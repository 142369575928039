import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter, last, map, mergeMap, toArray } from 'rxjs/operators';
import { S3Service } from '../http/aws-s3.service';


@Injectable()
export class ConfigurationBackOfficeService {

  constructor(
    private s3Service: S3Service
  ) {}

  uploadFilesToS3Bucket(files: { content: File; url: string }[]): Observable<number> {
    const uploads$ = from(files).pipe(
      filter(({ content }) => !!content),
      mergeMap(({ content, url }) =>
        this.s3Service.uploadFile(content, url).pipe(last())
      )
    );

    return uploads$.pipe(toArray(), map((results) => results.length));
  }

}
