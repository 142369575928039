<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '55vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="hideModal()"
>
  <div class="col-8 boxImage">
    <img oading="lazy" [src]="img" alt="image" class="w-3" />
  </div>
  <div class="grid text-center">
    <div class="col-8 col-offset-2 pt-4">
      <div class="headerModal">{{ title }}</div>
      <div class="textModal mt-3">
        {{ subtitle }}
      </div>
      <div class="textModal mt-3">
        <div *ngFor="let error of formattedErrors" [innerHTML]="error"></div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="col-12">
      <div class="col-12 chip-box pb-6">
        <deuna-chip
          backgroundColor="#000000"
          color="#FFFFFF"
          [text]="'Reintentar'"
          [chipClick]="true"
          [valueAction]="icons.imageReload"
          (closeChip)="hideModal()"
        >
        </deuna-chip>
      </div>
    </div>
  </ng-template>
</p-dialog>
