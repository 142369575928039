import { Component, Input } from '@angular/core';
import { ConfirmOrderModalComponent } from '../confirm-order-modal/confirm-order-modal.component';

@Component({
  selector: 'app-approved-expire-order-modal',
  templateUrl: './approved-expire-order-modal.component.html',
  styleUrls: ['./approved-expire-order-modal.component.scss'],
})
export class ApprovedExpireOrderModalComponent extends ConfirmOrderModalComponent {
    @Input() isReject: boolean = false;
  
}
