import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ImageSets } from 'src/app/shared/models/image-sets.schema';
import { backOfficeRoles } from 'src/constants';
import { jwtsecurityService } from '@deuna/bo-sh-security-ng';
import { debounceTime } from 'rxjs';
import { DataSharingService } from 'src/app/services/http/data-sharing.service';
import { Router } from '@angular/router';
import {
  OrderProcessType,
  TabListPlaceholderEnum,
} from 'src/app/shared/models/data-table.schema';

@Component({
  selector: 'app-tab-list-branch',
  templateUrl: './tab-list-branch.html',
  styleUrls: ['./tab-list-branch.scss'],
  providers: [TitleCasePipe],
})
export class TabListBranchComponent implements AfterViewInit, OnChanges {
  @Output() searchTextChanged: EventEmitter<string> =
    new EventEmitter<string>();

  @Output() openSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();

  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>();
  @Input() tabindex: string = 'INITIAL';

  securityService = jwtsecurityService;

  canDisplay(roles?: string[]) {
    return this.securityService.validateIn(roles);
  }

  isExecutor = this.canDisplay([backOfficeRoles.EXECUTOR]);
  showSideBar: boolean = false;
  titleSearch = '';
  dato: any = {};

  inputSub: any;

  icons = ImageSets.icons;
  errorImages = ImageSets.errorImages;

  formFilterOrderList: FormGroup;
  constructor(
    private readonly fb: FormBuilder,
    private sharedService: DataSharingService,
    private router: Router
  ) {
    this.formFilterOrderList = this.fb.group({
      textSearchOrders: [''],
    });
  }

  ngOnInit(): void {
    this.getInicialConfiguration();
  }

  ngAfterViewInit() {
    this.suscribeToInputChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.tabindex = changes['tabindex'].currentValue;
    this.getInicialConfiguration();
  }

  getInicialConfiguration() {
    switch (this.tabindex) {
      case OrderProcessType.CLOSEACCOUNT:
        this.titleSearch = TabListPlaceholderEnum.CLOSEACCOUNT;
        break;
      case OrderProcessType.JUDICIAL:
        this.titleSearch = TabListPlaceholderEnum.JUDICIAL;
        break;
      case OrderProcessType.RECONCILE:
        this.titleSearch = TabListPlaceholderEnum.RECONCILE;
        break;
      case OrderProcessType.RECOVERY:
        this.titleSearch = TabListPlaceholderEnum.RECOVERY;
        break;
    }
  }

  openSideBar() {
    this.toggleSidebar.emit();
  }
  onPressCreate() {
    if (this.tabindex === OrderProcessType.RECOVERY) {
      this.router.navigate([
        'people/incidentorders/create-order/RECOVERY/step-2',
      ]);
    } else {
      this.router.navigate(['people/incidentorders/create-order/step-1']);
    }
  }

  suscribeToInputChanges() {
    this.inputSub = this.formFilterOrderList.controls[
      'textSearchOrders'
    ].valueChanges
      .pipe(debounceTime(1500))
      .subscribe((value) => {
        this.sharedService.updateSearchText(value);
      });
  }
}
