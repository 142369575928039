import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading-error',
  templateUrl: './loading-error.component.html',
  styleUrls: ['./loading-error.component.scss'],
})
export class LoadingErrorComponent {
  @Input() image: string = '';
  @Input() subHeaderText: string = '';
  @Input() headerText: string | undefined = '';
  @Input() routesBack: string = '';
  @Input() routesNext: string = '';
  @Input() idParamRoute: string = '';
  @Input() valueAction: string = '';
  @Input() orderId: string | undefined = '';
  @Input() text: string = '';
  @Output() onHide = new EventEmitter();
  @Output() closeChip = new EventEmitter<string>();


  constructor(private router: Router) {
  }

  clickEvent(event: string) {
    this.closeChip.emit(event)
  }

  continueBack() {
    this.router.navigate([this.routesBack]);
  }

  continueNext() {
    this.router.navigate([this.routesNext, this.idParamRoute]);
  }
}
