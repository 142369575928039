import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@deuna/bo-sh-helpers-ng';
import { AuthorizationGuard } from '@deuna/bo-sh-security-ng';
import { backOfficeRoles } from 'src/constants';

import { TabListBranchComponent } from './tab-list-branch/tab-list-branch';
import { DefaultUsersLayout } from './layouts/default/default.layout';
import { OrderLayout } from './layouts/orders-layout/order-layout';

const routes: Routes = [
  {
    path: 'people/incidentorders',
    component: DefaultUsersLayout,
    canLoad: [AuthGuard, AuthorizationGuard],
    canActivateChild: [AuthGuard],
    canActivate: [AuthorizationGuard],
    children: [
      {
        path: 'list',
        component: OrderLayout,
      },
      {
        path: '',
        loadChildren: () =>
          import('../modules/merged.routes').then((m) => m.MergedRoutingModule),
      },
    ],
    data: {
      requiredRoles: [
        backOfficeRoles.APPROVER,
        backOfficeRoles.EXECUTOR,
        backOfficeRoles.READER,
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainIncidentOrdersRoutingModule {
  static readonly COMPONENTS = [
    OrderLayout,
    DefaultUsersLayout,
    TabListBranchComponent,
  ];
}
