<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '55vw' }"
  (onHide)="hideModal()"
  [draggable]="false"
  [resizable]="false"
>
  <div class="grid text-center">
    <div class="col-8 col-offset-2 pt-4">
      <div class="headerModal">¿Quieres rechazar la órden N°{{ orderId }}?</div>
      <div class="textModal mt-3">
        Al seleccionar “Si, rechazar”, la orden será rechazada y no podrás
        deshacer la acción.
      </div>
      <div class="inputModal mt-3">
        <span class="flex align-items-start textDetails">Detalle</span>
        <input
          type="text"
          class="p-inputtext"
          placeholder="Ejm.: Error en la fila 23 y 60"
          label="Detalle"
          [(ngModel)]="reviewComments"
          (ngModelChange)="onreviewCommentsChange.emit(reviewComments)"
        />
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid col-12">
      <div class="col-6">
        <deuna-button
          deUnaClass="p-button-outlined"
          label="No, cancelar"
          [disabled]="loading"
          [outline]="true"
          (eventClick)="cancelConfirm()"
        >
        </deuna-button>
      </div>
      <div *ngIf="!loading" class="col-6">
        <deuna-button
          label="Sí, rechazar"
          [disabled]="loading"
          (eventClick)="confirmCreation()"
        ></deuna-button>
      </div>
      <div *ngIf="loading" class="col-6">
        <deuna-button
          label="Rechazando..."
          [disabled]="loading"
          (eventClick)="confirmCreation()"
        >
        </deuna-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
