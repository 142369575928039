<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ height: '43vw', width: '80vw', 'background-color': 'red' }"
  (onHide)="hideModal()"
  [draggable]="false"
  [resizable]="false"
>
  <div class="grid">
    <div class="col">
      <div class="headerModal">Orden de pago Nro°{{ orderId }}</div>
      <div class="textModal">Estos son los detalles de la orden.</div>
    </div>
  </div>
  <div class="col headerbox"></div>
  <div class="grid">
    <div class="col-10">
      <div class="grid">
        <div class="col-7">
          <deuna-input
            [masked]="false"
            [form]="formFilterOrderList"
            [placeholder]="titleSearch"
            name="textSearchOrderList"
            control="textSearchOrders"
            [maxLength]="60"
          >
          </deuna-input>
        </div>
        <div class="col-4 pt-3" *ngIf="showFilterBtn">
          <div>
            <deuna-chip
              backgroundColor="#000000"
              color="#FFFFFF"
              [text]="'Filtrar'"
              [chipClick]="true"
              [valueAction]="iconPaths.searchFilter"
              (closeChip)="openFilter()"
            >
            </deuna-chip>
          </div>
        </div>
      </div>
      <ng-container *ngIf="activeFilters.length > 0">
        <div class="grid mb-4">
          <div class="col-12">
            <strong>Filtros aplicados</strong>
            <deuna-chip
              class="chipFilters"
              *ngFor="let item of activeFilters"
              backgroundColor="#ececfb"
              color="#46246B"
              [text]="item.name"
              [action]="true"
              [valueAction]="'X'"
              (closeChip)="clearFilter($event)"
            >
            </deuna-chip>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="col-9 table">
      <div class="col-12 text-center" *ngIf="loadListBranchs">
        <p-progressBar
          mode="indeterminate"
          [style]="{ height: '6px' }"
          download
        ></p-progressBar>
        <h2 class="mt-2">Cargando....</h2>
      </div>

      <div
        [ngClass]="{
          table: showSideBar,
          tableCollapse: showSideBar
        }"
        class="table"
        *ngIf="!loadListBranchs"
      >
        <ng-container *ngIf="dataLoad == true; else table">
          <app-modal-selector
            [modalType]="modalSelectorTypes"
            [modalErrorProps]="loadingErrorProps"
            (deleteFilters)="deleteFiltersReload()"
            (reloadComponent)="reloadComponent()"
          ></app-modal-selector>
        </ng-container>
        <ng-template #table>
          <deuna-table [arrayData]="[1]" [columnas]="columns">
            <ng-container
              *ngFor="let order; index as i; of: this.ordersListPts?.items"
            >
              <tr>
                <td>{{ order.requestId }}</td>
                <td
                  style="
                    white-space: nowrap;
                    list-style-type: circle;
                    padding-left: 1rem;
                  "
                >
                  <ng-container
                    [ngSwitch]="
                      isRetencionActiva
                        ? order.processStatus
                        : order.requestStatus
                    "
                  >
                    <deuna-chip
                      *ngSwitchCase="'SUCCESS'"
                      [valueAction]="pointGreen"
                      backgroundColor="#ECF9EF"
                      color="#2D8846"
                      text="Exitosa"
                    ></deuna-chip>
                    <deuna-chip
                      *ngSwitchCase="'TOTAL'"
                      [valueAction]="pointGreen"
                      backgroundColor="#ECF9EF"
                      color="#2D8846"
                      text="Exitosa"
                    ></deuna-chip>
                    <deuna-chip
                      *ngSwitchCase="'FAIL'"
                      [valueAction]="pointRed"
                      backgroundColor="#FFBABA"
                      color="#D8000C"
                      text="Fallida"
                      tooltipPosition="top"
                    ></deuna-chip>
                    <deuna-chip
                      *ngSwitchCase="'FAILED'"
                      [valueAction]="pointRed"
                      backgroundColor="#FFBABA"
                      color="#D8000C"
                      text="Fallida"
                      tooltipPosition="top"
                    ></deuna-chip>
                    <deuna-chip
                      *ngSwitchCase="'PARTIAL'"
                      [valueAction]="pointBrown"
                      backgroundColor="#FDF4E6"
                      color="#D98800"
                      text="Parcial"
                    ></deuna-chip>
                    <deuna-chip
                      *ngSwitchCase="'NOT_APPLY'"
                      [valueAction]="pointBrown"
                      backgroundColor="#FDF4E6"
                      color="#D98800"
                      text="No aplica"
                    ></deuna-chip>
                    <deuna-chip
                      *ngSwitchDefault
                      backgroundColor="#FDF4E6"
                      color="#835200"
                      [text]="
                        isRetencionActiva
                          ? order.processStatus
                          : order.requestStatus
                      "
                    ></deuna-chip>
                  </ng-container>
                </td>

                <ng-container *ngIf="!isOrderTypeIncluded(orderType)">
                  <td>
                    {{ order.custDocTypeId }}
                  </td>
                  <td>
                    {{ order.custDocId }}
                  </td>
                  <td *ngIf="isNewColumns">
                    {{
                      order.extraData && order.extraData.adjustments.length > 0
                        ? order.extraData.adjustments[0].id
                        : ""
                    }}
                  </td>
                  <td>
                    {{ order.custAccountNo }}
                  </td>
                  <td
                    *ngIf="
                      isNewColumns ||
                      orderProcessType === actionTypeEnum.RECOVERY
                    "
                  >
                    {{
                      order.requestAmount
                        | currency : "USD" : "symbol" : "1.2-2"
                    }}
                  </td>
                </ng-container>

                <td
                  *ngIf="
                    isNewColumns || orderProcessType === actionTypeEnum.RECOVERY
                  "
                  style="text-align: left"
                >
                  {{
                    order.processAmount | currency : "USD" : "symbol" : "1.2-2"
                  }}
                </td>
                <td
                  *ngIf="
                    !isNewColumns &&
                    orderProcessType !== actionTypeEnum.RECOVERY
                  "
                  style="text-align: left"
                >
                  {{
                    order.requestAmount | currency : "USD" : "symbol" : "1.2-2"
                  }}
                </td>

                <!-- Agrega este código dentro del lugar apropiado en tu plantilla -->
                <span *ngIf="order.requestStatus === 'FAIL'">
                  <img
                    style="cursor: pointer"
                    [src]="imageSee"
                    title="Ver"
                    alt="process_completed"
                    (click)="handleClickDetails(i)"
                  />
                </span></tr
            ></ng-container>
          </deuna-table>
        </ng-template>
      </div>
      <div class="grid pt-5">
        <div class="col">
          <p>
            {{
              ordersListPts.meta.totalItems === 1
                ? ordersListPts.meta.totalItems + " solicitud"
                : ordersListPts.meta.totalItems + " solicitudes"
            }}
          </p>
        </div>
        <div class="col">
          <deuna-pagination
            (paginaEmitter)="goToPage($event)"
            (itemsEmmiter)="changeToItems($event)"
            [currentPage]="ordersListPts.meta.currentPage"
            [limitPage]="paramsQueryPts.limit"
            [totalItems]="ordersListPts.meta.totalItems"
            [totalPages]="ordersListPts.meta.totalPages"
          >
          </deuna-pagination>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!showFilters">
      <div class="col-12 sectionRightContainer">
        <div class="sectionRight">
          <div>
            <p class="pb-2">Estado:</p>
            <span style="margin-left: -8px">
              <ng-container [ngSwitch]="statusOrder">
                <deuna-chip
                  *ngSwitchCase="'NEW'"
                  [valueAction]="pointBrown"
                  backgroundColor=" #FDF4E6 "
                  color="#A96A00"
                  text="Por aprobar "
                ></deuna-chip>
                <deuna-chip
                  *ngSwitchCase="'APPROVED'"
                  [valueAction]="pointBrown"
                  backgroundColor=" #FDF4E6 "
                  color=" #835200"
                  rdersByPage()
                  text="Aprobada"
                ></deuna-chip>
                <deuna-chip
                  *ngSwitchCase="'REJECTED'"
                  [valueAction]="pointRed"
                  backgroundColor="#FFBABA"
                  color="#D8000C"
                  text="Rechazada"
                  tooltipPosition="top"
                >
                </deuna-chip>
                <deuna-chip
                  *ngSwitchCase="'PROCESSING'"
                  [valueAction]="pointBlue"
                  backgroundColor="#E6F0FC"
                  color="#004DA3"
                  text="Procesando"
                ></deuna-chip>
                <deuna-chip
                  *ngSwitchCase="'COMPLETED'"
                  [valueAction]="pointGreen"
                  backgroundColor="#ECF9EF"
                  color="#2D8846"
                  text="Procesada"
                ></deuna-chip>
                <deuna-chip
                  *ngSwitchCase="'SUCCESS'"
                  [valueAction]="pointGreen"
                  backgroundColor="#ECF9EF"
                  color="#2D8846"
                  text="Procesada"
                ></deuna-chip>
                <deuna-chip
                  *ngSwitchCase="'ERROR'"
                  [valueAction]="pointRed"
                  backgroundColor="#FFBABA"
                  color="#D8000C"
                  text="Fallida"
                ></deuna-chip>
                <deuna-chip
                  *ngSwitchCase="'COMPLETED_WITH_ERRORS'"
                  [valueAction]="pointBrown"
                  backgroundColor="#FDF4E6"
                  color="#D98800"
                  text="Procesada con errores"
                ></deuna-chip>
                <deuna-chip
                  *ngSwitchDefault
                  backgroundColor="#FDF4E6"
                  color="#835200"
                ></deuna-chip>
              </ng-container>
            </span>
          </div>
          <div>
            <p class="title">Ejecutor:</p>
            <p class="textSection">{{ mailExecutor }}</p>
          </div>
          <div>
            <p class="title">Aprobador:</p>
            <p class="textSection">{{ mailApprove }}</p>
          </div>
          <ng-container *ngIf="orderProcessType === actionTypeEnum.RECOVERY">
            <div>
              <p class="title">Fecha de creación:</p>
              <p class="textSection">
                {{ order.createdOnDate | date : "dd/MM/yyyy" }}
              </p>
            </div>
            <div *ngIf="order.processInfo">
              <p class="title">Fecha de caducidad:</p>
              <p class="textSection">
                {{ order.processInfo.endDate | date : "dd/MM/yyyy" }}
              </p>
            </div>
          </ng-container>
        </div>
        <div class="pt-4 sectionDetails" *ngIf="showErrorDetails">
          <p class="title"><strong>Descripción de error:</strong></p>
          <p><strong>Código:</strong></p>
          <p>{{ ordersListPts.items[selectedIndex].requestErrorCode }}</p>
          <p><strong>Tipo de error:</strong></p>
          <p>
            {{ ordersListPts.items[selectedIndex].requestErrorDescription }}
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showFilters">
      <app-filters-pts-modal
        (onClose)="closeFilter()"
        (onChange)="onFilterChange($event)"
        [statusList]="statusList"
      ></app-filters-pts-modal>
    </ng-container>
  </div>
</p-dialog>
