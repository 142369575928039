import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql, MutationResult } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  AccountOrderResultResponse,
  IMutationReviewDecision,
  IQueryDataOrders,
  OrderAccountListResponse,
} from 'src/app/shared/models/data-table.schema';
import {
  DeleteCustomerAccountResultResponseDto,
  CreateCustomerAccountOrder,
  CreateCustomerAccountPresignedUrl,
  DownloadAccountOrderContentResponseDto,
  CreateAccountOrder,
  AccountResultResponseDto,
  ReviewOrderToExecute,
} from 'src/app/shared/models/config-backoffice.schema';
import {
  create_customer_account,
  create_presignurl,
  delete_accountMgmt_order,
  download_accountmgmt_content,
  review_decision_accountMgmt,
  review_executed_orders,
} from './graphql-account';

@Injectable()
export class GraphqlAccountService {
  apollo: ApolloBase;
  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('newClientName');
  }

  getAccountOrders(input: IQueryDataOrders, outPutResult: any) {
    return this.apollo
      .query<OrderAccountListResponse>({
        query: gql`
        query filterAccountMgmts(
          $input: FilterAccountOrdersByCriteriaInputData!
        ) {
          filterAccountMgmts(data: $input) {
            ${outPutResult}
          }
        }
      `,
        variables: {
          input,
        },
      })
      .pipe(map((response) => response.data.filterAccountMgmts));
  }

  approveAccountOrder(
    input: IMutationReviewDecision
  ): Observable<MutationResult<AccountOrderResultResponse>> {
    return this.apollo.mutate({
      mutation: review_decision_accountMgmt as any,
      variables: {
        input,
      },
    });
  }

  downloadAccountContent(id: string, orderType: string, contentType: string) {
    return this.apollo
      .query<DownloadAccountOrderContentResponseDto>({
        query: download_accountmgmt_content,
        variables: {
          input: { id, orderType, contentType },
        },
      })
      .pipe(map((result) => result.data.downloadAccountMgmtContent));
  }

  deleteCustomerAccountOrder(
    orderId: string
  ): Observable<MutationResult<DeleteCustomerAccountResultResponseDto>> {
    return this.apollo.mutate({
      mutation: delete_accountMgmt_order as any,
      variables: {
        input: { id: orderId },
      },
    });
  }

  createCustomerAccountOrder(orderInfo: CreateCustomerAccountOrder) {
    return this.apollo
      .mutate<AccountResultResponseDto>({
        mutation: create_customer_account,
        variables: {
          input: { ...orderInfo },
        },
      })
      .pipe(map((response) => response.data));
  }

  createCustomerAccountPresignedUrlToFileUpload(order: CreateAccountOrder) {
    return this.apollo
      .mutate<CreateCustomerAccountPresignedUrl>({
        mutation: create_presignurl,
        variables: {
          input: { ...order },
        },
      })
      .pipe(
        map((response) => response.data?.createPreSignUrlForAccountMgmtUpload)
      );
  }

  reviewOrderToExecute(id: string) {
    return this.apollo
      .mutate<ReviewOrderToExecute>({
        mutation: review_executed_orders,
        variables: {
          data: { id: id },
        },
      })
      .pipe(
        map((response) => {
          if (response?.data) {
            return response.data.reviewOrderToExecute.body.orderExecuted;
          }
          return null;
        })
      );
  }
}
