import { CreateOrderService } from 'src/app/services/incidentandorders/create-order.service';
import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { jwtsecurityService } from '@deuna/bo-sh-security-ng';
import { backOfficeRoles } from 'src/constants';
import { tabListItems } from 'src/app/shared/models/order-types.schema';
import { environment } from 'src/environments/environment';
import { OrderProcessType } from 'src/app/shared/models/data-table.schema';

@Component({
  selector: 'app-order-layout',
  templateUrl: './order-layout.html',
  styleUrls: ['./order-layout.scss'],
  providers: [TitleCasePipe],
})
export class OrderLayout implements OnInit {
  showSideBar: boolean = false;
  showSide: boolean = false;
  securityService = jwtsecurityService;
  items: Array<MenuItem> = [];
  activeItem: MenuItem = {};
  cancelationComponent: boolean = false;
  actionTypeEnum = OrderProcessType;
  constructor(private readonly createOrderService: CreateOrderService) {
    this.activeItem = {
      label: 'Inicial',
      id: 'Inicial',
      tabindex: 'INITIAL',
    };
  }

  ngOnInit(): void {
    this.getInitial();
  }

  get backofficeRoles() {
    return backOfficeRoles;
  }

  canDisplay(roles?: string[]) {
    return this.securityService.validateIn(roles);
  }

  setActiveItem(item: MenuItem) {
    this.activeItem = item;
    sessionStorage.setItem(
      'active_order_process_type',
      JSON.stringify(this.activeItem)
    );
  }

  getInitial() {
    const filteredItems = environment.enabledRecoveryTabModule
      ? tabListItems
      : tabListItems.filter((item) => item.tabindex !== OrderProcessType.RECOVERY);

    this.items = filteredItems.map((r) => ({
      ...r,
      command: (i: any) => this.setActiveItem(i.item),
    }));

    this.activeItem = this.items[0];
    sessionStorage.setItem(
      'active_order_process_type',
      JSON.stringify(this.activeItem)
    );
    this.createOrderService.setActiveOrderProcessType(this.activeItem);
  }

  toggleSidebar() {
    this.showSideBar = !this.showSideBar;
  }

  cancellationComponent2() {
    this.cancelationComponent = !this.cancelationComponent;
  }
}
