import { Component } from '@angular/core';

import { TableActionsBaseComponent } from 'src/app/shared/components/table-actions-base/table-actions-base.component';

@Component({
  selector: 'app-table-actions',
  templateUrl:
    '../../../../shared/components/table-actions-base/table-actions-base.component.html',
  styleUrls: [
    '../../../../shared/components/table-actions-base/table-actions-base.component.css',
  ],
})
export class TableActionsComponent extends TableActionsBaseComponent {
  
}
