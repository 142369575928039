import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { TableActionsComponent } from '../table-actions/table-actions.component';
import { ListBaseComponent } from 'src/app/shared/components/list-base/list-base.component';
import {
  AccountOrderListResponse,
  IQueryDataOrders,
  OrderProcessType,
  ReviewDecision,
} from 'src/app/shared/models/data-table.schema';
import { ModalType } from 'src/app/shared/models/constants.backoffice.schema';
import { GraphqlAccountService } from 'src/app/services/graphql/graphql-account.service';
import { DataSharingService } from 'src/app/services/http/data-sharing.service';

@Component({
  selector: 'app-list-cancellation',
  templateUrl: `../../../../shared/components/list-base/list-base.component.html`,
  styleUrls: [
    '../../../../shared/components/list-base/list-base.component.scss',
  ],
})
export class ListCancellationComponent extends ListBaseComponent {
  @ViewChild('tableActions') override tableActions!: TableActionsComponent;
  actionsTypeInput = OrderProcessType.CLOSEACCOUNT;
  override actionsType = OrderProcessType.CLOSEACCOUNT;
  @Input() override paramsQuery: IQueryDataOrders = {
    orderProcessType: OrderProcessType.CLOSEACCOUNT,
    skip: 0,
    limit: 10,
  };
  constructor(
    private servicesAccountGql: GraphqlAccountService,
    sharedService: DataSharingService
  ) {
    super(sharedService);
  }
  override createConfigGetInitial() {
    this.paramsQuery = {
      orderProcessType: OrderProcessType.CLOSEACCOUNT,
      skip: this.currentPage,
      limit: this.limitPage,
    };
    this.paramsDecision = {
      id: '',
      reviewDecision: ReviewDecision.APPROVED,
      reviewComments: '',
    };
    this.resultsDecision = `
        id: '',
        orderId: ''
        `;

    this.dataPagination = {
      skip: this.currentPage,
      limit: this.limitPage,
    };
  }
  override getOrdersByPageAccount() {
    this.loadListBranchs = true;
    this.modalSelectorTypes = ModalType.NotModal;
    this.dataLoad = false;
    if (this.paramsQuery.orderProcessType === undefined) return;
    this.servicesAccountGql
      .getAccountOrders(this.paramsQuery, this.resultfields)
      .subscribe({
        next: (res: AccountOrderListResponse) => {
          this.ordersList = res;
          if (!this.ordersList.items.length && !this.searchByFiltersEmpty) {
            this.dataLoad = true;
            this.activateInitNotOrders();
            this.modalSelectorTypes = ModalType.NotCreateOrders;
          }
          if (!this.ordersList.items.length && this.searchByFiltersEmpty) {
            this.dataLoad = true;
            this.initNotFiltersPropsModal();
            this.modalSelectorTypes = ModalType.NotFilters;
          }
          this.loadListBranchs = false;
        },
        error: () => {
          this.loadListBranchs = false;
          this.dataLoad = true;
          this.activateInitErrorOrders();
          this.modalSelectorTypes = ModalType.NotOrders;
        },
      });
  }
}
