import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-order-modal',
  templateUrl: './delete-order-modal.component.html',
  styleUrls: ['./delete-order-modal.component.scss'],
})
export class DeleteOrderModalComponent {
  @Input() displayModal: boolean = false;
  @Output() onConfirm = new EventEmitter();
  @Input() orderId: string = '';
  @Output() onHide = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() loading: boolean = false;

  cancelConfirm() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  showModal() {
    this.displayModal = true;
  }

  confirmCreation() {
    this.onConfirm.emit();
  }

  hideModal() {
    this.displayModal = false;
    this.onCancel.emit();
  }
}
