<div class="w-full h-full z-5" *ngIf="loading">
  <deuna-loading titulo="Reintentando" subtitulo="Por favor, espera un momento">
  </deuna-loading>
</div>

<div style="display: flex; justify-content: center; align-items: center; height: 100%; margin-top: 10%;">
  <div class="col-5">
    <div class="grid col-10 col-offset-1 px-2" style="display: flex;justify-content: center;">
      <img [src]="image" alt="error">

      <h2 class="order-created__error-title"> {{errorMessage || 'Ocurrió un problema al caducar la orden N°' +
        orderInformation.orderId}}</h2>
      <p *ngIf="subtitleError" class="text-center"> Por favor, espera un momento para volver a intentar.</p>
    </div>
    <div class="grid p-3">
      <div class="col-6">
        <deuna-button deUnaClass="p-button-outlined" label="Volver al inicio" [outline]="true"
          (eventClick)="goToHome()">
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button label="Reintentar"></deuna-button>
      </div>
    </div>
  </div>
</div>
