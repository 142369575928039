import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ConfigurationBackOfficeService } from 'src/app/services/graphql/configuration-flow-backoffice.service';
import { GraphqlMassivetxnsService } from 'src/app/services/graphql/graphql-massivetxns.service';
import { ErrorHandlingService } from 'src/app/services/http/error-handling.service';
import { DataSharingService } from 'src/app/services/http/data-sharing.service';

import {
  CreateOrderService,
  SelectOption,
} from 'src/app/services/incidentandorders/create-order.service';
import { ValidateFilesHelpers } from 'src/app/shared/helpers/validate-files';
import { monthsNumber } from 'src/app/constants/dates';
import {
  OrderProcessType,
  StrategyFileEnum,
} from '../../models/data-table.schema';
import { FileUploadComponent } from '../../components/file-upload/file-upload.component';
import {
  AnalysisDataResponse,
  ErrorMappingValidation,
} from '../../models/config-backoffice.schema';
import { FormatErrorModalComponent } from '../../components/modals/format-error-modal/format-error-modal.component';
import { recoveryPaymentType } from '../../models/order-types.schema';

export interface OrderType {
  label: string;
  name: string;
}

export interface OrderFileDescription {
  type: string;
  file?: File;
  required: boolean;
}

@Component({
  selector: 'app-create-massive-order-step2',
  templateUrl: './create-massive-order-step2.component.html',
  styleUrls: ['./create-massive-order-step2.component.scss'],
})
export class CreateMassiveOrderStep2Page implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload!: FileUploadComponent;
  @ViewChild('formatError') formatError!: FormatErrorModalComponent;

  maxFileSize = 10;
  orderTypes!: Array<OrderType>;
  displayConfirmModal: boolean = false;
  displayCancelModal: boolean = false;
  displayFormatErrorModal: boolean = false;
  loading: boolean = false;
  minDate = new Date();
  currentDate = `${new Date().getDate()}/${
    monthsNumber[new Date().getMonth()]
  }/${new Date().getFullYear()}`;
  analysisData: AnalysisDataResponse | undefined = undefined;
  modalFile;

  formStep2: FormGroup = this.formBuilder.group({
    orderType: [
      this.createOrderService.step1Result?.name ?? '',
      [Validators.required],
    ],
    orderTypeLabel: [
      'Recuperación',
    ],
    inFile: [
      null,
      [
        Validators.required,
        this.validateFiles.validateFileExtension(['.xlsx', '.csv', '.xls']),
        this.validateFiles.validateFileSize(this.maxFileSize),
      ],
    ],
    evidenceFile: [
      null,
      [
        this.validateFiles.validateFileExtension(['.zip']),
        this.validateFiles.validateFileSize(this.maxFileSize),
      ],
    ],
  });

  columns = ['Número de incidencias', 'Monto total'];
  recoveryType = OrderProcessType.RECOVERY;
  recoveryPaymentType = recoveryPaymentType;
  constructor(
    private router: Router,
    private readonly formBuilder: FormBuilder,
    public createOrderService: CreateOrderService,
    private readonly configurationBackOfficeService: ConfigurationBackOfficeService,
    private readonly massiveService: GraphqlMassivetxnsService,
    private messageService: MessageService,
    public validateFiles: ValidateFilesHelpers,
    private orderIdService: DataSharingService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  get initialOrderType() {
    return this.createOrderService.step1Result?.label;
  }

  ngOnInit(): void {
    const orderProcessTypeValid =
      this.createOrderService.isOrderProcessTypeValid();
    const ste1IsCompleted = this.createOrderService.isStep1Completed();
    if (
      !(orderProcessTypeValid && ste1IsCompleted) &&
      this.getFormType !== OrderProcessType.RECOVERY
    ) {
      this.router.navigate(['people/incidentorders/list']);
    } else {
      this.initData();
    }
  }

  ngOnDestroy(): void {
    this.loading = false;
    this.displayConfirmModal = false;
  }
  onSelect(event: any): void {
    console.log(event);
  }
  isFieldInvalid(fieldName: string): any {
    return (
      this.formStep2.controls?.[fieldName]?.errors &&
      this.formStep2.controls?.[fieldName]?.touched
    );
  }

  get step1Result() {
    return this.createOrderService.step1Result;
  }

  openConfirmModal() {
    this.displayConfirmModal = true;
  }

  hideConfirmModal() {
    this.displayConfirmModal = false;
  }

  confirmOrderCancellation() {
    this.messageService.add({
      severity: 'success',
      detail: 'Cancelaste la orden',
    });
    this.router.navigate(['people/incidentorders/list']);
  }
  confirmRetryUpload() {
    this.displayFormatErrorModal = false;
  }
  showFormatError(
    title: string,
    subtitle: string,
    error: ErrorMappingValidation[]
  ) {
    this.formatError.showModal(title, subtitle, error);
  }
  cancelOrderCreation() {
    this.displayConfirmModal = false;
  }

  openCancelModal() {
    this.displayCancelModal = true;
  }

  hideCancelModal() {
    this.displayCancelModal = false;
  }

  changeOrderTypeValue() {
    const { label, name } = this.formStep2.value['orderType'] as SelectOption;
    this.createOrderService.setStep1Value({ label, name });
  }
  get getFormType() {
    return this.validateFiles.selectedOrderType();
  }

  initData() {
    this.orderTypes = this.validateFiles.setOrderTypes();
    const orderTypeControl = this.formStep2.controls['orderType'];
    const orderTypeLabelControl = this.formStep2.controls['orderTypeLabel'];
    if (this.getFormType !== OrderProcessType.RECOVERY) {
      const { name } = JSON.parse(
        localStorage.getItem('create_order_step1')!
      ) as OrderType;
      orderTypeControl.setValue(name);
    } else {
      orderTypeLabelControl.disable();
      orderTypeControl.setValue(this.orderTypes[0].name,)
      this.formStep2.addControl(
        'selectedDates',
        this.formBuilder.control('', [Validators.required])
      );
      this.formStep2.addControl(
        'paymentType',
        this.formBuilder.control('', [Validators.required])
      );
      this.createOrderService.setStep1Value({
        label: this.orderTypes[0].label,
        name: this.orderTypes[0].name,
      });
    }
  }

  receiveInconsistencesFile(file: File) {
    const { name: orderType } = this.createOrderService.step1Result!;
    const control = this.formStep2.get('inFile');
    if (!control || !file) {
      this.analysisData = undefined;
      this.formStep2.get('inFile')?.setValue(null);
      return;
    }
    control.setValue(file);
    control.markAsTouched();
    const erroTitle = 'Ha ocurrido un error';
    const erroSubtitle = 'Verifique los datos';
    const erroLoad = 'Cargar nuevamente';
    this.massiveService
      .uploadFile(file, StrategyFileEnum.MASSIVE_ADJUSTMENT, {
        orderType,
      })
      .subscribe({
        next: (res) => {
          if (res?.success) {
            this.fileUpload.setFinishStatus(
              true,
              true,
              undefined,
              this.fileUpload.fileName
            );
            setTimeout(() => {
              this.analysisData = res.listObjects
                ? res.listObjects[0].analysisData
                : undefined;
            }, 2000);
          } else {
            this.fileUpload.changeStatus(
              'error',
              erroLoad,
              res?.errorMessage?.title ?? erroTitle
            );
            this.showFormatError(
              res?.errorMessage?.title ?? erroTitle,
              res?.errorMessage?.message ?? erroSubtitle,
              res?.errorMapping ?? []
            );
          }
        },
        error: (error) => {
          console.error(error);
          this.fileUpload.changeStatus(
            'error',
            erroLoad,
            erroTitle,
            erroSubtitle
          );
          this.showFormatError('Ha ocurrido un error', erroSubtitle, []);
        },
      });
  }
  getHeaderTitle() {
    let header = '';
    switch (this.getFormType) {
      case OrderProcessType.JUDICIAL:
        header = 'Carga de archivo de ordenanzas';
        break;
      case OrderProcessType.RECONCILE:
        header = 'Carga de archivo de ajustes';
        break;
      case OrderProcessType.RECOVERY:
        header = 'Carga de archivo de recuperación';
        break;
    }
    return header;
  }

  receiveEvidencesFile(file: File) {
    const control = this.formStep2.get('evidenceFile');
    if (!control) return;
    control.setValue(file);
    control.markAsTouched();
  }

  onSubmit() {
    if (this.formStep2.invalid) {
      this.formStep2.markAllAsTouched();
      return;
    }
    this.displayConfirmModal = true;
  }

  confirmOrderCreation() {
    this.loading = true;

    const { name: orderType } = this.createOrderService.step1Result!;

    this.massiveService.createPresignedUrlToFileUpload(orderType).subscribe({
      next: (res) => this.handlePresignedUrlResponse(res, orderType),
      error: (error) => this.handleCreationError(error),
    });
  }

  handlePresignedUrlResponse(res: any, orderType: string) {
    if (!res) {
      this.handleCreationError(res);
      return;
    }

    const { id, contentsUrl, orderId, orderArtifactsStoreUrl } = res;
    this.orderIdService.setOrderId(orderId);

    const filesToUpload = this.filesToUpload
      .filter(({ file }) => file)
      .map(({ type, file }) => {
        if (!file) {
          throw new Error(`Required file is missing`);
        }
        return {
          url: contentsUrl.find((contentUrl) => contentUrl.type === type)!
            .value,
          content: file,
        };
      });

    this.configurationBackOfficeService
      .uploadFilesToS3Bucket(filesToUpload)
      .subscribe({
        next: () => {
          this.handleUploadSuccess(
            id,
            orderId,
            orderType,
            orderArtifactsStoreUrl,
            this.analysisData?.totalRecord!,
            this.analysisData?.totalAmount!
          );
        },
        error: (error) => this.handleCreationError(error),
      });
  }

  handleUploadSuccess(
    id: string,
    orderId: string,
    orderType: string,
    orderArtifactsStoreUrl: string,
    totalRecordNumber: number,
    totalAmountNumber: number
  ) {
    const isRecovery = this.getFormType === this.recoveryType;
    const selectedDates = this.formStep2.controls['selectedDates'];
    const paymentType = this.formStep2.controls['paymentType'];

    this.massiveService
      .createMassiveTxnOrder({
        id,
        orderId,
        orderProcessType: this.getFormType,
        orderType,
        orderAdditionalData: {
          totalAmount: totalAmountNumber,
          totalRecord: totalRecordNumber,
        },
        orderName: orderId,
        orderArtifactsStoreUrl,
        ...(isRecovery && {
          processInfo: {
            beginDate: new Date().toISOString(),
            endDate: new Date(selectedDates.value).toISOString(),
          },
          paymentType: paymentType.value.name,
        }),
      })
      .subscribe({
        next: () => this.handleSuccess(),
        error: (error) => this.handleCreationError(error),
      });
  }

  handleSuccess() {
    this.router.navigate(['/people/incidentorders/create-order/success'], {
      queryParams: {
        orderProcessType: this.getFormType,
      },
    });
    this.formStep2.reset();
    this.loading = false;
    localStorage.removeItem('create_order_step1');
  }

  handleCreationError(error: any) {
    const errorMessage = this.errorHandlingService.handleErrorResponse(error);

    this.router.navigate(['/people/incidentorders/create-order/error'], {
      queryParams: { message: errorMessage },
    });
  }

  get filesToUpload(): OrderFileDescription[] {
    return [
      {
        type: 'IN',
        file: this.formStep2.controls['inFile'].value,
        required: true,
      },
      {
        type: 'EVIDENCE',
        file: this.formStep2.controls['evidenceFile'].value,
        required: false,
      },
    ];
  }
}
