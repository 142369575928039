<div style="display: flex; justify-content: center; align-items: center; height: 100%; margin-top: 10%;">
  <div class="col-5">
    <div class="grid col-10 col-offset-1 px-2" style="display: flex;justify-content: center;">
      <img [src]="image" alt="success">
      <h2 class="order-created__success-title">Tu orden se creó exitosamente y
        fue enviada para su aprobación</h2>
      <p class="text-center">ID de la orden creada: {{orderId}}. Comunícate con el aprobador para agilizar la revisión.</p>
    </div>
    <div class="grid p-3">
      <div class="col-6">
        <deuna-button deUnaClass="p-button-outlined"  label="+ Crear nueva orden" [outline]="true" (eventClick)="createNewOrder()">
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button  routerLink="/people/incidentorders/list" label="Volver a mis órdenes"></deuna-button>
      </div>
    </div>
  </div>
</div>
