<div class="grid text-center pt-2">
  <div class="col-8 col-offset-2">
    <img [src]="image" alt="image" class="w-4" />
  </div>
  <div class="col-8 col-offset-2 pt-1">
    <div *ngIf="orderId; else noOrderId" class="fontHeader">
      {{ headerText +": " + orderId}}
    </div>

    <ng-template #noOrderId>
      <div class="fontHeader">
        {{ headerText }}
      </div>
    </ng-template>

    <div class="fontSubHeader" style="padding-top: 3%">
      {{ subHeaderText}}
    </div>
  </div>
  <div class="col-6 col-offset-3 pt-4">
    <div class="grid">
      <div class="col-12">
        <deuna-chip
          backgroundColor="#000000"
          color="#FFFFFF"
          [text]="text"
          [chipClick]="true"
          (closeChip)="clickEvent($event)"
          [valueAction]="valueAction"
        >
        </deuna-chip>
      </div>
    </div>
  </div>
</div>
