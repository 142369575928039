<div class="col">
  <div  class="grid">
    <div  class="col-5 ml-6">
      <deuna-input
        [masked]="false"
        [disableErrorMessage]="true"
        styles="background:#F1F2F5"
        [form]="formFilterOrderList"
        [placeholder]="titleSearch"
        name="textSearchOrderList"
        control="textSearchOrders"
        [maxLength]="60"
      >
      </deuna-input>
    </div>
    <div class="col-4 pt-3">
      <div >
        <deuna-chip
          backgroundColor="#000000"
          color="#FFFFFF"
          [text]="'Filtrar'"
          [chipClick]="true"
          [valueAction]="icons.searchFilter"
          (closeChip)="openSideBar()"
        >
        </deuna-chip>
      </div>
    </div>
    <div class="col-2">
      <deuna-button
        *ngIf="isExecutor"
        class="w-19rem"
        [label]="'+ Nueva orden'"
        (eventClick)="onPressCreate()"
      ></deuna-button>
    </div>
  </div>
</div>




