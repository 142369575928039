import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { DataSharingService } from 'src/app/services/http/data-sharing.service';
import { OrderProcessType } from '../../models/data-table.schema';

@Component({
  selector: 'app-create-order-success',
  templateUrl: './create-order-success.component.html',
  styleUrls: ['./create-order-success.component.scss'],
})
export class CreateOrderSuccessPage {
  image = assetUrl('png/image-invite.png');

  constructor(
    private readonly router: Router,
    private orderIdService: DataSharingService,
    private route: ActivatedRoute
  ) {}

  orderId: string = '';
  orderProcessType: string = '';

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.orderProcessType = params['orderProcessType'];
    });
    this.orderIdService.getOrderId().subscribe((orderId) => {
      this.orderId = orderId;
    });
  }

  createNewOrder() {
    if (this.orderProcessType === OrderProcessType.RECOVERY) {
      this.router.navigate([
        'people/incidentorders/create-order/RECOVERY/step-2',
      ]);
    } else {
      this.router.navigate(['people/incidentorders/create-order/step-1']);
    }
  }
}
