import { HTTP_INTERCEPTORS, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InMemoryCache } from '@apollo/client/core';
import {
  APOLLO_NAMED_OPTIONS,
  ApolloModule,
  NamedOptions,
} from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './shared/pages/empty-route/empty-route.component';
import { BackOfficeTokenInterceptor } from './shared/interceptors/backoffice.interceptor';
import { CommonModule } from '@angular/common';
import { MainIncidentOrdersModule } from './modules/main-incident-orders.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MainIncidentOrdersModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApolloModule,
    CommonModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          newClientName: {
            name: 'backend',
            cache: new InMemoryCache(),
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'network-only',
                errorPolicy: 'ignore',
              },
              query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
              },
            },
            link: httpLink.create({
              uri: environment.backend,
              extractFiles: (body) => extractFiles(body, isExtractableFile),
              headers:  new HttpHeaders({
                'x-apollo-operation-name': 'backoffice',
              })
            }),
          },
        };
      },
      deps: [HttpLink],
    },
    {provide: HTTP_INTERCEPTORS, useClass: BackOfficeTokenInterceptor, multi: true},
  ],
  exports : [
    FormsModule,
    ReactiveFormsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
