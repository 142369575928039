<div class="col-12 sectionRightContainer">
  <div class="sectionRight">
    <div class="grid">
      <div class="col">
        <span class="title">Filtros</span>
      </div>
      <div class="col boxClose">
        <span (click)="handleClose()" class="btnClose">X</span>
      </div>
    </div>
    <div class="mt-5">
      <strong>Estado</strong>
    </div>
    <div class="all">
      <input
        class="colorCheck mt-2"
        type="checkbox"
        name="allStatus"
        [checked]="allSelectedStatus"
        (change)="handleAllSelected()"
      />
      Todas
    </div>
    <div class="boxFilters">
      <div *ngFor="let status of statusList; let i = index">
        <div
          class="boxCheck"
          [style.backgroundColor]="status.selected ? '#F1F2F5' : '#FFFFFF'"
        >
          <input
            class="colorCheck mt-2"
            type="checkbox"
            [(ngModel)]="status.selected"
            [checked]="status.selected"
            (change)="onPressFilter()"
          />
          {{ status.name }}
        </div>
      </div>
    </div>
    <div class="grid boxBtns">
      <div class="col-12">
        <deuna-button
          [label]="'Aplicar filtros'"
          deUnaClass="p-button-outlined"
          [outline]="true"
          (eventClick)="applyFilters()"
        ></deuna-button>
      </div>
      <div class="col-12 text-link cursor-pointer">
        <u (click)="deleteFiltersReload()">Borrar todos los filtros</u>
      </div>
    </div>
  </div>
</div>
