import { gql } from 'apollo-angular';

export const review_executed_orders = gql`
  mutation ReviewOrderToExecute($data: ReviewOrderToExecuteRequestDTO!) {
    reviewOrderToExecute(data: $data) {
      body {
        orderExecuted
      }
      status {
        httpStatus
        code
        description
      }
    }
  }
`;

export const create_customer_account = gql`
  mutation createAccountMgmtOrder($input: CreateAccountOrderInputData!) {
    createAccountMgmtOrder(data: $input) {
      body {
        id
        orderId
      }
      status {
        httpStatus
        code
        description
      }
    }
  }
`;
export const create_presignurl = gql`
  mutation createPreSignUrlForAccountMgmtUpload(
    $input: CreatePreSignUrlEvidenceData!
  ) {
    createPreSignUrlForAccountMgmtUpload(data: $input) {
      body {
        id
        orderId
        orderArtifactsBucket
        orderArtifactsStoreUrl
        orderId
        contentsUrl {
          type
          value
        }
      }
      status {
        code
        description
        httpStatus
      }
    }
  }
`;

export const download_accountmgmt_content = gql`
  query downloadAccountMgmtContent(
    $input: DownloadAccountMgmtContentInputData!
  ) {
    downloadAccountMgmtContent(data: $input) {
      url
    }
  }
`;

export const review_decision_accountMgmt = gql`
  mutation reviewDecisionAccountMgmt(
    $input: PatchAccountOrderStatusInputData!
  ) {
    reviewDecisionAccountMgmt(data: $input) {
      id
      orderId
    }
  }
`;

export const delete_accountMgmt_order = gql`
  mutation deleteAccountMgmtOrder($input: DeleteAccountOrderInputData!) {
    deleteAccountMgmtOrder(data: $input) {
      id
    }
  }
`;
