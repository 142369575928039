// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: 'https://backoffice.dev.deunalab.com/people-api/internal/graphql',
  //backend: 'http://localhost:4000/people-api/internal/graphql',
  staticUrlDownload:
    'https://deuna.dev.deunalab.com/web/backoffice/ajustes/massivetxns-templates.zip',

  kmsKey:
    'arn:aws:kms:us-east-1:563875027714:key/4587b6b1-1eb9-407b-a956-4cec0629e273',
  enabledRecoveryTabModule: true,
  enabledRetention: true,
  enabledPtsFilter: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
