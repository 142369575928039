import { Injectable } from '@angular/core';
import { errorCatalogueAws, errorCatalogueMambu } from 'src/app/constants/catalogos/mocks-catalogo';

@Injectable()
export class ErrorHandlingService {

  private errorCatalogue = errorCatalogueMambu.concat(errorCatalogueAws);

  handleErrorResponse(error: any): string {

    const errorCode = error.status?.code || 'unknown';

    const errorCatalogueEntry = this.errorCatalogue.find((entry) => entry.code === errorCode);

    return errorCatalogueEntry ? errorCatalogueEntry.message : 'Ocurrió un problema al crear tu orden';
  }
}
