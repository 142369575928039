import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderProcessType } from 'src/app/services/incidentandorders/create-order.service';

export interface FileToDownload {
  fileType: string;
  format: string;
  contentType: string;
}

@Component({
  selector: 'app-download-file-modal',
  templateUrl: './download-file-modal.component.html',
  styleUrls: ['./download-file-modal.component.scss'],
})
export class DownloadFileModalComponent {
  @Input() displayModal: boolean = false;
  @Input() status : string = ''
  @Input() isOrderCompleted: boolean = false;
  @Output() onHide = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onSelect: EventEmitter<FileToDownload> =
    new EventEmitter<FileToDownload>();
  @Input() loading: boolean = false;

  hideModal() {
    this.displayModal = false;
    this.onHide.emit();
  }

  showModal() {
    this.displayModal = true;
  }

  downloadOrderFile(fileType: string, format: string, contentType: string) {
    this.onSelect.emit({ fileType, format, contentType });
  }

  get selectedOrderType(): string {
    const activeOrderProcessType = sessionStorage.getItem(
      'active_order_process_type'
    );
    const { tabindex } = JSON.parse(
      activeOrderProcessType!
    ) as OrderProcessType;
    return tabindex;
  }
  }

