import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';


@Component({
  selector: 'app-create-order',
  templateUrl: './create-order-error.component.html',
  styleUrls: ['./create-order-error.component.scss'],
})
export class CreateOrderErrorPage {
  image = assetUrl('png/image-report-error-r.png');
  errorMessage: string = 'Ocurrió un problema al crear tu orden';
  subtitleError: boolean = false;


  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.errorMessage = params['message'] ;
    });
    this.updateSubtitleError();
  }
  updateSubtitleError() {
    this.subtitleError = this.errorMessage === 'Ocurrió un problema al crear tu orden';
  }
  retryProcess() {
    this.router.navigate(['people/incidentorders/create-order/step-1']);
  }

  goToHome() {
    this.router.navigate(['people/incidentorders/list']);
  }
}
