import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService, PrimeIcons, PrimeNGConfig } from 'primeng/api';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    PrimeNGConfig,
    PrimeIcons,
    MessageService,

  ],
  exports: [ CommonModule,
    ToastModule,
    ReactiveFormsModule,
    CardModule,
    CalendarModule,
    ProgressBarModule,
    InputNumberModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TableModule,
    ButtonModule,
    CheckboxModule,
    DialogModule,
    TagModule,
    SidebarModule,
    ProgressBarModule,
    InputTextModule,
    MessageModule,
    TooltipModule,
    InputTextareaModule,],
})
export class PrimeNgModule { }
