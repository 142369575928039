import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.scss'],
})
export class ReasonModalComponent{
  @Input() displayModal: boolean = false;
  @Output() onHide = new EventEmitter();
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onreviewCommentsChange = new EventEmitter<any>();
  @Input() loading: boolean = false;
  @Input() orderId: string = '';
  @Input() formSearch!: FormGroup;
  @Input() reviewComments: string = '';


  hideModal() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  showModal() {
    this.displayModal = true;
  }

  cancelConfirm() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  confirmCreation() {
    this.onConfirm.emit();

  }
}
