import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSharingService } from 'src/app/services/http/data-sharing.service';
import {
  IQueryDataOrders,
  OrderProcessType,
} from '../../models/data-table.schema';
import { filterOrderTypeListCancellation, filterOrderTypeListJudicial, filterStatusListCancellation, filterStatusListMassive, reconcileType, recoveryType } from '../../models/order-types.schema';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css'],
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() orderProcessType: string = OrderProcessType.CLOSEACCOUNT;
  paramsQuery: IQueryDataOrders = {
    filterCriteriaText: '',
    orderProcessType:
      (this.orderProcessType as OrderProcessType) ??
      OrderProcessType.CLOSEACCOUNT,
    skip: 0,
    limit: 10,
  };

  statusList = filterStatusListCancellation;
  
  orderTypeList = filterOrderTypeListCancellation;
  orderTypeListReconcile = reconcileType.map((type) => {
    return {
      name: type.label,
      value: type.name,
      selected: false,
    };
  });
  orderTypeListRecovery = recoveryType.map((type) => {
    return {
      name: type.label,
      value: type.name,
      selected: false,
    };
  });
  
  isExecutor = false;
  isApprover = false;
  areAllSelectedDate = false;
  areAllSelectedStatus = false;
  areAllSelectedOrdenType = false;
  searchByFiltersEmpty: boolean = false;
  orderTypeClean: any;
  listFilters!: Array<any>;
  filterDate = [{ name: 'Rango de fecha', selected: false }];
  viewFilters: boolean = false;

  loadListBranchs: boolean = true;

  selectedDates: (Date | null)[] = [];
  formFilterOrderList: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dataSharedService: DataSharingService
  ) {
    this.formFilterOrderList = this.fb.group({
      textSearchOrders: [''],
    });
  }

  ngOnInit() {
    this.getData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const changeOrderProcessType = changes['orderProcessType'];
    if (changeOrderProcessType?.currentValue) {
      this.paramsQuery.orderProcessType = changeOrderProcessType.currentValue;
      this.updateFiltersAndTable();
      this.setStatusList(changeOrderProcessType.currentValue);
      this.setOrderType(changeOrderProcessType.currentValue);
    }
  }
  setStatusList(orderProcessType: string) {
    switch (orderProcessType) {
      case OrderProcessType.CLOSEACCOUNT:
        this.statusList = filterStatusListCancellation;
        break;
      case OrderProcessType.JUDICIAL:
        this.statusList = filterStatusListMassive;
        break;
      case OrderProcessType.RECONCILE:
        this.statusList = filterStatusListMassive;
        break;
      case OrderProcessType.RECOVERY:
        this.statusList = filterStatusListMassive;
        break;
      default:
        this.statusList = [];
        break;
    }
  }
  setOrderType(orderProcessType: string) {
    switch (orderProcessType) {
      case OrderProcessType.CLOSEACCOUNT:
        this.orderTypeList = filterOrderTypeListCancellation;
        break;
      case OrderProcessType.JUDICIAL:
        this.orderTypeList = filterOrderTypeListJudicial;
        break;
      case OrderProcessType.RECONCILE:
        this.orderTypeList = this.orderTypeListReconcile;
        break;
      case OrderProcessType.RECOVERY:
        this.orderTypeList = this.orderTypeListRecovery;
        break;
      default:
        this.statusList = [];
        break;
    }
  }
  getData() {
    this.dataSharedService.clearFilterEvent$.subscribe((eventData: string) => {
      this.clearFilter(eventData);
    });

    this.isExecutor = this.dataSharedService.isExecutor;

    this.dataSharedService.deleteFilterEvent$.subscribe(() => {
      this.deleteFiltersReload();
    });
  }

  allSelectionDate() {
    this.areAllSelectedDate = !this.areAllSelectedDate;
    this.filterDate = this.filterDate.map((item) => ({
      ...item,
      selected: this.areAllSelectedDate,
    }));
  }

  allSelectionStatus() {
    this.areAllSelectedStatus = !this.areAllSelectedStatus;
    this.statusList = this.statusList.map((item) => ({
      ...item,
      selected: this.areAllSelectedStatus,
    }));
  }

  clearFilter(event: string) {
    const filtersStatusArray = this.paramsQuery.status?.split(',');
    const filtersOrderTypeArray = this.paramsQuery.orderType?.split(',');

    this.statusList.forEach((itemStatus) => {
      if (itemStatus.name === event) {
        itemStatus.selected = false;
        this.paramsQuery.status = filtersStatusArray
          ?.filter((filter) => !filter.includes(itemStatus.value))
          .toString();
        this.listFilters = this.listFilters.filter(
          (filter) => !filter.name.includes(event)
        );
      }
    });
    this.filterDate.forEach((itemDate) => {
      if (itemDate.name === event) {
        itemDate.selected = false;
      }
    });
    this.orderTypeList.forEach((itemProcessType) => {
      if (itemProcessType.name === event) {
        itemProcessType.selected = false;
        this.paramsQuery.orderType = filtersOrderTypeArray
          ?.filter((filter) => !filter.includes(itemProcessType.value))
          .toString();
        this.listFilters = this.listFilters.filter(
          (filter) => !filter.name.includes(event)
        );
      }
    });
    if (this.listFilters.length === 0) {
      this.viewFilters = false;
    }
    this.paramsQuery.skip = 0;
    this.dataSharedService.updateFiltersData({
      paramsQuery: this.paramsQuery,
      listFilters: this.listFilters,
      viewFilters: this.viewFilters,
    });
  }

  updateSelectionStatus() {
    let allSelected = true;
    for (let status of this.statusList) {
      if (!status.selected) {
        allSelected = false;
        break;
      }
    }
    if (allSelected) {
      this.areAllSelectedStatus = true;
    } else {
      this.areAllSelectedStatus = false;
    }
  }

  deleteFilters() {
    this.areAllSelectedDate = false;
    this.areAllSelectedOrdenType = false;
    this.areAllSelectedStatus = false;
    this.statusList = this.statusList.map((item) => ({
      ...item,
      selected: this.areAllSelectedStatus,
    }));
    this.filterDate = this.filterDate.map((item) => ({
      ...item,
      selected: this.areAllSelectedDate,
    }));
    this.orderTypeList = this.orderTypeList.map((item) => ({
      ...item,
      selected: this.areAllSelectedOrdenType,
    }));

    this.viewFilters = false;
    this.formFilterOrderList.controls['textSearchOrders'].setValue('');

    this.paramsQuery = {
      skip: 0,
      limit: 10,
      orderProcessType: this.orderProcessType as OrderProcessType,
      filterCriteriaText: '',
    };
    this.listFilters = [];
    this.dataSharedService.updateFiltersData({
      paramsQuery: this.paramsQuery,
      listFilters: '',
      viewFilters: this.viewFilters,
    });
    this.createConfigGetInitialClean();
  }

  deleteFiltersReload() {
    this.loadListBranchs = true;
    this.deleteFilters();

    this.updateFiltersAndTable();
  }

  updateFiltersAndTable() {
    this.paramsQuery = {
      skip: 0,
      limit: 10,
      orderProcessType: this.orderProcessType as OrderProcessType,
      filterCriteriaText: '',
    };
    this.listFilters = [];
    this.viewFilters = false;
    this.dataSharedService.updateFiltersData({
      paramsQuery: this.paramsQuery,
      listFilters: this.listFilters,
      viewFilters: this.viewFilters,
    });
  }

  allSelectionOrdenType() {
    this.areAllSelectedOrdenType = !this.areAllSelectedOrdenType;
    this.orderTypeList = this.orderTypeList.map((item) => ({
      ...item,
      selected: this.areAllSelectedOrdenType,
    }));
  }

  updateSelection() {
    let allSelected = true;
    for (let orderFilterList of this.orderTypeList) {
      if (!orderFilterList.selected) {
        allSelected = false;
        break;
      }
    }
    if (allSelected) {
      this.areAllSelectedOrdenType = true;
    } else {
      this.areAllSelectedOrdenType = false;
    }
  }

  applyFilters() {
    this.paramsQuery.status = this.statusList
      .filter((status) => status.selected)
      .map((items) => items.value)
      .toString();

    this.paramsQuery.orderType = this.orderTypeList
      .filter((orderType) => orderType.selected)
      .map((items) => items.value)
      .toString();

    if (this.filterDate[0].selected) {
      if (this.selectedDates[0] !== null) {
        this.paramsQuery.start = new Date(this.selectedDates[0]).toISOString();
      } else {
        this.paramsQuery.start = null;
      }

      const endDate = this.selectedDates[1]
        ? new Date(this.selectedDates[1])
        : null;

      this.paramsQuery.end = endDate
        ? new Date(endDate.setDate(endDate.getDate() + 1))
        : null;
    } else {
      this.paramsQuery.start = null;
      this.paramsQuery.end = null;
    }

    this.listFilters = this.statusList
      .concat(this.orderTypeList)
      .filter((x) => x.selected);
    this.viewFilters = true;

    this.paramsQuery.skip = 0;
    this.searchByFiltersEmpty = true;

    this.dataSharedService.updateFiltersData({
      paramsQuery: this.paramsQuery,
      listFilters: this.listFilters,
      viewFilters: this.viewFilters,
    });
  }

  createConfigGetInitialClean() {
    if (!this.orderTypeClean) {
      this.orderTypeClean = '';
      this.dataSharedService.updateFiltersData(this.orderTypeClean);
    }
  }

  formatDate(date: Date): string {
    return date ? date.toLocaleDateString() : '';
  }

  onSelect(event: any): void {
    if (event.length === 2) {
      const startDateInput = document.querySelector(
        'input[placeholder="Fecha inicial"]'
      ) as HTMLInputElement;
      const endDateInput = document.querySelector(
        'input[placeholder="Fecha final"]'
      ) as HTMLInputElement;
      startDateInput.value = this.formatDate(event[0]);
      endDateInput.value = this.formatDate(event[1]);
    }
  }
}
