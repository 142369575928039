import { Component, Input } from '@angular/core';
import { ImageSets } from '../../models/image-sets.schema';

@Component({
  selector: 'app-column-status',
  templateUrl: './column-status.component.html',
})
export class ColumnStatusComponent {
  @Input() status: string = 'EMPTY';
  @Input() custAccountNostatus: string = '';
  @Input() reviewedComments: string = '';
  @Input() old = false;
  icons = ImageSets.icons;
}
