import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancellationsRoutingModule } from './cancellations.routes';
import { GraphqlAccountService } from 'src/app/services/graphql/graphql-account.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeUnaModule } from 'src/app/lib/deuna/deuna.module';
import { PrimeNgModule } from 'src/app/lib/primeng/prime-ng.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';


@NgModule({
  providers: [
    GraphqlAccountService,
  ],
  declarations: [
    CancellationsRoutingModule.PAGES,
    CancellationsRoutingModule.COMPONENTS,
    ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    DeUnaModule,
    PrimeNgModule,
    CommonModule,
    RouterModule,
  ],
  exports: [
    RouterModule,
    CancellationsRoutingModule.PAGES,
    CancellationsRoutingModule.COMPONENTS,
  ],
})
export class CancellationsModule {

 }
