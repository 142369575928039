import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigurationBackOfficeService } from 'src/app/services/graphql/configuration-flow-backoffice.service';
import {
  CreateOrderService,
  SelectOption,
} from 'src/app/services/incidentandorders/create-order.service';
import {  CreateCustomerAccountOrder } from 'src/app/shared/models/config-backoffice.schema';
import {
  allRequesterTypes,
  closeAccountTypes,
} from 'src/app/shared/models/order-types.schema';
import { validate as isValidUUID } from 'uuid';
import { GraphqlAccountService } from 'src/app/services/graphql/graphql-account.service';
import { ValidateFilesHelpers } from 'src/app/shared/helpers/validate-files';

import { ErrorHandlingService } from 'src/app/services/http/error-handling.service';
import { DataSharingService } from 'src/app/services/http/data-sharing.service';

export interface OrderFileDescription {
  type: string;
  file?: File;
  required: boolean;
}

@Component({
  selector: 'app-create-customer-order-step2',
  templateUrl: './create-customer-order-step2.component.html',
  styleUrls: ['./create-customer-order-step2.component.scss'],
})
export class CreateCustomerOrderStep2Page implements OnInit, OnDestroy {
  maxFileSize = 10;
  isReverseOrderActive = false;
  uuid: string = '';
  orderTypes!: Array<SelectOption>;
  requesterTypes!: Array<SelectOption>;
  channelID: string | undefined;
  displayConfirmModal: boolean = false;
  displayCancelModal: boolean = false;
  loading: boolean = false;
  selectedAccountMgmt: string | undefined;
  selectedRequesterType: string | undefined;
  idDelServicio: string = ''

  formStep2: FormGroup = this.formBuilder.group({
    orderType: [
      this.createOrderService.step1Result?.name || '',
      [Validators.required],
    ],
    requesterType: ['', [Validators.required]],
    customerIdType: ['', [Validators.required]],
    accountId: ['', [Validators.required, Validators.minLength(10)]],
    customerId: [
      '',
      [
        Validators.required, Validators.minLength(10),
      ],
    ],

    fullName: ['', [Validators.required, Validators.minLength(4)]],
    evidenceFile: [
      null,
      [
        Validators.required,
        this.validateFiles.validateFileExtension(['.zip']),
        this.validateFiles.validateFileSize(this.maxFileSize),
      ],
    ],

  });

  nameOrderType: string | undefined;

  constructor(
    private router: Router,
    private readonly formBuilder: FormBuilder,
    private createOrderService: CreateOrderService,
    private readonly configurationBackOfficeService: ConfigurationBackOfficeService,
    private readonly graphqlAccountService: GraphqlAccountService,
    private validateFiles: ValidateFilesHelpers,
    private orderIdService: DataSharingService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.requesterTypes = allRequesterTypes;
  }

  get initialOrderType() {
    return this.createOrderService.step1Result?.label;
  }

  ngOnInit(): void {
    this.initData();
    this.getNameOrderType();
  }

  get showCreateCancellationOrderWarning() {
    return (
      this.channelID === '1001' ||
      this.channelID === '1004'
    );
  }

  get requesterTypeIsValid() {
    return this.formStep2.controls['requesterType'].valid;
  }

  getNameOrderType() {


    this.channelID = closeAccountTypes.find(
      (Ordertype) =>
        Ordertype.actor === this.selectedRequesterType &&
        Ordertype.type === this.selectedAccountMgmt
    )?.process;

  }

 validateUUID(): any | string {
    return (control: FormControl) => {
      const uuid = control.value as string;
      if (!isValidUUID(uuid) && this.isReverseOrderActive) {
        return { originalTransactionUUId: true };
      }
      return null;
    };
  }


  isFieldInvalid(fieldName: string): any {
    return (
      this.formStep2.controls?.[fieldName]?.errors &&
      this.formStep2.controls?.[fieldName]?.touched
    );
  }

  get step1Result() {
    return this.createOrderService.step1Result;
  }

  openConfirmModal() {
    this.displayConfirmModal = true;
  }

  hideConfirmModal() {
    this.displayConfirmModal = false;
  }

  confirmOrderCancellation() {
    this.router.navigate(['people/incidentorders/list']);
  }

  cancelOrderCreation() {
    this.displayConfirmModal = false;
  }

  openCancelModal() {
    this.displayCancelModal = true;
  }

  hideCancelModal() {
    this.displayCancelModal = false;
  }

  changeOrderTypeValue() {
    const { label, name } = this.formStep2.value['orderType'] as SelectOption;

    this.createOrderService.setStep1Value({ label, name });
    this.nameOrderType = this.createOrderService.step1Result?.name;
    this.selectedAccountMgmt = name;

   if (name === 'Reverso') {
     this.isReverseOrder();
   }


    this.getNameOrderType();
  }

  changeRequesterTypeValue() {
    const { label, name } = this.formStep2.value[
      'requesterType'
    ] as SelectOption;
    this.formStep2.controls['customerIdType'].setValue(
      label === 'Titular' ? 'Cedula' : 'RUC'
    );

    this.createOrderService.setRequesterType({ label, name });

    this.selectedRequesterType = name;

    this.getNameOrderType();
  }

  initData() {
    this.orderTypes =  this.validateFiles.setOrderTypes()
    const { name } = JSON.parse(
      localStorage.getItem('create_order_step1')!
    ) as SelectOption;
    
    this.selectedAccountMgmt = name;
    const orderTypeControl = this.formStep2.controls['orderType'];
    orderTypeControl.setValue(name);
  }

  receiveEvidencesFile(file: File) {
    const control = this.formStep2.get('evidenceFile');
    if (!control) return;
    control.setValue(file);
    control.markAsTouched();
  }

  isReverseOrder(): boolean {
    const orderType = this.channelID;
    if (!orderType) return false;


    if (parseInt(orderType, 10) > 1003) {
      this.isReverseOrderActive = true
      this.formStep2.addControl('originalTransactionRefId', this.formBuilder.control('', [Validators.required, Validators.minLength(5)]));
      this.formStep2.addControl('originalTransactionUUId', this.formBuilder.control('', [Validators.required, Validators.minLength(36), this.validateUUID()]));
      this.formStep2.addControl('reverseReason', this.formBuilder.control('', [Validators.required]));

    }

    return parseInt(orderType, 10) > 1003;
  }


  onSubmit() {

    if (this.formStep2.invalid) {
      this.formStep2.markAllAsTouched();
      return;
    }
    this.displayConfirmModal = true;
  }

  filterFileUpload(contentsUrl) {
    return this.filesToUpload
    .filter(({ file }) => file)
    .map(({ type, file }) => {
      if (!file) {
        throw new Error(`Required file is missing`);
      }
      return {
        url: contentsUrl.find((contentUrl) => contentUrl.type === type)!.value, content: file,
      };
    })
  }

  confirmOrderCreation() {
    this.loading = true;

    const custAccountNo = Number(this.formStep2.controls['accountId'].value);
    if (this.isReverseOrderActive) {
       this.uuid = this.formStep2.controls['originalTransactionUUId'].value as string;
    }



    this.graphqlAccountService.createCustomerAccountPresignedUrlToFileUpload({
        orderType: this.channelID!,
        custAccountNo,
        id: this.isReverseOrderActive ? this.uuid  : undefined
      })
      .subscribe({
        next: (res) => this.handlePresignedUrlResponse(res),
        error: (error) => this.handleOrderCreationError(error)
      });
  }

  handlePresignedUrlResponse(res: any) {
    if (!res.body) {
      this.handleOrderCreationError(res);
      return;
    }

    const { id, contentsUrl, orderId, orderArtifactsStoreUrl } =
      res.body;

    this.uploadFilesToS3Bucket(contentsUrl, id, orderId, orderArtifactsStoreUrl);
  }

  uploadFilesToS3Bucket(contentsUrl: string, id: string, orderId: string, orderArtifactsStoreUrl: string) {
    this.configurationBackOfficeService.uploadFilesToS3Bucket(this.filterFileUpload(contentsUrl))
    .subscribe({
      next: () => this.createCustomerAccountOrder(id, orderId, orderArtifactsStoreUrl),
      error: (error) => this.handleOrderCreationError(error)
    });

  }

  createCustomerAccountOrder(id: string, orderId: string, orderArtifactsStoreUrl: string) {
    const custName = this.formStep2.controls['fullName'].value as string;
    const custDocId = this.formStep2.controls['customerId'].value as string;
    const custDocTypeId = this.formStep2.controls['customerIdType'].value as string;

    let orderData: CreateCustomerAccountOrder = {
      id,
      orderProcessType: 'CLOSE-ACCOUNT',
      orderName: orderId,
      orderArtifactsStoreUrl,
      custDocTypeId,
      custDocId,
      custName,
    };

    if (this.isReverseOrderActive) {
      const originalTransactionRefId = this.formStep2.controls['originalTransactionRefId'].value as string;
      const originalTransactionUUId = this.formStep2.controls['originalTransactionUUId'].value as string;
      const reverseReason = this.formStep2.controls['reverseReason'].value as string;

      if (this.isReverseOrderActive) {
        orderData.orderAdditionalData = {
          originalTransactionRefId,
          originalTransactionUUId,
          reverseReason,
        };
      }
    }

    this.orderIdService.setOrderId(orderId);

    this.graphqlAccountService.createCustomerAccountOrder(orderData)
    .subscribe({
      next: () => this.handleOrderCreationSuccess(),
      error: (error) => this.handleOrderCreationError(error)

    });

  }

  handleOrderCreationSuccess() {

    localStorage.removeItem('create_order_step1');
    this.router.navigate(['/people/incidentorders/create-order/success']);
    this.formStep2.reset();
  }

  handleOrderCreationError(error: any) {

    const errorMessage = this.errorHandlingService.handleErrorResponse(error);

    this.router.navigate(['/people/incidentorders/create-order/error'], {
      queryParams: { message: errorMessage }
    });
  }

  get filesToUpload(): OrderFileDescription[] {
    return [
      {
        type: 'EVIDENCE',
        file: this.formStep2.controls['evidenceFile'].value,
        required: false,
      },
    ];
  }

  ngOnDestroy(): void {
    this.loading = false;
    this.displayConfirmModal = false;
  }
}
