// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[2]!../node_modules/primeng/resources/themes/tailwind-light/theme.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[2]!../node_modules/primeng/resources/primeng.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
html {
  font-style: normal;
  font-size: 14;
}

.line {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: solid;
  border-width: 0.5px;
  border-color: #BDC3D2;
}

.bold-input {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.text-style-back {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #4C1D80;
  cursor: pointer;
}

.titles {
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
}

strong {
  font-weight: 700;
}

.title-side {
  font-weight: 600;
  font-size: 25px;
  line-height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AACA,8EAAA;AAIA;EACI,kBAAA;EACA,aAAA;AADJ;;AAGA;EACI,cAAA;EACA,qBAAA;EACA,yBAAA;EACA,uBAAA;EACA,yBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,qBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,cAAA;EACA,eAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ","sourcesContent":["\r\n/* You can add global styles to this file, and also import other style files */\r\n@import 'primeng/resources/themes/tailwind-light/theme.css';\r\n@import 'primeng/resources/primeng.min.css';\r\n\r\nhtml{\r\n    font-style: normal;\r\n    font-size: 14;\r\n}\r\n.line{                                                                               \r\n    display: block;\r\n    unicode-bidi: isolate;\r\n    margin-block-start: 0.5em;\r\n    margin-block-end: 0.5em;\r\n    margin-inline-start: auto;\r\n    margin-inline-end: auto;\r\n    overflow: hidden;\r\n    border-style: solid;\r\n    border-width: 0.5px;\r\n    border-color: #BDC3D2;\r\n}\r\n\r\n.bold-input{\r\n    font-weight: 700;\r\n    font-size: 16px;\r\n    line-height: 24px;\r\n    color: #000000;\r\n}\r\n\r\n.text-style-back{\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n    text-decoration-line: underline;\r\n    color: #4C1D80;\r\n    cursor: pointer;\r\n}\r\n\r\n.titles{\r\n    font-weight: 700;\r\n    font-size: 48px;\r\n    line-height: 52px;\r\n}\r\n\r\nstrong{\r\n    font-weight: 700;\r\n}\r\n\r\n.title-side{\r\n    font-weight: 600;\r\n    font-size: 25px;\r\n    line-height: 36px;\r\n}\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
