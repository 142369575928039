import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';

export interface SelectOption {
  name: string;
  label: string;
}

export interface OrderProcessType {
  label: string;
  tabindex: string;
}

@Injectable({
  providedIn: 'root'
})
export class CreateOrderService {
  step1Result?: SelectOption;
  requesterType?: SelectOption;
  activeOrderProcessType?: OrderProcessType;

  tokenLocation!: 'LOCAL' | 'SESSION';
  tokenName!: string;
  rolesPropertyName!: string;

  constructor() {
    this.initData();
  }

  initData() {
    const step1ResultFromLocalStorage =
      localStorage.getItem('create_order_step1');
    const activeOrderProcessTypeFromLocalStorage = sessionStorage.getItem(
      'active_order_process_type'
    );
    this.step1Result = step1ResultFromLocalStorage
      ? (JSON.parse(step1ResultFromLocalStorage) as SelectOption)
      : undefined;

    this.activeOrderProcessType = activeOrderProcessTypeFromLocalStorage
      ? (JSON.parse(activeOrderProcessTypeFromLocalStorage) as OrderProcessType)
      : undefined;
  }

  setActiveOrderProcessType(activeItem: MenuItem) {
    this.activeOrderProcessType = {
      label: activeItem.label!,
      tabindex: activeItem.tabindex!,
    };
  }

  setRequesterType(requesterType: SelectOption) {
    this.requesterType = { ...requesterType };
  }

  isOrderProcessTypeValid() {
    if (!this.activeOrderProcessType) {
      return false;
    }
    const { label, tabindex } = this.activeOrderProcessType;
    return Boolean(label && tabindex);
  }

  setStep1Value(result: SelectOption) {
    localStorage.setItem('create_order_step1', JSON.stringify(result));
    this.step1Result = result;
  }

  isStep1Completed() {
    if (!this.step1Result) {
      return false;
    }
    
    const { label, name } = this.step1Result;
    return label && name;
  }
}
