import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { map } from 'rxjs';
import {
  CreateMassiveAdjustmentOrder,
  CreateMassiveTxnsPresignedUrl,
  DeleteMassiveTxnResultResponseDto,
  DownloadOrderContentResponseDto,
  OrderResultResponseDto,
  ResponseFileValidation,
  ResponseFileValidationExtraData,
} from 'src/app/shared/models/config-backoffice.schema';

import {
  AccountOrderResultResponse,
  AdjustmenOrderResponse,
  IMutationReviewDecision,
  IQueryDataOrders,
  IQueryDataOrdersPts,
  OrderListResponse,
} from 'src/app/shared/models/data-table.schema';
import {
  create_adjustment,
  massive_createpresign,
  massive_delete,
  massive_download,
  massive_orders,
  massive_pts_orders,
  review_decision,
} from './graphql-massivetxns';

@Injectable()
export class GraphqlMassivetxnsService {
  apollo: ApolloBase;

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('newClientName');
  }

  getOrders(data: IQueryDataOrders) {
    return this.apollo
      .query<OrderListResponse>({
        query: massive_orders,
        variables: {
          data,
        },
      })
      .pipe(map((response) => response.data.filterMassiveTxns));
  }

  approveMassiveTxnOrder(data: IMutationReviewDecision) {
    return this.apollo
      .mutate<AccountOrderResultResponse>({
        mutation: review_decision,
        variables: {
          data,
        },
      })
      .pipe(map((response) => response.data?.body));
  }

  createMassiveTxnOrder(orderInfo: CreateMassiveAdjustmentOrder) {
    return this.apollo
      .mutate<OrderResultResponseDto>({
        mutation: create_adjustment,
        variables: {
          data: { ...orderInfo },
        },
      })
      .pipe(map((response) => response.data));
  }

  deleteMassiveTxnOrder(orderId: string) {
    return this.apollo
      .mutate<DeleteMassiveTxnResultResponseDto>({
        mutation: massive_delete,
        variables: {
          data: { id: orderId },
        },
      })
      .pipe(
        map((response) => {
          return response?.data?.deleteMassiveTxnOrder?.body ?? null;
        })
      );
  }

  downloadMassiveTxnContent(
    id: string,
    orderType: string,
    orderId: string,
    contentType: string
  ) {
    return this.apollo
      .query<DownloadOrderContentResponseDto>({
        query: massive_download,
        variables: {
          data: { id, orderType, orderId, contentType },
        },
      })
      .pipe(map((response) => response.data.downloadMassiveTxnContent));
  }

  createPresignedUrlToFileUpload(orderType: string) {
    return this.apollo
      .mutate<CreateMassiveTxnsPresignedUrl>({
        mutation: massive_createpresign,
        variables: {
          data: { orderType },
        },
      })
      .pipe(
        map((response) => {
          if (response?.data?.createPreSignUrlForMassiveTxnUpload) {
            return response.data.createPreSignUrlForMassiveTxnUpload.body;
          }
          return null;
        })
      );
  }
  uploadFile(
    file: File,
    strategy: string,
    extraData: ResponseFileValidationExtraData
  ) {
    return this.apollo
      .mutate<ResponseFileValidation>({
        mutation: gql`
          mutation fileValidationMassive(
            $file: Upload!
            $extraData: FileValidationExtraData
          ) {
            fileValidationMassive(
              file: $file
              strategy: "${strategy}"
              extraData: $extraData
            ) {
              errorMessage {
                title
                message
              }
              success
              listObjects {
                analysisData {
                  totalRecord
                  totalAmount
                }
              }
              errorMapping {
                reason
                item
              }
            }
          }
        `,
        variables: {
          file,
          extraData,
        },
        context: {
          useMultipart: true,
        },
      })
      .pipe(
        map((response) => {
          if (response?.data?.fileValidationMassive) {
            return response.data.fileValidationMassive;
          }
          return null;
        })
      );
  }

  getOrdersMassivePts(data: IQueryDataOrdersPts) {
    return this.apollo
      .query<AdjustmenOrderResponse>({
        query: massive_pts_orders,
        variables: {
          data,
        },
      })
      .pipe(
        map((response) => response.data.filterAdjustmentOrderRequests.body)
      );
  }
}
