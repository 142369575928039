import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';


@Component({
  selector: 'app-delete-order-error',
  templateUrl: './delete-order-error.component.html',
  styleUrls: ['./delete-order-error.component.scss'],
})
export class DeleteOrderErrorPage implements OnInit {
  image = assetUrl('png/image-report-error-r.png');
  errorMessage: string = 'Ocurrió un problema al crear tu orden';
  subtitleError: boolean = false;
  id: string = '';
  orderId: string = '';
  loading: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params['id'] || '';
      this.orderId = params['orderId'] || '';
      this.errorMessage = params['message'] || 'Ocurrió un problema al crear tu orden';
    });
    this.updateSubtitleError();
  }
  updateSubtitleError() {
    this.subtitleError = this.errorMessage === 'Ocurrió un problema al crear tu orden';
  }
  get orderInformation() {
    return {
      id: this.id,
      orderId: this.orderId
    };
  }

  goToHome() {
    this.router.navigate(['people/incidentorders/list']);
  }
}
