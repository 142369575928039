import { ActivatedRoute, Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';

export abstract class OrderErrorBasePage {
  image = assetUrl('png/image-report-error-r.png');
  errorMessage: string = '';
  subtitleError: boolean = false;
  id: string = '';
  orderId: string = '';
  loading: boolean = false;

  constructor(protected router: Router, protected activatedRoute: ActivatedRoute) {}

  init(defaultMessage: string) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id'] || '';
      this.orderId = params['orderId'] || '';
      this.errorMessage = params['message'] || defaultMessage;
      this.updateSubtitleError();
    });
  }

  updateSubtitleError() {
    this.subtitleError = this.errorMessage === this.getDefaultMessage();
  }

  goToHome() {
    this.router.navigate(['people/incidentorders/list']);
  }

  protected abstract getDefaultMessage(): string;
}
