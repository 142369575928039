import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared.routes';
import { RouterModule } from '@angular/router';
import { DeUnaModule } from '../lib/deuna/deuna.module';
import { PrimeNgModule } from '../lib/primeng/prime-ng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [SharedRoutingModule.COMPONENTS, SharedRoutingModule.PAGES],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DeUnaModule,
    PrimeNgModule,
    RouterModule,
  ],
  exports: [
    RouterModule,
    DeUnaModule,
    PrimeNgModule,
    SharedRoutingModule.COMPONENTS,
    SharedRoutingModule.PAGES,
  ],
})
export class SharedModule {}
