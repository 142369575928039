import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';

import { MessageService } from 'primeng/api';
import { GraphqlMassivetxnsService } from 'src/app/services/graphql/graphql-massivetxns.service';
import { ErrorHandlingService } from 'src/app/services/http/error-handling.service';
@Component({
  selector: 'app-approve-reject-order-error',
  templateUrl: './approve-reject-order-error.component.html',
  styleUrls: ['./approve-reject-order-error.component.scss'],
})
export class ApproveOrRejectOrderErrorPage implements OnInit {
  image = assetUrl('png/image-report-error-r.png');
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private servicesMassiveTxnsGql: GraphqlMassivetxnsService,
    private messageService: MessageService,
    private errorHandlingService: ErrorHandlingService
  ) {}
  id: string = '';
  reviewDecision: string = '';
  reviewComments: string = '';
  orderId: string = '';
  loading: boolean = false;
  errorMessage: string | null = 'Ocurrió un problema al crear tu orden';
  subtitleError: boolean = false;



  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params['id'] || '';
      this.reviewDecision = params['reviewDecision'] || '';
      this.reviewComments = params['reviewComments'] || '';
      this.orderId = params['orderId'] || '';
      this.errorMessage = params['message'] || 'Ocurrió un problema al crear tu orden';
    });

   this.updateSubtitleError();
  }
  
   updateSubtitleError() {
    this.subtitleError = this.errorMessage === 'Ocurrió un problema al crear tu orden';
  }

  get orderInformation() {
    return {
      id: this.id,
      reviewDecision: this.reviewDecision,
      reviewComments: this.reviewComments,
      orderId: this.orderId
    };
  }

  retryProcess() {
    this.loading = true;
    return this.servicesMassiveTxnsGql
      .approveMassiveTxnOrder({ ...this.orderInformation })
      .subscribe({
        next: () => {
          this.loading = false;
          this.messageService.add({
            severity: 'success',
            detail: 'Aprobaste la Orden N° ' + this.id,
          });
          this.router.navigate(['people/incidentorders/list']);
        },
        error: (error) => {
          const errorMessage = this.errorHandlingService.handleErrorResponse(error);
          console.log(errorMessage);
          this.loading = false;
        },
      });
  }

  goToHome() {
    this.router.navigate(['people/incidentorders/list']);
  }
}
