import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumOrderStatus } from 'src/app/constants/order-status.enum';
import { TableActionsAdjustmentComponent } from 'src/app/modules/adjustment/components/table-actions-adjustment/table-actions-adjustment.component';
import { GraphqlAccountService } from 'src/app/services/graphql/graphql-account.service';
import { GraphqlMassivetxnsService } from 'src/app/services/graphql/graphql-massivetxns.service';
import { ErrorHandlingService } from 'src/app/services/http/error-handling.service';

@Component({
  selector: 'app-table-actions-recovery',
  templateUrl:
    '../../../../shared/components/table-actions-base/table-actions-base.component.html',
  styleUrls: [
    '../../../../shared/components/table-actions-base/table-actions-base.component.css',
  ],
})
export class TableActionsRecoveryComponent extends TableActionsAdjustmentComponent {
  constructor(
    servicesAccountGql: GraphqlAccountService,
    http: HttpClient,
    messageService: MessageService,
    router: Router,
    errorHandlingService: ErrorHandlingService,
    servicesMassivetxnsGql: GraphqlMassivetxnsService
  ) {
    super(
      servicesAccountGql,
      http,
      messageService,
      router,
      errorHandlingService,
      servicesMassivetxnsGql
    );
  }

  override confirmExpireOrder(): void {
    const { orderId, status } = this.selectedOrderToId;
    if (status !== EnumOrderStatus.PROCESSING) return;

    this.servicesMassivetxnsGql
      .requestExpireMassiveTxnOrder(orderId)
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail:
              'Solicitaste caducar la orden N°' +
              this.selectedOrderToId.orderId,
          });
          this.refreshOrders();
        },
        error: (error) => {
          const errorMessage =
            this.errorHandlingService.handleErrorResponse(error);

          this.hideOrderExpireModal();
          this.displayDeleteOrderModal = false;
          this.router.navigate([`people/incidentorders/order-expire/error`], {
            queryParams: {
              id: this.selectedOrderToId.id,
              message: errorMessage,
              orderId: this.requestOrderNumberById(
                this.selectedOrderToId.orderId
              ),
            },
          });
        },
      });
    this.hideOrderExpireModal();
    this.displayExpireOrderModal = false;
  }
  override confirmApprovedExpireOrder(): void {
    if (this.displayRejectText) {
      this.confirmRejectExpireOrder();
      return;
    }
    const { orderId, status } = this.selectedOrderToId;
    if (status !== EnumOrderStatus.PENDING_EXPIRATION) return;
    this.servicesMassivetxnsGql.expireMassiveTxnOrder(orderId).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail:
            'Aprobaste la caducidad manual de la orden N°' +
            this.selectedOrderToId.orderId,
        });
        this.hideApprovedOrderExpireModal();
        this.hideOrderExpireModal();
        this.displayDeleteOrderModal = false;
        this.displayExpireOrderModal = false;
        this.refreshOrders();
      },
      error: (error) => {
        const errorMessage =
          this.errorHandlingService.handleErrorResponse(error);

        this.hideApprovedOrderExpireModal();
        this.displayDeleteOrderModal = false;
        this.hideOrderExpireModal();
        this.displayExpireOrderModal = false;
        this.router.navigate([`people/incidentorders/order-expire/error`], {
          queryParams: {
            id: this.selectedOrderToId.id,
            message: errorMessage,
            orderId: this.requestOrderNumberById(
              this.selectedOrderToId.orderId
            ),
          },
        });
      },
    });
  }
  confirmRejectExpireOrder(): void {
    this.selectedOrderToReject = {
      id: this.selectedOrderToId.id,
      reviewDecision: EnumOrderStatus.APPROVED,
    };
    this.rejectOrderAccount();
  }
}
