<div class="col-12 mx-6 pr-2 text-center" *ngIf="loadListBranchs">
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
  <h2 class="mt-2">Cargando....</h2>
</div>
<p-toast position="bottom-right"></p-toast>
<div
  [ngClass]="{
    table: !showSideBar,
    tableCollapse: showTable
  }"
  class="grid table mb-4"
  *ngIf="!loadListBranchs"
>
  <div class="col-12 mx-6 pb-6 pr-2">
    <ng-container *ngIf="viewFilters">
      <div class="grid mb-4">
        <div class="col-12">
          <strong>Filtros aplicados</strong>
          <deuna-chip
            class="chipFilters"
            *ngFor="let item of listFilters"
            backgroundColor="#ececfb"
            color="#46246B"
            [text]="item.name"
            [action]="true"
            [valueAction]="'X'"
            (closeChip)="clearFilter($event)"
          >
          </deuna-chip>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="dataLoad == true; else table">
      <app-modal-selector
        [modalType]="modalSelectorTypes"
        [modalErrorProps]="loadingErrorProps"
        (deleteFilters)="deleteFiltersReload()"
        (reloadComponent)="reloadComponent()"
      ></app-modal-selector>
    </ng-container>
    <ng-template #table>
      <deuna-table [arrayData]="[1]" [columnas]="columns">
        <ng-container *ngFor="let order of ordersList.items">
          <tr>
            <td>{{ order.orderId }}</td>
            <td
              class="left-aling"
              *ngIf="isMassive && isExecutor"
              style="white-space: nowrap; list-style-type: circle"
            >
              <app-column-status
                [status]="
                  order.approvalStatus == null ? 'EMPTY' : order.approvalStatus
                "
                [reviewedComments]="order.reviewedComments"
                [custAccountNostatus]="order.custAccountNostatus"
              ></app-column-status>
            </td>
            <td
              class="left-aling"
              *ngIf="isExecutor"
              style="white-space: nowrap; list-style-type: circle"
            >
              <app-column-status
                [status]="order.status"
                [old]="
                  actionsType !== actionTypeEnum.JUDICIAL &&
                  actionsType !== actionTypeEnum.RECOVERY
                "
                [reviewedComments]="order.reviewedComments"
                [custAccountNostatus]="order.custAccountNostatus"
              ></app-column-status>
            </td>
            <ng-container *ngIf="actionsType === actionTypeEnum.CLOSEACCOUNT">
              <td>{{ order.createdOnDate | date : "dd/MM/yyyy" }}</td>
              <td>{{ getOrderTypeListAccount(order.orderType) }}</td>
              <td>{{ getOrderActor(order.orderType) }}</td>
              <td>{{ order.orderType }}</td>
              <td>{{ order.custAccountNo }}</td>

              <td>{{ order.custDocId }}</td>
              <td>
                {{ order.createdByUser }}
              </td>
              <td>
                {{ order?.reviewedByUser }}
              </td>
              <td>
                <div *ngIf="order.processingResponseData!!">
                  <p>
                    <strong> Ref: </strong>
                    <small>{{
                      order.processingResponseData.extReferenceId
                    }}</small>
                  </p>
                  <p>
                    <strong>Id: </strong
                    ><small>{{
                      order.processingResponseData.extReferenceUUID
                    }}</small>
                  </p>
                </div>
              </td>
              <td>
                <div *ngIf="order.orderAdditionalData!!">
                  <p>
                    <strong> Ref: </strong>
                    <small>{{
                      order.orderAdditionalData.originalTransactionRefId
                    }}</small>
                  </p>
                  <p>
                    <strong>Id: </strong
                    ><small>{{
                      order.orderAdditionalData.originalTransactionUUId
                    }}</small>
                  </p>
                </div>
              </td>
            </ng-container>
            <ng-container
              *ngIf="
                actionsType === actionTypeEnum.RECONCILE ||
                actionsType === actionTypeEnum.JUDICIAL
              "
            >
              <td style="text-align: center">
                {{ order.createdOnDate | date : "dd/MM/yyyy" }}
              </td>
              <td>
                {{ getOrderTypeListMassive(order.orderType) }}
              </td>
              <td>{{ order.orderType }}</td>
              <td>
                {{ order.createdByUser }}
              </td>
              <td>
                {{ order.reviewedByUser }}
              </td>
              <td style="text-align: center">
                {{ order.orderAdditionalData?.totalRecord }}
              </td>
              <td style="text-align: right">
                {{
                  order.orderAdditionalData?.totalAmount
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
            </ng-container>
            <ng-container *ngIf="actionsType === actionTypeEnum.RECOVERY">
              <td style="text-align: center">
                {{ order.createdOnDate | date : "dd/MM/yyyy" }}
              </td>
              <td style="text-align: center" *ngIf="order.processInfo">
                {{ order.processInfo.endDate | date : "dd/MM/yyyy" }}
              </td>
              <td style="text-align: center" *ngIf="!order.processInfo"></td>
              <td>
                {{ getOrderTypeListMassive(order.orderType) }}
              </td>
              <td>{{ order.orderType }}</td>
              <td>
                {{ order.createdByUser }}
              </td>
              <td>
                {{ order.reviewedByUser }}
              </td>
              <td style="text-align: center">
                {{ order.orderAdditionalData?.totalRecord }}
              </td>
              <td style="text-align: right">
                {{
                  order.orderAdditionalData?.totalAmount
                    | currency : "USD" : "symbol" : "1.2-2"
                }}
              </td>
            </ng-container>
            <td *ngIf="isReader && isMassive">
              <span
                *ngIf="
                  order.status === 'COMPLETED' ||
                  showProcessingModalExpire(order.status) ||
                  (order.status === 'PROCESSING' &&
                    showProcessingModal(order.orderType))
                "
              >
                <img
                  (click)="handleMassivePtsModal(order)"
                  style="cursor: pointer"
                  [src]="icons.see"
                  title="Ver"
                  alt="process_completed"
                />
              </span>
              <span *ngIf="order.status === 'COMPLETED_WITH_ERRORS'">
                <img
                  (click)="handleMassivePtsModal(order)"
                  style="cursor: pointer"
                  [src]="icons.warning"
                  alt="process_fail"
                />
              </span>
            </td>
            <td *ngIf="isApprover">
              <span class="flex flex-direction-row justify-content-between">
                <img
                  (click)="openDownloadOrderFile(order)"
                  style="cursor: pointer; margin-right: 0px"
                  [src]="icons.download"
                  title="Descargar"
                  alt="download"
                />
                <img
                  class="pl-5"
                  (click)="
                    reviewDecisionApprover(order, reviewDecision.APPROVED)
                  "
                  [title]="!enabledApprover(order) ? 'Aprobar': 'Aprobar caducidad'"
                  [style]="{
                    cursor:
                      order.status !== 'NEW' &&
                      !enabledApprover(order)
                        ? 'default'
                        : 'pointer',
                    opacity:
                      order.status !== 'NEW' &&
                      !enabledApprover(order)
                        ? 0.2
                        : 1
                  }"
                  [src]="icons.okay"
                  alt="approve order"
                />
                <img
                  class="pl-5"
                  (click)="reviewDecisionReject(order, reviewDecision.REJECTED)"
                  style="cursor: pointer"
                  [title]="!enabledApprover(order) ? 'Rechazar': 'Rechazar caducidad'"
                  [style]="{
                    cursor:
                      order.status !== 'NEW' &&
                      !enabledApprover(order)
                        ? 'default'
                        : 'pointer',
                    opacity:
                      order.status !== 'NEW' &&
                      !enabledApprover(order)
                        ? 0.2
                        : 1
                  }"
                  [src]="icons.reject"
                  alt="reject order"
                />
              </span>
            </td>
            <td
              *ngIf="isExecutor && actionsType === actionTypeEnum.CLOSEACCOUNT"
            >
              <span *ngIf="order.status === 'PENDING'">
                <img
                  (click)="reviewChangeStatus(order)"
                  style="cursor: pointer"
                  [src]="icons.arrow"
                  alt="change-status"
                />
              </span>
            </td>
            <td *ngIf="isExecutor" style="text-align: right">
              <span
                *ngIf="
                  actionsType === actionTypeEnum.RECOVERY &&
                  order.status === 'ERROR'
                "
              >
                <img
                  (click)="handleMassivePtsModal(order)"
                  style="cursor: pointer; margin-right: 15px"
                  [src]="icons.danger"
                  title="Fallida"
                  alt="process_error"
                />
              </span>
              <span>
                <img
                  (click)="openDownloadOrderFile(order)"
                  style="cursor: pointer; margin-right: 15px"
                  [src]="icons.download"
                  title="Descargar"
                  alt="downloadExe"
              /></span>
              <span *ngIf="!showExpireBtn(order)">
                <img
                  (click)="deleteOrder(order)"
                  [style]="{
                    cursor:
                      order.status !== 'NEW' &&
                      order.approvalStatus !== 'PENDING'
                        ? 'default'
                        : 'pointer',
                    opacity:
                      order.status !== 'NEW' &&
                      order.approvalStatus !== 'PENDING'
                        ? 0.2
                        : 1
                  }"
                  [src]="icons.deleteOrderImg"
                  alt="delete order"
                />
              </span>
              <span *ngIf="showExpireBtn(order)">
                <img
                  (click)="expireOrder(order)"
                  [style]="{
                    cursor: expireActions(order) ? 'default' : 'pointer',
                    opacity: expireActions(order) ? 0.2 : 1
                  }"
                  [src]="icons.trashTime"
                  alt="expire order"
                />
              </span>
            </td>
          </tr>
        </ng-container>
      </deuna-table>
      <deuna-pagination
        (paginaEmitter)="goToPage($event)"
        (itemsEmmiter)="changeToItems($event)"
        [currentPage]="ordersList.meta.currentPage"
        [limitPage]="paramsQuery.limit"
        [totalItems]="ordersList.meta.totalItems"
        [totalPages]="ordersList.meta.totalPages"
      >
      </deuna-pagination>
      <div *ngIf="errorFilter" class="box-error-items">
        <span class="text-error-items"
          >Items por página no puede ser menor que 1 o mayor a 100</span
        >
      </div>
    </ng-template>
  </div>
</div>
<ng-container [ngSwitch]="actionsType">
  <ng-template [ngSwitchCase]="actionTypeEnum.CLOSEACCOUNT">
    <ng-container *ngTemplateOutlet="closeAccount"></ng-container>
  </ng-template>
  <ng-template [ngSwitchCase]="actionTypeEnum.RECONCILE">
    <ng-container *ngTemplateOutlet="reconcile"></ng-container>
  </ng-template>
  <ng-template [ngSwitchCase]="actionTypeEnum.JUDICIAL">
    <ng-container *ngTemplateOutlet="judicial"></ng-container>
  </ng-template>
  <ng-template [ngSwitchCase]="actionTypeEnum.RECOVERY">
    <ng-container *ngTemplateOutlet="recovery"></ng-container>
  </ng-template>
</ng-container>
<ng-template #closeAccount>
  <app-table-actions
    #tableActions
    [paramsQuery]="paramsQuery"
    (reloadComponent)="reloadComponent()"
    (loadingErrorPropsChange)="receiveLoadingErrorProps($event)"
  ></app-table-actions>
</ng-template>
<ng-template #reconcile>
  <app-table-actions-adjustment
    #tableActions
    [paramsQuery]="paramsQuery"
    (reloadComponent)="reloadComponent()"
    (loadingErrorPropsChange)="receiveLoadingErrorProps($event)"
  ></app-table-actions-adjustment>
</ng-template>
<ng-template #judicial>
  <app-table-actions-ordinance
    #tableActions
    [paramsQuery]="paramsQuery"
    (reloadComponent)="reloadComponent()"
    (loadingErrorPropsChange)="receiveLoadingErrorProps($event)"
  ></app-table-actions-ordinance>
</ng-template>
<ng-template #recovery>
  <app-table-actions-recovery
    #tableActions
    [paramsQuery]="paramsQuery"
    (reloadComponent)="reloadComponent()"
    (loadingErrorPropsChange)="receiveLoadingErrorProps($event)"
  ></app-table-actions-recovery>
</ng-template>
