import {
  Component,
  EventEmitter,
  HostBinding,
  Injectable,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { jwtsecurityService } from '@deuna/bo-sh-security-ng';
import { MenuItem } from 'primeng/api/menuitem';
import { Subscription } from 'rxjs';
import {
  ModalParamsLoadingError,
  ModalType,
} from 'src/app/shared/models/constants.backoffice.schema';
import {
  AccountOrderListResponse,
  DataAccountOrders,
  DataPages,
  IMutationReviewDecision,
  IQueryDataOrders,
  OrderProcessType,
  ReviewDecision,
} from 'src/app/shared/models/data-table.schema';
import { ImageSets } from 'src/app/shared/models/image-sets.schema';
import {
  closeAccountTable,
  columnsApproveCancellation,
  columnsExecutorCancellation,
  judicialType,
  judicialTypeNew,
  reconcileType,
  recoveryType,
  resulFieldsTables,
} from 'src/app/shared/models/order-types.schema';
import { backOfficeRoles } from 'src/constants';
import { DataSharingService } from 'src/app/services/http/data-sharing.service';

@Injectable()
@Component({
  selector: 'app-list-base',
  templateUrl: './list-base.component.html',
  styleUrls: ['./list-base.component.scss'],
})
export class ListBaseComponent implements OnInit {
  @Output() openSide = new EventEmitter();
  @Output() countBranchs = new EventEmitter();
  @Input() showTable: boolean = false;
  @ViewChild('tableActions') tableActions!: any;
  @Input() paramsQuery: IQueryDataOrders = {
    orderProcessType: undefined as any,
    skip: 0,
    limit: 10,
  };
  @HostBinding('style.--input-border-bottom') inputBorderBottom =
    '1px solid #BDC3D2';
  @HostBinding('style.--input-color') inputColor = '#202020';
  @HostBinding('style.--input-border-focus') inputBorderFocus =
    '1px solid #BDC3D2';
  @HostBinding('style.--input-color-focus') inputColorFocus = '#202020';

  actionsType: OrderProcessType = undefined as any;
  actionTypeEnum = OrderProcessType;

  backOfficeRoles = backOfficeRoles;
  judicialNew = judicialTypeNew;
  modalSelectorTypes: ModalType = ModalType.NotModal;
  loadingErrorProps: ModalParamsLoadingError = {
    image: '',
    subHeaderText: '',
    headerText: undefined,
    valueAction: '',
    text: '',
    orderId: '',
  };
  inputSub: any;
  valueChanged: any;
  value: any;
  subActivatedRoute!: Subscription;
  securityService = jwtsecurityService;
  icons = ImageSets.icons;
  errorImages = ImageSets.errorImages;
  text!: string;
  currentPage: number = 0;
  limitPage: number = 10;
  itemCount: number = 0;
  totalItems: number = 0;
  itemsPerPage: number = 0;
  totalPages: number = 0;
  loadListBranchs: boolean = false;
  showSideBar: boolean = false;
  resultsDecision: string = '';
  paramsDecision!: IMutationReviewDecision;
  reviewDecision: any = {
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
  };
  formFilterOrderList!: FormGroup;
  orderList: Array<IQueryDataOrders> = [];
  activeItem: MenuItem = {};
  viewFilters: boolean = false;
  listFilters!: Array<any>;
  load: boolean = true;
  dataPagination!: DataPages;
  items: Array<MenuItem> = [];
  showButton = false;
  titleSearch = '';

  totalRecord: number = 0;
  totalAmmount: number = 0;

  resultfields = resulFieldsTables;

  columnsApprove = columnsApproveCancellation;
  columnsExecutor = columnsExecutorCancellation;

  columns: string[] = [];
  dataLoad: boolean = false;
  loading: boolean = false;
  showSide: boolean = false;
  errorCampaign: boolean = false;
  subGraphqlMassivetxnsService!: Subscription;
  accountOrdersList: AccountOrderListResponse = {
    meta: {
      itemCount: 0,
      totalItems: 0,
      currentPage: 0,
      itemsPerPage: 0,
      totalPages: 0,
    },
    items: [],
  };

  searchByFiltersEmpty: boolean = false;
  searchByInputEmpty: boolean = false;

  noOrderListModalActivate: boolean = false;

  ordersDecision: any = '';

  orderTypeClean: any;
  displayStatusModal: string = '';
  selectedOrderToDownload!: DataAccountOrders;

  selectedOrderToId!: DataAccountOrders;
  selectedOrderToConfirm!: IMutationReviewDecision;
  selectedOrderToReject!: IMutationReviewDecision;

  showModal: boolean = false;

  isExecutor = this.canDisplay([backOfficeRoles.EXECUTOR]);
  isApprover = this.canDisplay([backOfficeRoles.APPROVER]);
  isReader = this.canDisplay([backOfficeRoles.READER]);

  rejectionMessage: string = '';
  orderExecute: boolean | null = false;

  dato: any = {};
  ordersList: any;
  errorFilter = false;
  previousSearchText: string = '';
  constructor(private sharedService: DataSharingService) {}

  ngOnInit(): void {
    this.dataShared();
    this.createConfigGetInitial();
    this.validationColumsByRole();
  }

  ngAfterViewInit(): void {
    // Verificar si tableActions está definido
    if (this.tableActions) {
      // Pasar loadingErrorProps al componente hijo
      this.tableActions.loadingErrorProps = this.loadingErrorProps;
    }
  }

  dataShared() {
    this.sharedService.filtersData$.subscribe((filters) => {
      if (filters) {
        const tempParam = {
          ...filters.paramsQuery,
          orderProcessType: this.paramsQuery.orderProcessType,
        };
        this.paramsQuery = tempParam;
        this.listFilters = filters.listFilters;
        this.viewFilters = filters.viewFilters;
        this.loadListBranchs = filters.loadListBranchs;
        if (this.actionsType === filters.paramsQuery.orderProcessType)
          this.getOrdersByPageAccount();
      }

      this.sharedService.searchText$.subscribe((value) => {
        if (
          (value && !this.previousSearchText) ||
          (this.previousSearchText && !value)
        ) {
          this.previousSearchText = value;
          this.paramsQuery.skip = 0;
          this.paramsQuery.filterCriteriaText = value;
          this.searchByFiltersEmpty = true;
          this.getOrdersByPageAccount();
        }
      });
    });
    this.searchByFiltersEmpty = false;
  }

  get backofficeRoles() {
    return backOfficeRoles;
  }

  canDisplay(roles?: string[]) {
    return this.securityService.validateIn(roles);
  }

  validationColumsByRole() {
    const selectedColumns = this.securityService.validateIn([
      this.backofficeRoles.APPROVER,
    ])
      ? [...this.columnsApprove]
      : [...this.columnsExecutor];
    this.columns = selectedColumns;
  }

  createConfigGetInitial() {}

  getOrdersByPageAccount() {}

  toggle() {
    this.showSide = !this.showSide;
  }
  get isMassive() {
    const massiveActionTypes = [
      this.actionTypeEnum.JUDICIAL,
      this.actionTypeEnum.RECOVERY,
      this.actionTypeEnum.RECONCILE,
    ];

    return massiveActionTypes.includes(this.actionsType);
  }
  showProcessingModal(v: string) {
    const labelName =  this.getOrderTypeListMassive(v)
    const orderTypes = [this.judicialNew[0].label, recoveryType[0].label];
    return orderTypes.includes(labelName ?? '');
  }
  openSideBar() {
    this.viewFilters = false;
    this.showSideBar = true;
  }

  goToPage(page: number) {
    if (page === 1) {
      this.paramsQuery.skip = 0;
    } else {
      this.paramsQuery.skip = this.paramsQuery.limit * (page - 1);
    }
    this.getOrdersByPageAccount();
  }

  changeToItems(page: number) {
    if (page > 100 || page < 1) {
      this.inputBorderBottom = '1px solid #a92825';
      this.inputColor = '#a92825';
      // focus
      this.inputBorderFocus = '1px solid #a92825';
      this.inputColorFocus = '#a92825';
      // label
      this.errorFilter = true;
      return;
    }
    if (page === 0 || page === null) {
      this.paramsQuery.limit = 1;
    } else {
      this.paramsQuery.limit = page;
    }
    this.paramsQuery.skip = 0;
    this.getOrdersByPageAccount();
    // clean style
    this.inputBorderBottom = '1px solid #BDC3D2';
    this.inputColor = '#202020';
    this.inputBorderFocus = '1px solid #BDC3D2';
    this.inputColorFocus = '#202020';
    this.errorFilter = false;
  }

  reloadComponent() {
    this.dataLoad = true;
    this.getOrdersByPageAccount();
  }

  clearFilter(event: string) {
    this.sharedService.emitClearFilterEvent(event);
  }

  deleteFiltersReload() {
    this.sharedService.emitDeleteFilterEvent();
  }

  getOrderActor(orderType: string) {
    return closeAccountTable.find((order) => order.process === orderType)
      ?.actor;
  }

  getOrderTypeListAccount(orderType: string) {
    return closeAccountTable.find((order) => order.process === orderType)?.type;
  }
  getOrderTypeListReconcile(orderType: string) {
    return reconcileType.find((order) => order.name === orderType)?.label;
  }
  getOrderTypeListJudicial(orderType: string) {
    return judicialType.find((order) => order.name === orderType)?.label;
  }
  getOrderTypeListRecovery(orderType: string) {
    return recoveryType.find((order) => order.name === orderType)?.label;
  }
  getOrderTypeListMassive(orderType: string) {
    switch (this.actionsType) {
      case OrderProcessType.JUDICIAL:
        return this.getOrderTypeListJudicial(orderType);
      case OrderProcessType.RECONCILE:
        return this.getOrderTypeListReconcile(orderType);
      case OrderProcessType.RECOVERY:
        return this.getOrderTypeListRecovery(orderType);
      default:
        return this.getOrderTypeListJudicial(orderType);
    }
  }

  getOrderNumberById(id: string): string {
    return this.accountOrdersList.items.find((order) => order.id === id)!
      .orderId;
  }

  openDownloadOrderFile(order: any) {
    this.tableActions.openDownloadOrderFileModal(order);
  }

  reviewDecisionApprover(
    orderToApprove: DataAccountOrders,
    decision: ReviewDecision
  ) {
    this.tableActions.reviewDecisionApproverModal(orderToApprove, decision);
  }

  reviewDecisionReject(
    orderToReject: DataAccountOrders,
    decision: ReviewDecision
  ) {
    this.tableActions.reviewDecisionRejectModal(orderToReject, decision);
  }

  reviewChangeStatus(orderToApprove: any) {
    this.tableActions.reviewChangeStatusModal(orderToApprove);
  }

  deleteOrder(orderToDelete: any) {
    this.tableActions.deleteOrder(orderToDelete);
  }

  activateInitNotOrders() {
    this.tableActions.initNotOrdersPropsModal();
  }
  activateInitErrorOrders() {
    this.tableActions.initErrorOrdersPropsModal();
  }
  initNotFiltersPropsModal() {
    this.tableActions.initNotFiltersPropsModal();
  }

  receiveLoadingErrorProps(loadingErrorProps: any) {
    this.loadingErrorProps = loadingErrorProps;
  }
  handleMassivePtsModal(orderToListPts: any) {
    this.tableActions.openMassivePtsModal(orderToListPts);
  }
}
