import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { GraphqlAccountService } from 'src/app/services/graphql/graphql-account.service';
import { GraphqlMassivetxnsService } from 'src/app/services/graphql/graphql-massivetxns.service';
import { ErrorHandlingService } from 'src/app/services/http/error-handling.service';
import { FileToDownload } from 'src/app/shared/components/modals/download-file-modal/download-file-modal.component';
import { TableActionsBaseComponent } from 'src/app/shared/components/table-actions-base/table-actions-base.component';
import {
  DataAccountOrders,
  IMutationReviewDecision,
  ReviewDecision,
} from 'src/app/shared/models/data-table.schema';

@Component({
  selector: 'app-table-actions-adjustment',
  templateUrl:
    '../../../../shared/components/table-actions-base/table-actions-base.component.html',
  styleUrls: [
    '../../../../shared/components/table-actions-base/table-actions-base.component.css',
  ],
})
export class TableActionsAdjustmentComponent extends TableActionsBaseComponent {
  constructor(
    servicesAccountGql: GraphqlAccountService,
    http: HttpClient,
    messageService: MessageService,
    router: Router,
    errorHandlingService: ErrorHandlingService,
    public servicesMassivetxnsGql: GraphqlMassivetxnsService
  ) {
    super(
      servicesAccountGql,
      http,
      messageService,
      router,
      errorHandlingService
    );
  }

  override downloadOrderFileModalAccount(fileToDownload: FileToDownload) {
    const { contentType, fileType, format } = fileToDownload;

    this.servicesMassivetxnsGql
      .downloadMassiveTxnContent(
        this.selectedOrderToDownload.id,
        this.selectedOrderToDownload.orderType,
        this.selectedOrderToDownload.orderId,
        fileType
      )
      .subscribe({
        next: (res) => {
          if (!res.body) {
            const errorMessage = 'El archivo no existe o no se encontró';

            this.messageService.add({
              severity: 'error',
              detail: errorMessage,
            });
            return;
          }
          this.http.get(res.body.url, { responseType: 'blob' }).subscribe({
            next: (response: Blob) => {
              const blobMassive = new Blob([response], { type: contentType });
              const urlMassive = window.URL.createObjectURL(blobMassive);
              const linkMassive = document.createElement('a');
              linkMassive.href = urlMassive;
              linkMassive.download = `${this.selectedOrderToDownload.id}_${fileType}.${format}`;
              document.body.appendChild(linkMassive);
              linkMassive.click();
              document.body.removeChild(linkMassive);
              window.URL.revokeObjectURL(urlMassive);
            },
            error: () => {
              const errorMessage =
                this.errorHandlingService.handleErrorResponse(res);

              this.messageService.add({
                severity: 'error',
                detail: errorMessage,
              });
            },
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            detail: `El archivo no existe o no se encontró`,
          });
        },
      });
  }

  override reviewChangeStatusModal(orderToApprove): void {
    this.selectedOrderToId = orderToApprove;
    if (
      orderToApprove.status !== 'PENDING' &&
      orderToApprove.approvalStatus !== 'PENDING'
    ) {
      return;
    }
    this.displayExecuteOrderModal = true;
  }
  override reviewDecisionRejectModal(
    orderToReject: DataAccountOrders,
    decision: ReviewDecision
  ): void {
    this.selectedOrderToId = orderToReject;
    if (
      orderToReject.status !== 'NEW' &&
      orderToReject.approvalStatus !== 'PENDING'
    ) {
      return;
    }
    this.selectedOrderToReject = {
      id: orderToReject.id,
      reviewDecision: decision,
      reviewComments: this.reviewComments,
    };
    this.displayRejectOrderModal = true;
  }

  override confirmOrderAccount(): void {
    const input: IMutationReviewDecision = {
      id: this.selectedOrderToConfirm.id,
      reviewDecision: this.selectedOrderToConfirm.reviewDecision,
      reviewComments: '',
    };
    this.servicesMassivetxnsGql.approveMassiveTxnOrder(input).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Aprobaste la Orden N° ' + this.selectedOrderToId.orderId,
        });
        this.refreshOrders();
      },
      error: (error) => {
        const errorMessage =
          this.errorHandlingService.handleErrorResponse(error);

        this.hideOrderConfirmModal();
        const { reviewDecision } = this.selectedOrderToConfirm;
        this.router.navigate(
          [`people/incidentorders/order-${reviewDecision.toLowerCase()}/error`],
          {
            queryParams: {
              ...this.selectedOrderToConfirm,
              message: errorMessage,
              orderId: this.requestOrderNumberById(
                this.selectedOrderToConfirm.id
              ),
            },
          }
        );
      },
    });
    this.hideOrderConfirmModal();
  }

  override openMassivePtsModal(orderToListPts) {
    this.paramsOrder = orderToListPts;
    this.displayMassivePtsModal = true;
  }

  override rejectOrder() {
    this.rejectOrderAccount();
  }

  override rejectOrderAccount(): void {
    const data: IMutationReviewDecision = {
      id: this.selectedOrderToReject.id,
      reviewDecision: this.selectedOrderToReject.reviewDecision,
      reviewComments: this.selectedOrderToReject.reviewComments,
    };
    this.servicesMassivetxnsGql.approveMassiveTxnOrder(data).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Rechazaste la Orden N° ' + this.selectedOrderToId.orderId,
        });
        this.hideApprovedOrderExpireModal();
        this.displayDeleteOrderModal = false;
        this.refreshOrders();
      },
      error: (error) => {
        console.error(error);
        this.hideOrderRejectModal();
        this.hideApprovedOrderExpireModal();
        this.displayDeleteOrderModal = false;
        const { reviewDecision } = this.selectedOrderToReject;
        this.router.navigate(
          [`people/incidentorders/order-${reviewDecision.toLowerCase()}/error`],
          {
            queryParams: {
              ...this.selectedOrderToReject,
              orderId: this.requestOrderNumberById(
                this.selectedOrderToReject.id
              ),
            },
          }
        );
      },
    });
    this.hideOrderRejectModal();
  }

  override hideOrderConfirmModal() {
    this.displayConfirmOrderModal = false;
  }

  override hideOrderRejectModal() {
    this.displayRejectOrderModal = false;
  }

  override confirmDeleteOrder() {
    this.confirmDeleteOrderAccount();
  }

  override confirmDeleteOrderAccount() {
    const { id, status, approvalStatus } = this.selectedOrderToId;
    if (status !== 'NEW' && approvalStatus !== 'PENDING') return;
    this.servicesMassivetxnsGql.deleteMassiveTxnOrder(id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Eliminaste la Orden N° ' + this.selectedOrderToId.orderId,
        });
        this.refreshOrders();
      },
      error: (error) => {
        const errorMessage =
          this.errorHandlingService.handleErrorResponse(error);

        this.hideOrderDeletionModal();
        this.displayDeleteOrderModal = false;
        this.router.navigate([`people/incidentorders/order-deleted/error`], {
          queryParams: {
            error: errorMessage,
            id: this.selectedOrderToId.id,
            orderId: this.requestOrderNumberById(this.selectedOrderToId.id),
          },
        });
      },
    });
    this.hideOrderDeletionModal();
    this.displayDeleteOrderModal = false;
  }
}
