import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageSets } from 'src/app/shared/models/image-sets.schema';

@Component({
  selector: 'app-expire-order-modal',
  templateUrl: './expire-order-modal.component.html',
  styleUrls: ['./expire-order-modal.component.scss'],
})
export class ExpireOrderModalComponent {
  @Input() displayModal: boolean = false;
  @Output() onConfirm = new EventEmitter<void>();
  @Input() orderId: string = '';
  @Output() onHide = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() loading: boolean = false;
  errorImages = ImageSets.errorImages;
  cancelConfirm() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  showModal() {
    this.displayModal = true;
  }

  confirmExpire() {
    this.onConfirm.emit();
  }

  hideModal() {
    this.displayModal = false;
    this.onCancel.emit();
  }
}
