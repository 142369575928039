import { Component, EventEmitter, Input, Output } from '@angular/core';
import { filterPtsModalOld } from '../../models/order-types.schema';

@Component({
  selector: 'app-filters-pts-modal',
  templateUrl: './filters-pts-modal.component.html',
  styleUrls: ['./filters-pts-modal.component.css'],
})
export class FiltersPtsModalComponent {
  @Output() onClose = new EventEmitter();
  @Output() onChange = new EventEmitter<any>();
  allSelectedStatus = false;
  @Input() statusList = filterPtsModalOld;

  handleAllSelected() {
    this.allSelectedStatus = !this.allSelectedStatus;
    this.statusList = this.statusList.map((item) => ({
      ...item,
      selected: this.allSelectedStatus,
    }));
  }

  onPressFilter() {
    let allSelected = true;
    for (let status of this.statusList) {
      if (!status.selected) {
        allSelected = false;
        break;
      }
    }
    if (allSelected) {
      this.allSelectedStatus = true;
    } else {
      this.allSelectedStatus = false;
    }
  }
  applyFilters() {
    const actives = this.statusList.filter((item) => item.selected);
    this.onChange.emit(actives);
  }
  deleteFiltersReload() {
    this.allSelectedStatus = false;
    this.statusList = this.statusList.map((item) => ({
      ...item,
      selected: this.allSelectedStatus,
    }));
    const actives = this.statusList.filter((item) => item.selected);
    this.onChange.emit(actives);
  }
  handleClose() {
    this.onClose.emit();
  }
}
