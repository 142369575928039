import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderErrorBasePage } from '../order-error-base/order-error-base.page';

@Component({
  selector: 'app-expire-order-error',
  templateUrl: './expire-order-error.component.html',
  styleUrls: ['./expire-order-error.component.scss'],
})
export class ExpireOrderErrorPage extends OrderErrorBasePage implements OnInit {
  constructor(router: Router, activatedRoute: ActivatedRoute) {
    super(router, activatedRoute);
  }

  ngOnInit(): void {
    this.init('Ocurrió un problema al caducar tu orden');
  }

  protected getDefaultMessage(): string {
    return 'Ocurrió un problema al caducar tu orden';
  }
  get orderInformation() {
    return {
      id: this.id,
      orderId: this.orderId
    };
  }
}
