import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import { OrderProcessType } from 'src/app/services/incidentandorders/create-order.service';
import { OrderProcessType as TypeEnum } from '../models/data-table.schema';
import {
  custAccountMgmtType,
  judicialType,
  reconcileType,
  recoveryType,
} from '../models/order-types.schema';

@Injectable()
export class ValidateFilesHelpers {
  validateFileExtension(allowedExtensions: string[]) {
    return (control: FormControl) => {
      const file = control.value;
      if (file) {
        const extension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(`.${extension}`)) {
          return { invalidExtension: true };
        }
      }
      return null;
    };
  }

  validateFileSize(maxSizeInMB: number) {
    const maxSize = maxSizeInMB * 1024 * 1024;
    return (control: FormControl) => {
      const file = control.value;
      if (file && file.size > maxSize) {
        return { invalidSize: true };
      }
      return null;
    };
  }

  selectedOrderType(): string {
    const activeOrderProcessType = sessionStorage.getItem(
      'active_order_process_type'
    );
    const { tabindex } = JSON.parse(
      activeOrderProcessType!
    ) as OrderProcessType;
    return tabindex;
  }

  setOrderTypes() {
    switch (this.selectedOrderType()) {
      case TypeEnum.CLOSEACCOUNT:
        return custAccountMgmtType;
      case TypeEnum.JUDICIAL:
        return judicialType;
      case TypeEnum.RECONCILE:
        return reconcileType;
      case TypeEnum.RECOVERY:
        return recoveryType;
      default:
        return custAccountMgmtType;
    }
  }
}
