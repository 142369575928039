import { Injectable } from '@angular/core';
import { jwtsecurityService } from '@deuna/bo-sh-security-ng';
import { BehaviorSubject, Subject } from 'rxjs';
import { OrderProcessType } from 'src/app/shared/models/data-table.schema';
import { backOfficeRoles } from 'src/constants';

@Injectable()
export class DataSharingService {
  private orderIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private searchTextSubject = new BehaviorSubject<string>('');
  searchText$ = this.searchTextSubject.asObservable();

  securityService = jwtsecurityService;


  private filtersData = new BehaviorSubject<{
    paramsQuery:  {
      filterCriteriaText?: string;
      orderProcessType: OrderProcessType;
      skip: number;
      limit: number;
    };
    listFilters: {
      name: string;
      selected: boolean;
    }[];
    viewFilters: boolean;
    loadListBranchs: boolean;
  }>({
    paramsQuery: {
      filterCriteriaText: '',
      orderProcessType: undefined as any,
      skip: 0,
      limit: 10,
    },
    listFilters: [],
    viewFilters: false,
    loadListBranchs: false,
  });
  filtersData$ = this.filtersData.asObservable();
  private clearFilterEventSubject = new Subject<any>();
  private deleteFilterEventSubject = new Subject<void>();

  public showSidebarSubject = new BehaviorSubject<boolean>(false);
  showSidebar$ = this.showSidebarSubject.asObservable();


  isExecutor = this.canDisplay([backOfficeRoles.EXECUTOR]);
  isApprover = this.canDisplay([backOfficeRoles.APPROVER]);

  setOrderId(orderId: string): void {
    this.orderIdSubject.next(orderId);
  }

  getOrderId(): BehaviorSubject<string> {
    return this.orderIdSubject;
  }

  toggleSidebar() {
    this.showSidebarSubject.next(!this.showSidebarSubject.value);
  }

  updateSearchText(searchText: string) {
    this.searchTextSubject.next(searchText);
  }


  updateFiltersData(data: any) {
    this.filtersData.next(data);
  }

  clearFilterEvent$ = this.clearFilterEventSubject.asObservable();
  deleteFilterEvent$ = this.deleteFilterEventSubject.asObservable();


  emitClearFilterEvent(eventData: any) {
    this.clearFilterEventSubject.next(eventData);
  }

  canDisplay(roles?: string[]) {
    return this.securityService.validateIn(roles);

  }


  emitDeleteFilterEvent() {

    this.deleteFilterEventSubject.next();
  }

}


