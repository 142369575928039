import { assetUrl } from '@deuna/bo-sh-helpers-ng';

export const ImageSets = {
  icons: {
    searchFilter: assetUrl('png/Filter_Vector.png'),
    pointBrown: assetUrl('svg/dot-brown.svg'),
    pointGreen: assetUrl('svg/dot-green.png'),
    pointRed: assetUrl('svg/dot-red.svg'),
    pointBlue: assetUrl('svg/dot-blue.svg'),
    pointYellow: assetUrl('svg/dot-yellow.svg'),
    download: assetUrl('svg/download.svg'),
    deleteOrderImg: assetUrl('svg/trashRed.svg'),
    arrow: assetUrl('svg/arrow.svg'),
    reject: assetUrl('svg/reject.svg'),
    okay: assetUrl('svg/okay.svg'),
    avatar: assetUrl('png/avatar_merchant.png'),
    valueAction: assetUrl('png/Filter_Vector.png'),
    imgNotFoundMerchants: assetUrl('png/checklist.png'),
    trash: assetUrl('svg/trash.svg'),
    trashRed: assetUrl('svg/trashRed.svg'),
    see: assetUrl('svg/see.svg'),
    warning: assetUrl('svg/warning.svg'),
    warningAlt: assetUrl('svg/warningalt.svg'),
    danger: assetUrl('svg/danger.svg'),
  },
  errorImages: {
    imageEmptyList:assetUrl('png/image-report.png'),
    imageLoadError: assetUrl('png/image-report-error.png'),
    imageReload: assetUrl('svg/return.svg'),
    imageClearFilters: assetUrl('svg/trash-white.svg'),
  },
};
