import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from '@deuna/bo-sh-helpers-ng';

@Injectable()
export class BackOfficeTokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private tokenService: SessionService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = this.addToken(request);
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type === 4) {
          const response = event.body;
          const error = response.errors ? response.errors[0].message : '';
          if (error.includes('401')) {
            this.router.navigate(['/login/out']);
          }
        }
        return event;
      }),
      catchError(
        (
          httpErrorResponse: HttpErrorResponse,
          _: Observable<HttpEvent<any>>
        ) => {
          if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
            this.router.navigate(['/login/out']);
          }
          return next.handle(request);
        }
      )
    );
  }

  private addToken(request: HttpRequest<unknown>) {
    const token = this.tokenService.getToken();
    if (token && !request.url.includes('aws')) {
      return request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }
    return request;
  }
}
