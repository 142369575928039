import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { allowedFileFormats } from 'src/app/shared/models/order-types.schema';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileInput', {
    read: ElementRef,
  })
  fileInputRef!: ElementRef;
  @Input() allowedExtensions: string[] = [];
  @Input() titleButton: string = '';
  @Input() fileDescriptionText: string = '';
  @Input() showDownloadTemplate: boolean = false;
  @Input() maxSizeMB: number = 10;
  @Input() required: boolean = false;
  @Input() isServerSide: boolean = false;
  @Output() onFileUploaded: EventEmitter<File> = new EventEmitter<File>();
  fileName: string = '';
  accept: string = '';
  status: string = 'new';
  title: string = '';
  message: string = '';
  stepLoad: number = 0;
  fileUploaded: boolean = false;
  staticUrlDownload = environment.staticUrlDownload;

  ngOnInit(): void {
    const extensions = this.allowedExtensions.map((ext) => ext.toLowerCase());
    this.accept = extensions
      .map((ext) => allowedFileFormats[ext.toUpperCase()])
      .map(({ mime }) => mime)
      .join(', ');
  }

  resetFile(event: MouseEvent) {
    if (this.status === 'success') {
      event.preventDefault();
      this.onFileUploaded.emit(undefined);
      this.changeStatus('new', 'Cargar nuevamente', '', '');
    }
  }

  readFile() {
    this.title = '';
    this.message = '';
    if (this.fileInputRef.nativeElement.files === 0) {
      return;
    }
    const file: File | undefined = this.fileInputRef.nativeElement.files[0];

    if (!file) {
      return;
    }

    this.fileName = file.name || '';
    const { size } = file;
    const extension: string = this.fileName
      .slice(((this.fileName.lastIndexOf('.') - 1) >>> 0) + 2)
      .toLowerCase()
      .trim();
    this.fileInputRef.nativeElement.value = '';

    if (!this.allowedExtensions.includes(extension)) {
      this.changeStatus(
        'error',
        'Cargar nuevamente',
        `El archivo cargado no está dentro de las extensiones válidas.`,
        `Verifica que tu archivo tenga formato ${this.allowedExtensions.join(
          ', '
        )}`
      );
      this.onFileUploaded.emit(file);
      return;
    }
    if (size > Math.pow(this.maxSizeMB, 7)) {
      this.changeStatus(
        'error',
        'Cargar nuevamente',
        `El archivo excede el tamaño permitido de ${this.maxSizeMB}M`,
        `Verifica que tu archivo tenga el tamaño adecuado`
      );
      this.onFileUploaded.emit(file);
      return;
    }
    this.setFinishStatus(this.isServerSide, false, file, this.fileName);
  }
  setFinishStatus(
    server: boolean,
    show: boolean,
    file: File | undefined,
    name?: string
  ) {
    const tempFile = (file && file.name) || name;
    if (server) {
      this.status = 'upload';
      this.stepLoad = 10;
      if (!show) {
        this.onFileUploaded.emit(file);
        return;
      }
      setTimeout(() => {
        this.stepLoad = 100;
        setTimeout(
          () =>
            this.changeStatus(
              'success',
              'Eliminar',
              `${tempFile} se cargó correctamente`
            ),
          1000
        );
      }, 1000);
    } else {
      this.status = 'upload';
      this.stepLoad = 0;
      setTimeout(() => {
        this.stepLoad = 100;
        this.onFileUploaded.emit(file);
        setTimeout(
          () =>
            this.changeStatus(
              'success',
              'Eliminar',
              `${tempFile} se cargó correctamente`
            ),
          1000
        );
      }, 1000);
    }
  }

  changeStatus(
    status: string,
    titleButton: string,
    title: string,
    message: string = ''
  ) {
    this.status = status;
    this.titleButton = titleButton;
    this.title = title;
    this.message = message;
  }
}
