<form [formGroup]="formStep2" (ngSubmit)="onSubmit()" class="grid my-5">
  <div class="col-8 col-offset-2 mb-5">
    <span class="text-style-back" routerLink="/people/incidentorders/list"
      >Regresar</span
    >
  </div>

  <div class="col-8 col-offset-2">
    <app-card header="Tipo de orden">
      <ng-container *ngIf="getFormType !== recoveryType">
        <div class="grid col-12">
          <div class="col-6">
            <div class="grid">
              <div class="col-12">
                <deuna-select
                  [form]="formStep2"
                  [options]="orderTypes"
                  (onChangeSelect)="changeOrderTypeValue()"
                  optionLabel="label"
                  label="Proceso"
                  name="orderType"
                  control="orderType"
                  [placeholder]="initialOrderType!"
                ></deuna-select>
                <span
                  *ngIf="isFieldInvalid('orderType')"
                  class="form-text text-danger"
                >
                  Debe ingresar un tipo de orden
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="grid">
              <div class="col-12">
                <span class="title-period">Channel ID</span>
              </div>
              <div class="col-12" style="margin-top: 5px">
                <strong>{{ step1Result?.name }}</strong>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="getFormType === recoveryType">
        <div class="grid col-12">
          <div class="col-6">
            <div class="grid">
              <div class="col-12">
                <deuna-select
                  [form]="formStep2"
                  [options]="orderTypes"
                  optionLabel="label"
                  label="Proceso"
                  name="orderTypeLabel"
                  control="orderTypeLabel"
                  placeholder="Recuperación"
                ></deuna-select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="grid">
              <div class="col-12">
                <deuna-select
                  [form]="formStep2"
                  [options]="orderTypes"
                  (onChangeSelect)="changeOrderTypeValue()"
                  optionLabel="label"
                  label="Channel ID"
                  name="orderType"
                  control="orderType"
                  [placeholder]="initialOrderType!"
                ></deuna-select>
                <span
                  *ngIf="isFieldInvalid('orderType')"
                  class="form-text text-danger"
                >
                  Debe ingresar un tipo de orden
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="getFormType === recoveryType">
        <div class="grid col-12">
          <!-- RECOVERY -->
          <div class="col-6">
            <deuna-select
              [form]="formStep2"
              [options]="recoveryPaymentType"
              optionLabel="label"
              label="Tipo de proceso"
              name="paymentType"
              control="paymentType"
              placeholder="Seleccionar proceso"
            ></deuna-select>
            <span
              *ngIf="isFieldInvalid('paymentType')"
              class="form-text text-danger"
            >
              Debe ingresar un tipo de proceso
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="getFormType === recoveryType">
        <div class="grid col-12">
          <div class="col-6 period-box">
            <span class="title-period">Período de recuperación</span>
          </div>
        </div>
        <div class="col-6 flex flex-direction-row">
          <div class="col-6 box-input-calendar">
            <p-calendar
              #calendar
              [numberOfMonths]="2"
              [appendTo]="'body'"
              [dateFormat]="'dd/mm/yy'"
              [inputStyle]="{
              width: '206px',
              display: 'block',
            }"
              [placeholder]="currentDate"
              [disabled]="true"
            >
            </p-calendar>
          </div>
          <div class="col-6 calendar-right">
            <p-calendar
              [numberOfMonths]="1"
              [appendTo]="'body'"
              formControlName="selectedDates"
              (onSelect)="onSelect($event)"
              [dateFormat]="'dd/mm/yy'"
              [minDate]="minDate"
              [ngClass]="{
                'ng-invalid ng-dirty': isFieldInvalid('selectedDates')
              }"
              [inputStyle]="{
                width: '100%',
                display: 'block',
                'margin-right': '0',
              }"
              placeholder="Hasta"
            >
            </p-calendar>
            <div
              *ngIf="isFieldInvalid('selectedDates')"
              class="create-order__bad-input error-calendar"
            >
              Dato requerido
            </div>
          </div>
        </div>
      </ng-container>
    </app-card>
  </div>

  <div class="col-8 mt-5 col-offset-2">
    <app-card
      [header]="getHeaderTitle()"
      subHeader="Sube el archivo de excel con inconsistencias que se deban procesar"
    >
      <app-file-upload
        #fileUpload
        (onFileUploaded)="receiveInconsistencesFile($event)"
        [maxSizeMB]="10"
        [isServerSide]="true"
        [allowedExtensions]="['csv']"
        fileDescriptionText="Archivo de inconsistencias"
        titleButton="Cargar Archivo"
        [showDownloadTemplate]="true"
        [required]="isFieldInvalid('inFile')"
      ></app-file-upload>
      <div class="grid col-12" *ngIf="false">
        <div class="mb-2 col-6">
          <div class="grid">
            <div class="col-12">
              <span>Número de incidencias</span>
            </div>
            <div class="col-12">
              <deuna-input
                [masked]="false"
                [disableErrorMessage]="true"
                [form]="formStep2"
                [maxLength]="10"
                type="number"
                placeholder=""
                name="totalRecord"
                control="totalRecord"
              >
              </deuna-input>
              <span
                *ngIf="isFieldInvalid('totalRecord')"
                class="create-order__bad-input"
              >
                Dato requerido
              </span>
            </div>
          </div>
        </div>

        <div class="mb-2 col-6">
          <div class="grid">
            <div class="col-12">
              <span>Monto total</span>
            </div>
            <div class="col-12">
              <deuna-input
                [masked]="false"
                [disableErrorMessage]="true"
                [form]="formStep2"
                [maxLength]="10"
                type="number"
                placeholder=""
                name="totalAmount"
                control="totalAmount"
              >
              </deuna-input>
              <span
                *ngIf="isFieldInvalid('totalAmount')"
                class="create-order__bad-input"
              >
                Dato requerido
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pb-6" *ngIf="analysisData">
        <div class="col-12 pb-2">
          <span class="resum-text">Resumen consolidado</span>
        </div>
        <deuna-table [arrayData]="[1]" [columnas]="columns">
          <tr>
            <td class="textCount">
              {{ analysisData ? analysisData.totalRecord : 0 }}
            </td>
            <td class="textAmount">
              {{
                analysisData
                  ? (analysisData.totalAmount
                    | currency : "USD" : "symbol" : "1.2-2")
                  : 0
              }}
            </td>
          </tr>
        </deuna-table>
      </div>
    </app-card>
  </div>

  <div class="col-8 mt-5 col-offset-2">
    <app-card
      header="Carga de respaldos"
      subHeader="Sube un zip con toda la evidencia de respaldo relacionada a las inconsistencias"
    >
      <app-file-upload
        (onFileUploaded)="receiveEvidencesFile($event)"
        [maxSizeMB]="10"
        [allowedExtensions]="['zip']"
        fileDescriptionText="Archivo de evidencias (opcional)"
        titleButton="Cargar ZIP"
      ></app-file-upload>
    </app-card>
  </div>

  <div class="col-6 col-offset-4 pt-4">
    <div class="grid">
      <div class="col-6">
        <deuna-button
          deUnaClass="p-button-outlined"
          label="Cancelar"
          [outline]="true"
          (eventClick)="openCancelModal()"
        >
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button
          (eventClick)="onSubmit()"
          label="Crear orden"
        ></deuna-button>
      </div>
    </div>
  </div>
</form>
<p-toast position="bottom-right"></p-toast>

<div class="w-full h-full z-5" *ngIf="loading">
  <deuna-loading
    titulo="Creando orden"
    subtitulo="Por favor, espera un momento"
  >
  </deuna-loading>
</div>

<app-confirm-modal
  *ngIf="!formStep2.invalid"
  [displayModal]="displayConfirmModal"
  [loading]="loading"
  (onConfirm)="confirmOrderCreation()"
  (onCancel)="cancelOrderCreation()"
  (onHide)="hideConfirmModal()"
></app-confirm-modal>

<app-cancel-modal
  [displayModal]="displayCancelModal"
  [loading]="loading"
  (onConfirm)="confirmOrderCancellation()"
  (onCancel)="hideCancelModal()"
  (onHide)="hideCancelModal()"
></app-cancel-modal>
<app-format-error-modal
  #formatError
  [displayModal]="displayFormatErrorModal"
  (onRetry)="confirmRetryUpload()"
></app-format-error-modal>
