import { Component, Input } from '@angular/core';
import { ListAdjustmentComponent } from 'src/app/modules/adjustment/components/list-adjustment/list-adjustment.component';

import {
  IQueryDataOrders,
  OrderProcessType,
  ReviewDecision,
} from 'src/app/shared/models/data-table.schema';
import {
  recoveryColumnsApprover,
  recoveryColumnsExecutor,
} from 'src/app/shared/models/order-types.schema';

@Component({
  selector: 'app-list-recovery',
  templateUrl: `../../../../shared/components/list-base/list-base.component.html`,
  styleUrls: [
    '../../../../shared/components/list-base/list-base.component.scss',
  ],
})
export class ListRecoveryComponent extends ListAdjustmentComponent {
  @Input() override paramsQuery: IQueryDataOrders = {
    orderProcessType: OrderProcessType.RECOVERY,
    skip: 0,
    limit: 10,
  };
  override actionsType = OrderProcessType.RECOVERY;
  override columnsApprove = recoveryColumnsApprover;
  override columnsExecutor = recoveryColumnsExecutor;

  override createConfigGetInitial() {
    this.paramsQuery = {
      orderProcessType: OrderProcessType.RECOVERY,
      skip: this.currentPage,
      limit: this.limitPage,
    };
    this.paramsDecision = {
      id: '',
      reviewDecision: ReviewDecision.APPROVED,
      reviewComments: '',
    };
    this.resultsDecision = `
        id: '',
        orderId: ''
        `;

    this.dataPagination = {
      skip: this.currentPage,
      limit: this.limitPage,
    };
  }
}
