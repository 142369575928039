<p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '55vw'}" (onHide)="hideModal()" [draggable]="false"
    [resizable]="false">
    <div class="grid text-center">
        <div class="col-8 col-offset-2 pt-4">
            <div class="headerModal">
                ¿Estás seguro de cancelar la orden?
            </div>
            <div class="textModal mt-3">
                Al seleccionar “Si, cancelar”, la orden se borrará y no podrás recuperarla.
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="grid col-12">
            <div class="col-6">
                <deuna-button deUnaClass="p-button-outlined"  label="No, continuar" [disabled]="loading" [outline]="true"
                    (eventClick)="cancel()">
                </deuna-button>
            </div>
            <div class="col-6">
                <deuna-button label="Sí, cancelar" [disabled]="loading" (eventClick)="confirmCancellation()"></deuna-button>
            </div>
        </div>
    </ng-template>
</p-dialog>