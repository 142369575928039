<p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '30vw'}" (onHide)="hideModal()" [draggable]="false"
  [resizable]="false">
  <div class="grid text-center">
    <div class="col-8 col-offset-2 pt-4">
      <div class="headerModal">
        Descarga de archivos
      </div>
      <div class="textModal mt-3">
        Selecciona el archivo que deseas descargar.
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid col-12" *ngIf="selectedOrderType === 'JUDICIAL' || selectedOrderType === 'RECONCILE' || selectedOrderType === 'AMOUNT_RECOVERY' ">
      <div class="col-12 ml-2">
        <deuna-button label="Excel de orígen de la orden"
          (eventClick)="downloadOrderFile('IN', 'xls', 'application/vnd.ms-excel')">
        </deuna-button>
      </div>
      <div class="col-12 ml-2">
        <deuna-button label="Respaldo o evidencias"
          (eventClick)="downloadOrderFile('EVIDENCE', 'zip', 'application/zip')"></deuna-button>
      </div>
      <div *ngIf="['COMPLETED', 'SUCCESS', 'COMPLETED_WITH_ERRORS', 'ERROR_PROCESSING'].includes(status)" class="col-12 ml-2">
        <deuna-button label="Excel de respuesta de PTS"
          (eventClick)="downloadOrderFile('OUT','xls', 'application/vnd.ms-excel')"></deuna-button>
      </div>
    </div>
    <div class="grid col-12" *ngIf="selectedOrderType === 'CLOSE-ACCOUNT' ">
      <div class="col-12 ml-2">
        <deuna-button label="Respaldo o evidencias"
          (eventClick)="downloadOrderFile('EVIDENCE', 'zip', 'application/zip')"></deuna-button>
      </div>
    </div>

  </ng-template>
</p-dialog>
