<ng-container [ngSwitch]="status">
  <deuna-chip
    *ngSwitchCase="'NEW'"
    [valueAction]="icons.pointBrown"
    backgroundColor=" #FDF4E6 "
    color="#A96A00"
    text="Por aprobar "
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'APPROVED'"
    [valueAction]="icons.pointGreen"
    backgroundColor="#ECF9EF"
    color="#2D8846"
    text="Aprobada"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'REJECTED'"
    [valueAction]="icons.pointRed"
    backgroundColor="#FFBABA"
    color="#D8000C"
    text="Rechazada"
    [pTooltip]="reviewedComments"
    tooltipPosition="top"
  >
  </deuna-chip>
  <deuna-chip
    *ngSwitchCase="'PROCESSING'"
    [valueAction]="icons.pointBlue"
    backgroundColor="#E6F0FC"
    color="#004DA3"
    text="Procesando"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'COMPLETED'"
    [valueAction]="icons.pointGreen"
    backgroundColor="#ECF9EF"
    color="#2D8846"
    text="Procesada"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'SUCCESS'"
    [valueAction]="icons.pointGreen"
    backgroundColor="#ECF9EF"
    color="#2D8846"
    text="Procesada"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'PENDING'"
    [valueAction]="old ? icons.pointBlue : icons.pointYellow"
    [backgroundColor]="old ? '#E6F0FC' : '#FDF4E6'"
    [color]="old ? '#004DA3' : '#D98800'"
    [text]="old ? 'Pendiente' : 'Por aprobar'"
  ></deuna-chip>
  <deuna-chip
  *ngSwitchCase="'PENDING_EXPIRATION'"
  [valueAction]="icons.pointBlue"
  backgroundColor="#E6F0FC"
  color="#004DA3"
  text="Pendiente de caducar"
></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'EXPIRED'"
    [valueAction]="icons.pointGray"
    backgroundColor]="#F1F2F5"
    color="#4F596F"
    text="Caducada"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'EXPIRED_ON_DEMAND'"
    [valueAction]="icons.pointGray"
    backgroundColor="#F1F2F5"
    color="#4F596F"
    text="Caducada"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'EXPIRED_WITH_ERRORS'"
    [valueAction]="icons.pointGray"
    backgroundColor="#F1F2F5"
    color="#4F596F"
    text="Caducada con errores"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'CANCELLED'"
    [valueAction]="icons.pointRed"
    backgroundColor="#FFBABA"
    color="#D8000C"
    text="Cancelada"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'ERROR'"
    [valueAction]="icons.pointRed"
    backgroundColor="#FFBABA"
    color="#D8000C"
    [pTooltip]="reviewedComments"
    text="Fallida"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'ERROR_PROCESSING'"
    [valueAction]="icons.pointRed"
    backgroundColor="#FFBABA"
    color="#D8000C"
    [pTooltip]="
      'Ocurrió un error por uno de estos motivos: ' +
      ' 1. El formato del archivo cargado es inválido' +
      ' 2. Hay un problema con PTS'
    "
    text="Procesamiento fallido"
  ></deuna-chip>
  <deuna-chip
    *ngSwitchCase="'COMPLETED_WITH_ERRORS'"
    [valueAction]="icons.pointYellow"
    backgroundColor="#FDF4E6"
    color="#D98800"
    text="Procesada con errores"
  ></deuna-chip>
  <p *ngSwitchCase="'EMPTY'"></p>
  <p *ngSwitchCase="''"></p>
  <deuna-chip
    *ngSwitchDefault
    backgroundColor="#FDF4E6"
    color="#835200"
    [text]="custAccountNostatus"
  ></deuna-chip>
</ng-container>
