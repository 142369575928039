
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'

@Injectable()
export class S3Service {

  uploadFile(file: File, presignedUrl: string): Observable<void> {
    const myHeaders = new Headers();
    myHeaders.append('x-amz-server-side-encryption', 'aws:kms');
    myHeaders.append(
      'x-amz-server-side-encryption-aws-kms-key-id',
      environment.kmsKey
    );
    myHeaders.append('Content-Type', 'application/zip');

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: myHeaders,
      body: file,
      redirect: 'follow',
    };

    return new Observable(observer  => {
      fetch(
        presignedUrl,
        requestOptions
      )
      .then(() => {
        observer.next();
        observer.complete();
      })
      .catch(err => observer.error(err));

    })
  }
}
