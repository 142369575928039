<form [formGroup]="formStep1" (ngSubmit)="onSubmit()" class="grid my-5">
  <div class="col-8 col-offset-2 mb-5">
    <span class="text-style-back" routerLink="/people/incidentorders/list"
      >Regresar</span
    >
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Tipo de orden">
      <div class="col-6">
        <deuna-select
          [form]="formStep1"
          [options]="orderTypes"
          optionLabel="label"
          label="Proceso"
          name="orderType"
          control="orderType"
          placeholder="Selecciona un tipo de orden"
        ></deuna-select>
        <span *ngIf="isFieldValid('orderType')" class="create-order__bad-input ">
          Debe ingresar un tipo de orden
        </span>
      </div>
    </app-card>
  </div>

  <div class="col-6 col-offset-4 pt-4">
    <div class="grid">
      <div class="col-6">
        <deuna-button
          deUnaClass="p-button-outlined"
          label="Cancelar"
          (eventClick)="openCancelModal()"
        >
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button
          label="Crear orden"
          (eventClick)="onSubmit()"
        ></deuna-button>
      </div>
    </div>
  </div>
</form>

<app-cancel-modal
  [displayModal]="displayCancelModal"
  (onConfirm)="confirmOrderCancellation()"
  (onCancel)="hideCancelModal()"
  (onHide)="hideCancelModal()"
></app-cancel-modal>
