import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderErrorBasePage } from '../order-error-base/order-error-base.page';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order-error.component.html',
  styleUrls: ['./create-order-error.component.scss'],
})
export class CreateOrderErrorPage extends OrderErrorBasePage {
  constructor(router: Router, activatedRoute: ActivatedRoute) {
    super(router, activatedRoute);
  }

  ngOnInit(): void {
    this.init('Ocurrió un problema al crear tu orden');
  }

  protected getDefaultMessage(): string {
    return 'Ocurrió un problema al crear tu orden';
  }

  retryProcess() {
    this.router.navigate(['people/incidentorders/create-order/step-1']);
  }
}
