import { Component, EventEmitter, Input, Output } from '@angular/core';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import {
  ModalParamsLoadingError,
  ModalType,
} from 'src/app/shared/models/constants.backoffice.schema';

@Component({
  selector: 'app-modal-selector',
  templateUrl: './modal-selector.component.html',
})
export class ModalSelectorComponent {
  @Input() modalType: ModalType = ModalType.NotModal;
  @Output() deleteFilters = new EventEmitter<string>();
  @Output() reloadComponent = new EventEmitter<string>();
  @Input() modalErrorProps: ModalParamsLoadingError = {
    image: '',
    subHeaderText: '',
    headerText: undefined,
    valueAction: '',
    text: '',
    orderId: '',
  };

  imageLoadError: string = assetUrl('png/image-report-error-r.png');
  imageClearFilters: string = assetUrl('svg/trash-white.svg');
  modalTypeEnum = ModalType;

  clickEvent(event: string) {
    if (this.modalTypeEnum.NotFilters === this.modalType)
      this.deleteFilters.emit(event);
    if (this.modalTypeEnum.NotOrdersSearch === this.modalType)
      this.deleteFilters.emit(event);
    if (this.modalTypeEnum.NotOrders === this.modalType)
      this.reloadComponent.emit(event);
  }
}
