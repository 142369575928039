export enum ModalType {
  NotOrders,
  NotFilters,
  NotOrdersSearch,
  NotCreateOrders,
  NotModal,
}

export interface ModalParamsLoadingError {
  image: string;
  subHeaderText: string;
  headerText: string | undefined;
  valueAction: string;
  text: string;
  orderId: string | undefined;
}
