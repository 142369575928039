import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() displayModal: boolean = false;
  @Output() onHide = new EventEmitter();
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() loading: boolean = false;

  hideModal() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  showModal() {
    this.displayModal = true;
  }

  cancelConfirm() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  confirmCreation() {
    this.onConfirm.emit();
  }

}
