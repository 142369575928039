import { AccountOrderTypeEnum } from 'src/app/constants/catalogos/account.order-type';
import { MenuItem } from 'primeng/api/menuitem';
import { environment } from 'src/environments/environment';

export interface OrderType {
  label: string;
  name: string;
}

export const reconcileType: OrderType[] = [
  { label: 'Faltante', name: 'INTTRANSFERFALTANTES' },
  { label: 'Reverso de faltante', name: 'INTTRANSFERFALTANTESRV' },
  { label: 'Sobrante', name: 'INTTRANSFERSOBRANTES' },
  { label: 'Reverso de sobrante', name: 'INTTRANSFERSOBRANTESRV' },
  { label: 'Reclasificacion', name: 'INTAJUSTERECLASI' },
  { label: 'Reverso de reclasificación', name: 'INTAJUSTERECLASIRV' },
  { label: 'Reclasificación de sobrante', name: 'INTRECLASIFSOBRANTE' },
  {
    label: 'Reverso de reclasificación de sobrante',
    name: 'INTRECLASIFSOBRANTERV',
  },
  { label: 'Reclasificación de faltante', name: 'INTRECLASIFFALTANTE' },
  {
    label: 'Reverso de reclasificación de faltante',
    name: 'INTRECLASIFFALTANTERV',
  },
  { label: 'Sobrante comercio', name: 'INTAJUSTECOMERCIO' },
  { label: 'Reverso de sobrante comercio', name: 'INTAJUSTECOMERCIORV' },
  { label: 'Faltante comercio', name: 'INTPAGOMANUALCOMERCIO' },
  { label: 'Reverso de faltante comercio', name: 'INTPAGOMANUALCOMERCIORV' },
  { label: 'Reclasificación comercio', name: 'INTREGFALTANTECOMERCIO' },
  {
    label: 'Reverso de Reclasificación comercio',
    name: 'INTREGFALTANTECOMERCIORV',
  },
  { label: 'Cargo al gasto', name: 'INTCARGOGASTO' },
  { label: 'Reverso de cargo al gasto', name: 'INTCARGOGASTORV' },
  {
    label: 'Pago de incentivos a cuentas de ahorro DEUNA',
    name: 'INTPAGOINCENTIVO',
  },
  {
    label: 'Reverso de pago de incentivos a cuentas de ahorro DEUNA',
    name: 'INTPAGOINCENTIVORV',
  },
];
export const judicialTypeOld: OrderType[] = [
  { label: 'Retención', name: 'INTRETENCIONJUDICIAL' },
  { label: 'Reverso de Retención', name: 'INTRETENCIONJUDICIALRV' },
  { label: 'Liberación', name: 'INTDEVOLUCIONJUDICIAL' },
  { label: 'Reverso de Liberación', name: 'INTDEVOLUCIONJUDICIALRV' },
];
export const judicialTypeNew: OrderType[] = [
  { label: 'Retención Activas', name: 'INTRETENCIONJUDICIAL' },
  { label: 'Reverso de Retención Activas', name: 'INTRETENCIONJUDICIALRV' },
  { label: 'Retención Inactivas', name: 'INTRETENCIONINACTIVAS' },
  { label: 'Reverso de Retención Inactivas', name: 'INTRETENCIONINACTIVASRV' },
  { label: 'Liberación', name: 'INTDEVOLUCIONJUDICIAL' },
  { label: 'Reverso de Liberación', name: 'INTDEVOLUCIONJUDICIALRV' },
];
export const judicialType: OrderType[] = environment.enabledRetention
  ? judicialTypeNew
  : judicialTypeOld;

export const recoveryType: OrderType[] = [
  { label: 'INTTRANSFERSOBRANTES', name: 'INTTRANSFERSOBRANTES' },
  { label: 'EXTREGBANREDTRANSFEROUT', name: 'EXTREGBANREDTRANSFEROUT' },
  { label: 'INTREGINTERBANCTRANSFER', name: 'INTREGINTERBANCTRANSFER' },
  { label: 'INTREGINTERNALTRANSFER', name: 'INTREGINTERNALTRANSFER' },
  { label: 'INTREGRECAUDOCOMERCIO', name: 'INTREGRECAUDOCOMERCIO' },
  { label: 'INTREGRECAUDOMETROUIO', name: 'INTREGRECAUDOMETROUIO' },
  { label: 'INTREGPAGOINCENTIVO', name: 'INTREGPAGOINCENTIVO' },
];
export const ptsModalItemsStatus = [
  'INTRETENCIONJUDICIAL',
  ...reconcileType.map((r) => r.name),
];

export const recoveryPaymentType: OrderType[] = [
  { label: 'Parcial', name: 'PARTIAL' },
  { label: 'Total', name: 'TOTAL' },
];

export const custAccountMgmtType: OrderType[] = [
  { label: 'Cancelación', name: 'Cancelación' },
  { label: 'Reversar cancelación', name: 'Reverso' },
];

export const closeAccountTypes: {
  process: AccountOrderTypeEnum;
  actor: string;
  type: string;
}[] = [
  {
    process: AccountOrderTypeEnum.TITULAR,
    actor: 'Titular',
    type: 'Cancelación',
  },
  {
    process: AccountOrderTypeEnum.UNIDAD_CUMPLIMIENTO,
    actor: 'Entidad financiera',
    type: 'Cancelación',
  },
  {
    process: AccountOrderTypeEnum.AUTORIDAD_COMPETENTE,
    actor: 'Autoridad competente',
    type: 'Cancelación',
  },
  {
    process: AccountOrderTypeEnum.TITULAR_RV,
    actor: 'Titular',
    type: 'Reverso',
  },
  {
    process: AccountOrderTypeEnum.UNIDAD_CUMPLIMIENTO_RV,
    actor: 'Entidad financiera',
    type: 'Reverso',
  },
  {
    process: AccountOrderTypeEnum.AUTORIDAD_COMPETENTE_RV,
    actor: 'Autoridad competente',
    type: 'Reverso',
  },
];

export const closeAccountTable = [
  {
    process: 'INTCIERRECUENTASINSALDO',
    actor: 'Titular',
    type: 'Cancelación',
  },
  {
    process: 'INTCIERRECUENTASALDO',
    actor: 'Autoridad competente',
    type: 'Cancelación',
  },
  {
    process: 'INTCIERRECUENTAUNICUM',
    actor: 'Entidad financiera',
    type: 'Cancelación',
  },
  {
    process: 'INTCIERRECUENTASINSALDORV',
    actor: 'Titular',
    type: 'Reverso',
  },
  {
    process: 'INTCIERRECUENTASALDORV',
    actor: 'Autoridad competente',
    type: 'Reverso',
  },
  {
    process: 'INTCIERRECUENTAUNICUMRV',
    actor: 'Entidad financiera',
    type: 'Reverso',
  },
];

export const allRequesterTypes: OrderType[] = [
  { label: 'Titular', name: 'Titular' },
  { label: 'Entidad financiera', name: 'Entidad financiera' },
  { label: 'Autoridad competente', name: 'Autoridad competente' },
];

export interface FileFormat {
  extension: string;
  mime: string;
}

export const allowedFileFormats: Record<string, FileFormat> = {
  XLSX: {
    extension: 'xlsx',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  XLS: { extension: 'xls', mime: 'application/vnd.ms-excel' },
  CSV: { extension: 'csv', mime: 'text/csv' },
  ZIP: { extension: 'zip', mime: 'application/zip' },
  GZIP: { extension: 'gzip', mime: 'application/gzip' },
  RAR: { extension: 'rar', mime: 'application/x-rar-compressed' },
};

export const tabListItems: Array<MenuItem> = [
  {
    label: 'Cancelaciones',
    id: 'Cancelaciones',
    tabindex: 'CLOSE-ACCOUNT',
  },
  {
    label: 'Ajustes',
    id: 'Ajustes',
    tabindex: 'RECONCILE',
  },
  {
    label: 'Ordenanzas',
    id: 'Ordenanzas',
    tabindex: 'JUDICIAL',
  },
  {
    label: 'Débitos automáticos',
    id: 'Recuperación',
    tabindex: 'AMOUNT_RECOVERY',
  },
];

export const massiveColumnsExecutor = [
  'N° de orden',
  'Aprobación',
  'Procesamiento',
  'Fecha de creación',
  'Tipo de Proceso',
  'Canal',
  'Ejecutor',
  'Aprobador',
  'Cant. incidentes',
  'Monto total',
  '',
  '',
];

export const massiveColumnsExecutorNew = [
  'N° de orden',
  'Aprobación',
  'Procesamiento',
  'Fecha de creación',
  'Tipo de Proceso',
  'Canal',
  'Ejecutor',
  'Aprobador',
  'Cant. incidentes',
  'Monto total',
  '',
  '',
];

export const massiveColumnsApprover = [
  'N° de orden',
  'Fecha de creación',
  'Tipo de Proceso',
  'Canal',
  'Ejecutor',
  'Aprobador',
  'Cant. incidentes',
  'Monto total',
  '',
  '',
];
export const massivePtsColumnsGeneral: string[] = [
  'Ref ID',
  'Estado',
  'Documento',
  'N° de documento',
  'N° de cuenta',
  'Valor',
  '',
];
export const massivePtsColumnsGeneralNew: string[] = [
  'Ref ID',
  'Estado',
  'Documento',
  'N° de documento',
  'N° de juicio',
  'N° de cuenta',
  'Valor a retener',
  'Valor retenido',
  '',
];

export const massivePtsColumnsIntRe: string[] = [
  'Ref ID',
  'Estado',
  'Valor',
  '',
];

export const recoveryColumnsExecutor: string[] = [
  'N° de orden',
  'Aprobación',
  'Procesamiento',
  'Fecha de creación',
  'Fecha de caducidad',
  'Tipo de proceso',
  'Canal',
  'Ejecutor',
  'Aprobador',
  'Cant. registros',
  'Monto total',
  '',
  '',
];
export const recoveryColumnsApprover: string[] = [
  'N° de orden',
  'Fecha de creación',
  'Fecha de caducidad',
  'Tipo de proceso',
  'Canal',
  'Ejecutor',
  'Aprobador',
  'Cant. incidentes',
  'Monto total',
  '',
  '',
];
export const massivePtsColumnsRecovery: string[] = [
  'Ref ID',
  'Estado',
  'Documento',
  'N° de documento',
  'N° de cuenta',
  'Valor a recuperar',
  'Valor recuperado',
  '',
];
export const columnsApproveCancellation: string[] = [
  'N° de orden',
  'Fecha de creación',
  'Tipo de Proceso',
  'Solicitante',
  'Canal',
  'N° de cuenta ',
  'Cédula',
  'Ejecutor',
  'Aprobador',
  'Referencia de procesamiento',
  'Referencia de la transacción',
  '',
];
export const columnsExecutorCancellation: string[] = [
  'N° de orden',
  'Estado',
  'Fecha de creación',
  'Tipo de Proceso',
  'Solicitante',
  'Canal',
  'N° de cuenta ',
  'Cédula',
  'Ejecutor',
  'Aprobador',
  'Referencia de procesamiento',
  'Referencia de la transacción',
  '',
  '',
];
const commonStatuses = [
  { name: 'Aprobada', selected: false, value: 'PROCESSING' },
  { name: 'Rechazada', selected: false, value: 'REJECTED' },
  {
    name: 'Procesada con errores',
    selected: false,
    value: 'COMPLETED_WITH_ERRORS',
  },
  {
    name: 'Procesamiento fallido',
    selected: false,
    value: 'ERROR_PROCESSING',
  },
  { name: 'Procesada', selected: false, value: 'COMPLETED' },
  { name: 'Procesando', selected: false, value: 'PROCESSING' },
  { name: 'Fallida', selected: false, value: 'ERROR' },
];

export const filterStatusListMassive = [
  { name: 'Por aprobar', selected: false, value: 'NEW' },
  ...commonStatuses,
];

export const filterStatusListMassiveRecovery = [
  ...commonStatuses,
  {
    name: 'Pendiente de caducar',
    selected: false,
    value: 'PENDING_EXPIRATION',
  },
  { name: 'Caducada', selected: false, value: 'EXPIRED_ON_DEMAND' },
  {
    name: 'Caducada con errores',
    selected: false,
    value: 'EXPIRED_WITH_ERRORS',
  },
];
export const filterStatusListCancellation = [
  { name: 'Por aprobar', selected: false, value: 'NEW' },
  { name: 'Aprobada', selected: false, value: 'PROCESSING' },
  { name: 'Rechazada', selected: false, value: 'REJECTED' },
  { name: 'Procesada', selected: false, value: 'SUCCESS' },
  { name: 'Procesando', selected: false, value: 'PROCESSING' },
  { name: 'Pendiente', selected: false, value: 'PENDING' },
  { name: 'Cancelada', selected: false, value: 'CANCELLED' },
  { name: 'Fallida', selected: false, value: 'ERROR' },
];
export const filterOrderTypeListCancellation = [
  {
    name: 'Cancelación solicitado por titular',
    selected: false,
    value: 'INTCIERRECUENTASINSALDO',
  },
  {
    name: 'Reversar cancelación solicitado por titular',
    selected: false,
    value: 'INTCIERRECUENTASINSALDORV',
  },
  {
    name: 'Cancelación por unidad cumplimiento',
    selected: false,
    value: 'INTCIERRECUENTAUNICUM',
  },
  {
    name: 'Reversar cancelación por unidad cumplimiento',
    selected: false,
    value: 'INTCIERRECUENTAUNICUMRV',
  },
  {
    name: 'Cancelación  por Deuna SBS',
    selected: false,
    value: 'INTCIERRECUENTASALDO',
  },
  {
    name: 'Reversar cancelación  por Deuna SBS',
    selected: false,
    value: 'INTCIERRECUENTASALDORV',
  },
];

export const filterOrderTypeListJudicial = [
  {
    name: 'Retención',
    selected: false,
    value: 'INTRETENCIONJUDICIAL',
  },
  {
    name: 'Reverso de Retención',
    selected: false,
    value: 'INTRETENCIONJUDICIALRV',
  },
  {
    name: 'Liberación',
    selected: false,
    value: 'INTDEVOLUCIONJUDICIAL',
  },
  {
    name: 'Reverso de Liberación',
    selected: false,
    value: 'INTDEVOLUCIONJUDICIALRV',
  },
];

export const statusGeneral = [
  { name: 'Por aprobar', selected: false, value: 'PENDING' },
  { name: 'Aprobada', selected: false, value: 'APPROVED' },
  { name: 'Rechazada', selected: false, value: 'REJECTED' },
];
export const filterPaymentTypeList = recoveryPaymentType.map((element) => ({
  name: element.label,
  value: element.name,
  selected: false,
}));
export const resulFieldsTables: string = `
meta {
  itemCount
  totalItems
  itemsPerPage
  totalPages
  currentPage
}
items {id
orderId
orderProcessType
orderType
orderName
orderAdditionalData {
  originalTransactionRefId
  originalTransactionUUId
  reverseReason
}
orderArtifactsStoreUrl
isActive
status
createdByUser
reviewedByUser
createdOnDate
reviewedOnDate
reviewedComments
lastStatusChangeDate
searchKey
custDocId
custDocTypeId
custAccountNo
custName
processingResponseData{
    amount
    source
    responseCode
    responseDesc
    extReferenceId
    extReferenceUUID
}}
`;

export const filterPtsModalOld = [
  { name: 'Fallida', selected: false, value: 'FAIL' },
  { name: 'Exitosa', selected: false, value: 'SUCCESS' },
];
export const filterPtsModalNew = [
  { name: 'Fallida', selected: false, value: 'FAILED' },
  { name: 'Exitosa', selected: false, value: 'TOTAL' },
  { name: 'Parcial', selected: false, value: 'PARTIAL' },
];
