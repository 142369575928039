import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdjustmentRoutingModule } from './adjustment.routes';
import { GraphqlAccountService } from 'src/app/services/graphql/graphql-account.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeUnaModule } from 'src/app/lib/deuna/deuna.module';
import { PrimeNgModule } from 'src/app/lib/primeng/prime-ng.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { GraphqlMassivetxnsService } from 'src/app/services/graphql/graphql-massivetxns.service';

@NgModule({
  providers: [GraphqlAccountService, GraphqlMassivetxnsService],
  declarations: [
    AdjustmentRoutingModule.PAGES,
    AdjustmentRoutingModule.COMPONENTS,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    DeUnaModule,
    PrimeNgModule,
    CommonModule,
    RouterModule,
  ],
  exports: [
    RouterModule,
    AdjustmentRoutingModule.PAGES,
    AdjustmentRoutingModule.COMPONENTS,
  ],
})
export class AdjustmentModule {}
