<div class="grid">
    <div class="col p-0 mb-4">
        <ng-content select=".content-app"></ng-content>
    </div>
    <div *ngIf="showSide" class="side-lateral">
        <div class="title-side m-5">
            <div class="">
                <span class="">
                    {{title}}
                </span>
            </div>
            <div class="">
                <span (click)="clickClose()" style="cursor: pointer;">
                    X
                </span>
            </div>
        </div>
        <ng-content class="grid" select=".content-side"></ng-content>
    </div>
    
</div>