import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumOrderStatus } from 'src/app/constants/order-status.enum';
import { GraphqlAccountService } from 'src/app/services/graphql/graphql-account.service';
import { ErrorHandlingService } from 'src/app/services/http/error-handling.service';
import { FileToDownload } from 'src/app/shared/components/modals/download-file-modal/download-file-modal.component';
import { ModalParamsLoadingError } from 'src/app/shared/models/constants.backoffice.schema';
import {
  DataAccountOrders,
  IMutationReviewDecision,
  OrderData,
  ReviewDecision,
} from 'src/app/shared/models/data-table.schema';
import { ImageSets } from 'src/app/shared/models/image-sets.schema';

@Component({
  selector: 'app-table-actions-base',
  templateUrl: './table-actions-base.component.html',
  styleUrls: ['./table-actions-base.component.css'],
})
export class TableActionsBaseComponent {
  constructor(
    public servicesAccountGql: GraphqlAccountService,
    public readonly http: HttpClient,
    public messageService: MessageService,
    public readonly router: Router,
    public errorHandlingService: ErrorHandlingService
  ) {
    console.log('in table actionn base');
  }

  @Input() loading: boolean = false;
  @Input() paramsQuery: any;
  @Output() reloadComponent = new EventEmitter<void>();
  @Output() getOrderNumberById = new EventEmitter<string>();
  @Output() reviewDecisionApproveOrder = new EventEmitter<{
    order: DataAccountOrders;
    decision: ReviewDecision;
  }>();
  @Output() downloadOrderFile = new EventEmitter<any>();

  @Output() loadingErrorPropsChange = new EventEmitter<any>();

  paramsOrder!: OrderData;
  displayStatusModal: string = '';
  reviewComments: string = '';
  selectedOrderToDownload!: DataAccountOrders;
  displayDownloadOrderFileModal: boolean = false;
  displayDeleteOrderModal: boolean = false;
  displayConfirmOrderModal: boolean = false;
  displayApprovedExpireOrderModal: boolean = false;
  displayExecuteOrderModal: boolean = false;
  displayMassivePtsModal: boolean = false;
  displayApproveOrderModal: boolean = false;
  displayRejectOrderModal: boolean = false;
  displayExpireOrderModal: boolean = false;
  displayRejectText: boolean = false;
  orderExecute: boolean | null = false;
  selectedOrderToId!: DataAccountOrders;
  selectedOrderToConfirm!: IMutationReviewDecision;
  selectedOrderToReject!: IMutationReviewDecision;

  loadingErrorProps: ModalParamsLoadingError = {
    image: '',
    subHeaderText: '',
    headerText: undefined,
    valueAction: '',
    text: '',
    orderId: '',
  };

  errorImages = ImageSets.errorImages;

  refreshOrders() {
    this.reloadComponent.emit();
  }

  requestOrderNumberById(id: string) {
    this.getOrderNumberById.emit(id);
  }

  downloadOrderFilesOrders(fileToDownload: FileToDownload) {
    this.downloadOrderFile.emit(fileToDownload);
  }

  reviewDecisionApprover(
    orderToApprove: DataAccountOrders,
    decision: ReviewDecision
  ) {
    const payload = { order: orderToApprove, decision: decision };
    this.reviewDecisionApproveOrder.emit(payload);
  }

  downloadOrderFileModal(fileToDownload: FileToDownload) {
    this.downloadOrderFileModalAccount(fileToDownload);
  }

  downloadOrderFileModalAccount(fileToDownload: FileToDownload) {
    const { contentType, fileType, format } = fileToDownload;

    this.servicesAccountGql
      .downloadAccountContent(
        this.selectedOrderToDownload.id,
        this.selectedOrderToDownload.orderType,
        fileType
      )
      .subscribe({
        next: (res) => {
          const errorMessage = 'El archivo no existe o no se encontró';

          this.http.get(res.url, { responseType: 'blob' }).subscribe({
            next: (response) => {
              const blobAccount = new Blob([response], { type: contentType });
              const urlAccount = window.URL.createObjectURL(blobAccount);
              const linkAccount = document.createElement('a');
              linkAccount.href = urlAccount;
              linkAccount.download = `${this.selectedOrderToDownload.id}_${fileType}.${format}`;
              document.body.appendChild(linkAccount);
              linkAccount.click();
              document.body.removeChild(linkAccount);
              window.URL.revokeObjectURL(urlAccount);
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                detail: errorMessage,
              });
            },
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            detail: `El archivo no existe o no se encontró`,
          });
        },
      });
  }

  confirmExecuteOrderAccount() {
    const { id } = this.selectedOrderToId;

    this.servicesAccountGql.reviewOrderToExecute(id).subscribe({
      next: (res) => {
        this.orderExecute = res;

        if (this.orderExecute !== null && this.orderExecute === true) {
          this.messageService.add({
            severity: 'success',
            detail:
              'La Orden N° ' +
              this.selectedOrderToId.orderId +
              ' fue enviada al aprobador para su revisión',
          });
        } else {
          this.messageService.add({
            severity: 'error',
            detail: 'La cuenta aún tiene saldo, intenta más tarde ',
          });
        }
        this.refreshOrders();
      },
      error: (error) => {
        console.error(error);
        this.hideOrderExecuteModal();
        this.displayExecuteOrderModal = false;
        this.router.navigate([`people/incidentorders/order-deleted/error`], {
          queryParams: {
            id: this.selectedOrderToId.id,
            orderId: this.requestOrderNumberById(this.selectedOrderToId.id),
          },
        });
      },
    });
    this.hideOrderExecuteModal();
    this.displayExecuteOrderModal = false;
  }

  openDownloadOrderFileModal(orderToDownload): void {
    this.displayDownloadOrderFileModal = true;
    this.selectedOrderToDownload = orderToDownload;
    this.displayStatusModal = orderToDownload.status;
  }

  isOrderToDownloadCompleted(): boolean {
    return this.selectedOrderToDownload?.status === EnumOrderStatus.COMPLETED;
  }

  hideOrderFileModal() {
    this.displayDownloadOrderFileModal = false;
  }

  deleteOrder(orderToDelete: DataAccountOrders) {
    if (
      orderToDelete.status !== EnumOrderStatus.NEW &&
      orderToDelete.approvalStatus !== EnumOrderStatus.PENDING
    ) {
      return;
    }
    this.displayDeleteOrderModal = true;
    this.selectedOrderToId = orderToDelete;
  }
  expireOrder(orderToDelete: DataAccountOrders) {
    if (orderToDelete.status !== EnumOrderStatus.PROCESSING) {
      return;
    }
    this.displayExpireOrderModal = true;
    this.selectedOrderToId = orderToDelete;
  }
  approvedExpireOrder(orderToExpire: DataAccountOrders, isReject: boolean) {
    if (orderToExpire.status !== EnumOrderStatus.PENDING_EXPIRATION) {
      return;
    }
    this.displayRejectText = isReject;
    this.displayApprovedExpireOrderModal = true;
    this.selectedOrderToId = orderToExpire;
  }

  reviewDecisionApproverModal(
    orderToApprove: DataAccountOrders,
    decision: ReviewDecision
  ): void {
    this.selectedOrderToId = orderToApprove;

    if (
      orderToApprove.status !== EnumOrderStatus.NEW &&
      orderToApprove.approvalStatus !== EnumOrderStatus.PENDING
    ) {
      return;
    }
    this.selectedOrderToConfirm = {
      id: orderToApprove.id,
      reviewDecision: decision,
      reviewComments: '',
    };
    this.displayConfirmOrderModal = true;
  }

  reviewDecisionRejectModal(
    orderToReject: DataAccountOrders,
    decision: ReviewDecision
  ): void {
    this.selectedOrderToId = orderToReject;
    if (orderToReject.status !== EnumOrderStatus.NEW) {
      return;
    }
    this.selectedOrderToReject = {
      id: orderToReject.id,
      reviewDecision: decision,
      reviewComments: this.reviewComments,
    };
    this.displayRejectOrderModal = true;
  }

  reviewChangeStatusModal(orderToApprove): void {
    this.selectedOrderToId = orderToApprove;

    if (orderToApprove.status !== EnumOrderStatus.PENDING) {
      return;
    }
    this.displayExecuteOrderModal = true;
  }

  confirmExpireOrder(): void {}
  confirmApprovedExpireOrder(): void {}
  confirmOrderAccount(): void {
    const input: IMutationReviewDecision = {
      id: this.selectedOrderToConfirm.id,
      reviewDecision: this.selectedOrderToConfirm.reviewDecision,
      reviewComments: '',
    };
    this.servicesAccountGql.approveAccountOrder(input).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Aprobaste la Orden N° ' + this.selectedOrderToId.orderId,
        });
        this.refreshOrders();
      },
      error: (error) => {
        const errorMessage =
          this.errorHandlingService.handleErrorResponse(error);
        this.hideOrderConfirmModal();
        const { reviewDecision } = this.selectedOrderToConfirm;
        this.router.navigate(
          [`people/incidentorders/order-${reviewDecision.toLowerCase()}/error`],
          {
            queryParams: {
              ...this.selectedOrderToConfirm,
              orderId: this.requestOrderNumberById(
                this.selectedOrderToConfirm.id
              ),
              message: errorMessage,
              cancellation: true,
            },
          }
        );
      },
    });
    this.hideOrderConfirmModal();
  }

  openMassivePtsModal(orderToListPts) {
    this.paramsOrder = orderToListPts;
    this.displayMassivePtsModal = true;
  }

  rejectOrder() {
    this.rejectOrderAccount();
  }

  rejectOrderAccount(): void {
    const input: IMutationReviewDecision = {
      id: this.selectedOrderToReject.id,
      reviewDecision: this.selectedOrderToReject.reviewDecision,
      reviewComments: this.selectedOrderToReject.reviewComments,
    };
    this.servicesAccountGql.approveAccountOrder(input).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Rechazaste la Orden N° ' + this.selectedOrderToId.orderId,
        });
        this.refreshOrders();
      },
      error: () => {
        this.hideOrderRejectModal();
        const { reviewDecision } = this.selectedOrderToReject;
        this.router.navigate(
          [`people/incidentorders/order-${reviewDecision.toLowerCase()}/error`],
          {
            queryParams: {
              ...this.selectedOrderToReject,
              orderId: this.requestOrderNumberById(
                this.selectedOrderToReject.id
              ),
              cancellation: true,
            },
          }
        );
      },
    });
    this.hideOrderRejectModal();
  }

  hideOrderConfirmModal() {
    this.displayConfirmOrderModal = false;
  }
  hideOrderExpireModal() {
    this.displayExpireOrderModal = false;
  }
  hideApprovedOrderExpireModal() {
    this.displayRejectText = true;
    this.displayApprovedExpireOrderModal = false;
  }

  hideOrderRejectModal() {
    this.displayRejectOrderModal = false;
  }

  confirmDeleteOrder() {
    this.confirmDeleteOrderAccount();
  }

  confirmDeleteOrderAccount() {
    const { id, status } = this.selectedOrderToId;
    if (status !== 'NEW') return;
    this.servicesAccountGql.deleteCustomerAccountOrder(id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Eliminaste la orden N°' + this.selectedOrderToId.orderId,
        });
        this.refreshOrders();
      },
      error: (error) => {
        const errorMessage =
          this.errorHandlingService.handleErrorResponse(error);

        this.hideOrderDeletionModal();
        this.displayDeleteOrderModal = false;
        this.router.navigate([`people/incidentorders/order-deleted/error`], {
          queryParams: {
            id: this.selectedOrderToId.id,
            message: errorMessage,
            orderId: this.requestOrderNumberById(this.selectedOrderToId.id),
          },
        });
      },
    });
    this.hideOrderDeletionModal();
    this.displayDeleteOrderModal = false;
  }

  hideOrderDeletionModal() {
    this.displayDeleteOrderModal = false;
  }

  hideOrderExecuteModal() {
    this.displayExecuteOrderModal = false;
  }

  hideOrderMassivePtsModal() {
    this.displayMassivePtsModal = false;
  }

  initNotOrdersSearchPropsModal() {
    this.loadingErrorProps = {
      image: this.errorImages.imageLoadError,
      text: 'Realizar otra busqueda',
      valueAction: this.errorImages.imageClearFilters,
      headerText: `No encontramos ${this.paramsQuery.filterCriteriaText} entre tus ordenes `,
      subHeaderText: '',
      orderId: this.paramsQuery.filterCriteriaText,
    };
  }

  initNotFiltersPropsModal() {
    this.loadingErrorProps = {
      image: this.errorImages.imageLoadError,
      text: 'Borrar filtros',
      valueAction: this.errorImages.imageClearFilters,
      headerText:
        'No tienes órdenes que coincidan con los filtros que aplicaste',
      subHeaderText:
        'No tienes órdenes que coincidan con los filtros que aplicaste',
      orderId: this.paramsQuery.filterCriteriaText,
    };
  }

  initNotOrdersPropsModal() {
    this.loadingErrorProps = {
      image: this.errorImages.imageEmptyList,
      text: '',
      valueAction: this.errorImages.imageReload,
      headerText: '',
      subHeaderText: '',
      orderId: this.paramsQuery.filterCriteriaText,
    };
    this.loadingErrorPropsChange.emit(this.loadingErrorProps);
  }
  initErrorOrdersPropsModal() {
    this.loadingErrorProps = {
      image: this.errorImages.imageLoadError,
      text: 'Volver a cargar',
      valueAction: this.errorImages.imageReload,
      headerText: 'Ocurrió un error al cargar tus órdenes',
      subHeaderText: 'Intenta cargar nuevamente para visualizar tus órdenes.',
      orderId: this.paramsQuery.filterCriteriaText,
    };
    this.loadingErrorPropsChange.emit(this.loadingErrorProps);
  }
}
