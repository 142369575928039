import { ICatalogue } from 'src/app/shared/models/catalogue.schema';

export const errorCatalogueMambu: Array<ICatalogue> = [
  {
    code: '29000',
    message:
      'Hubo un problema desconocido con el proveedor Mambu. Por favor, comunícate con el equipo de Desarrollo. (Código: 29000)',
  },
  {
    code: '29001',
    message:
    'Ocurrió un error con el proveedor de Mambu. Por favor, comunícate con el equipo de Desarrollo. (Código: 29001)',
  },
  {
    code: '29002',
    message:
      'Ocurrió un error con el proveedor de Mambu. Por favor, comunícate con el equipo de Desarrollo. (Código: 29002)',
  },
  {
    code: '29003',
    message:
      'Ocurrió un error con el proveedor de Mambu. Por favor, comunícate con el equipo de Desarrollo. (Código: 29003)',
  },
  {
    code: '29004',
    message:
      'Ocurrió un error con el proveedor de Mambu. Por favor, comunícate con el equipo de Desarrollo. (Código: 29004)',
  },
  {
    code: '29005',
    message:
      'No existen intereses que puedan aplicarse a esta cuenta porque: 1. Está en 0, 2. Ya se aplicó el interés o 3. La tasa de interés es 0.',
  },
  {
    code: '29006',
    message:
      'Ocurrió un error con el proveedor de Mambu. Por favor, comunícate con el equipo de Desarrollo. (Código: 29006)',
  },
  {
    code: '29007',
    message:
      'El número de cuenta no existe en Mambú. Revisa los datos que ingresaste y vuelve a intentarlo.',
  },
  {
    code: '29008',
    message:
      'Estás intentando cancelar una cuenta que ya está cancelada. Revisa los datos que ingresaste y vuelve a intentarlo.',
  },

]

export const errorCatalogueAws: Array<ICatalogue> = [
  {
    code: '28000',
    message:
      'Hubo un problema desconocido con el provedor AWS. Por favor, comunícate con el equipo de Desarrollo.',
  },
  {
    code: '28001',
    message:
      'Archivo no encontrado.',
  },
  {
    code: '28002',
    message:
      'Ocurrió un error con el proveedor de AWS. Por favor, comunícate con el equipo de Desarrollo. (Código: 28002)',
  },
  {
    code: '28003',
    message:
      'Ocurrió un error con el proveedor de AWS. Por favor, comunícate con el equipo de Desarrollo. (Código: 29003)',
  },
  {
    code: '28004',
    message:
      'Ocurrió un error con el proveedor de AWS. Por favor, comunícate con el equipo de Desarrollo. (Código: 29004)',
  },
  {
    code: '28005',
    message:
      'Ocurrió un error con el proveedor de AWS. Por favor, comunícate con el equipo de Desarrollo. (Código: 29005)',
  },
  {
    code: '28006',
    message:
      'Ocurrió un error con el proveedor de AWS. Por favor, comunícate con el equipo de Desarrollo. (Código: 29006)',
  },
  {
    code: '28007',
    message:
      'Ocurrió un error con el proveedor de AWS. Por favor, comunícate con el equipo de Desarrollo. (Código: 29007)',
  },
];
