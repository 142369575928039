import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  CreateOrderService,
  OrderProcessType,
  SelectOption,
} from 'src/app/services/incidentandorders/create-order.service';
import {
  OrderType,
  custAccountMgmtType,
  judicialType,
  reconcileType,
} from 'src/app/shared/models/order-types.schema';
import { OrderProcessType as TypeEnum } from '../../models/data-table.schema';

@Component({
  selector: 'app-create-order-step1',
  templateUrl: './create-order-step1.component.html',
  styleUrls: ['./create-order-step1.component.css'],
})
export class CreateOrderStep1Page implements OnInit {
  formStep1: FormGroup;
  orderTypes: Array<OrderType> = [];
  selectedOption!: string;
  displayCancelModal: boolean = false;

  constructor(
    public router: Router,
    private readonly formBuilder: FormBuilder,
    private createOrderService: CreateOrderService
  ) {
    this.formStep1 = this.formBuilder.group({
      orderType: [
        this.createOrderService.step1Result?.label,
        [Validators.required],
      ],
    });
  }

  ngOnInit(): void {
    this.formStep1.reset();
    this.initData();
  }

  initData() {
    const activeOrderProcessType = this.selectedOrderType;
    if (!activeOrderProcessType) {
      this.router.navigate(['people/incidentorders/list']);
    } else {
      switch (activeOrderProcessType) {
        case TypeEnum.CLOSEACCOUNT:
          this.orderTypes = custAccountMgmtType;
          break;
        case TypeEnum.JUDICIAL:
          this.orderTypes = judicialType;
          break;
        case TypeEnum.RECONCILE:
          this.orderTypes = reconcileType;
          break;
        case TypeEnum.RECOVERY:
          this.orderTypes = reconcileType;
          break;
        default:
          break;
      }
    }
  }

  isFieldValid(fieldName: string): any {
    return (
      this.formStep1.controls?.[fieldName]?.errors &&
      this.formStep1.controls?.[fieldName]?.touched
    );
  }

  openCancelModal() {
    this.displayCancelModal = true;
  }

  hideCancelModal() {
    this.displayCancelModal = false;
  }

  confirmOrderCancellation() {
    this.router.navigate(['people/incidentorders/list']);
  }

  get selectedOrderType(): string {
    const activeOrderProcessType = sessionStorage.getItem(
      'active_order_process_type'
    );
    const { tabindex } = JSON.parse(
      activeOrderProcessType!
    ) as OrderProcessType;
    return tabindex;
  }
  onSubmit() {
    if (this.formStep1.invalid) {
      this.formStep1.markAllAsTouched();
      return;
    }
    const { label, name } = this.formStep1.value['orderType'] as SelectOption;
    this.createOrderService.setStep1Value({ label, name });
    this.formStep1.reset();
    this.router.navigate([
      `people/incidentorders/create-order/${this.selectedOrderType}/step-2`,
    ]);
  }
}
