import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss'],
})
export class CancelModalComponent {
  @Input() displayModal: boolean = false;
  @Output() onHide = new EventEmitter();
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() loading: boolean = false;


  hideModal() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  showModal() {
    this.displayModal = true;
  }

  cancel() {
    this.displayModal = false;
    this.onCancel.emit();
  }

  confirmCancellation() {
    this.onConfirm.emit();
  }
}
