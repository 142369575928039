<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '55vw' }"
  (onHide)="hideModal()"
  [draggable]="false"
  [resizable]="false"
>
  <div class="grid text-center">
    <div class="col-8 col-offset-2 pt-4">
      <img
        loading="lazy"
        [src]="errorImages.accessDenied"
        alt="hand"
        class="w-3"
      />
      <div class="headerModal">¿Quieres caducar esta orden?</div>
      <div class="textModal mt-3">
        La orden caducará y la recuperación de valores será detenida
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid col-12">
      <div class="col-6">
        <deuna-button
          label="No, cancelar"
          [disabled]="loading"
          deUnaClass="p-button-outlined" 
          [outline]="true"
          (eventClick)="cancelConfirm()"
        >
        </deuna-button>
      </div>
      <div deUnaClass="p-button-outlined" *ngIf="!loading" class="col-6">
        <deuna-button
          label="Sí, caducar"
          [disabled]="loading"
          (eventClick)="confirmExpire()"
        ></deuna-button>
      </div>
      <div *ngIf="loading" class="col-6">
        <deuna-button
          label="Caducando..."
          [disabled]="loading"
          (eventClick)="confirmExpire()"
        >
        </deuna-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
