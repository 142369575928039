<p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '55vw'}" (onHide)="hideModal()" [draggable]="false"
    [resizable]="false">
    <div class="grid text-center">
        <div class="col-8 col-offset-2 pt-4">
            <div class="headerModal">
                ¿Quieres aprobar la órden N°{{orderId}}?
            </div>
            <div class="textModal mt-3">
                Al seleccionar “Si, aprobar”, la orden será procesada y no podrás deshacer la acción.
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="grid col-12">
            <div class="col-6">
                <deuna-button deUnaClass="p-button-outlined"  label="No, cancelar" [disabled]="loading" [outline]="true" (eventClick)="cancelConfirm()">
                </deuna-button>
            </div>
            <div *ngIf="!loading" class="col-6">
                <deuna-button label="Sí, aprobar" [disabled]="loading" (eventClick)="confirmCreation()"></deuna-button>
            </div>
            <div *ngIf="loading" class="col-6">
                <deuna-button label="Aprobando..." [disabled]="loading" (eventClick)="confirmCreation()">
                </deuna-button>
            </div>
        </div>
    </ng-template>
</p-dialog>
