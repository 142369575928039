<form [formGroup]="formStep2" (ngSubmit)="onSubmit()" class="grid my-5">
  <div class="col-8 col-offset-2 mb-5">
    <span class="text-style-back" routerLink="/people/incidentorders/list"
      >Regresar</span
    >
  </div>

  <div class="col-8 col-offset-2">
    <app-card header="Tipo de orden">
      <div class="grid col-12">
        <div class="mb-2 col-12">
          <div class="grid">
            <div class="col-6">
              <deuna-select
                [form]="formStep2"
                [options]="orderTypes"
                (onChangeSelect)="changeOrderTypeValue()"
                optionLabel="label"
                label="Proceso"
                name="orderType"
                control="orderType"
                [placeholder]="initialOrderType!"
              ></deuna-select>
              <span
                *ngIf="isFieldInvalid('orderType')"
                class="form-text text-danger"
              >
                Debe ingresar un tipo de orden
              </span>
            </div>
          </div>
          <div class="grid">
            <div class="col-6">
              <deuna-select
                [form]="formStep2"
                [options]="requesterTypes"
                (onChangeSelect)="changeRequesterTypeValue()"
                optionLabel="label"
                label="Solicitante"
                name="requesterType"
                control="requesterType"
                placeholder="Selecciona un solicitante"
              ></deuna-select>
              <span
                *ngIf="isFieldInvalid('requesterType')"
                class="form-text text-danger"
              >
                Debe ingresar un solicitante
              </span>
            </div>
          </div>
          <div *ngIf="showCreateCancellationOrderWarning">
            <span style="font-weight: bold">Nota: </span>Recuerda, el
            <span style="font-weight: bold"
              >cierre de cuenta solicitado por el titular</span
            >
            solo puede ejecutarse para cuentas sin saldo.
          </div>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-8 mt-5 col-offset-2">
    <app-card
      header="Datos de la cuenta"
      subHeader="Escribe los datos de la cuenta del cliente que se va a cancelar o cerrar"
    >
      <div class="grid col-12">
        <div class="mb-2 col-6">
          <div class="grid">
            <div class="col-12">
              <span>Número de cuenta</span>
            </div>
            <div class="col-12">
              <deuna-input
                [masked]="false"
                [disableErrorMessage]="true"
                [form]="formStep2"
                type="number"
                [maxLength]="10"
                placeholder=""
                name="accountId"
                control="accountId"
              >
              </deuna-input>
              <span
                *ngIf="
                  formStep2.controls['accountId']?.hasError('required') &&
                  formStep2.controls['accountId']?.touched
                "
                class="create-order__bad-input"
              >
                Dato obligatorio
              </span>
              <span
                *ngIf="formStep2.controls['accountId']?.hasError('minlength')"
                class="create-order__bad-input"
              >
                El número de cuenta debe tener 10 caracteres.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="grid col-12">
        <div class="mb-2 col-6">
          <div class="grid">
            <div class="col-12">
              <span>Cédula o RUC</span>
            </div>
            <div class="col-12">
              <deuna-input
                [masked]="false"
                [disableErrorMessage]="true"
                [form]="formStep2"
                type="number"
                placeholder=""
                [maxLength]="13"
                name="customerId"
                control="customerId"
              >
              </deuna-input>
              <span
                *ngIf="
                  formStep2.controls['customerId']?.hasError('required') &&
                  formStep2.controls['customerId']?.touched
                "
                class="create-order__bad-input"
              >
                Dato obligatorio
              </span>
              <span
                *ngIf="formStep2.controls['customerId']?.hasError('minlength')"
                class="create-order__bad-input"
              >
                El número de documento debe tener 10 caracteres si es cédula o
                13 caracteres si es RUC
              </span>
            </div>
          </div>
        </div>

        <div class="mb-2 col-6">
          <div class="grid">
            <div class="col-12">
              <span>Nombres y apellidos</span>
            </div>
            <div class="col-12">
              <deuna-input
                [masked]="false"
                [disableErrorMessage]="true"
                [form]="formStep2"
                type="text"
                [maxLength]="60"
                placeholder=""
                name="fullName"
                control="fullName"
              >
              </deuna-input>
              <span
                *ngIf="
                  formStep2.controls['fullName']?.hasError('required') &&
                  formStep2.controls['fullName']?.touched
                "
                class="create-order__bad-input"
              >
                Dato obligatorio
              </span>
              <span
                *ngIf="formStep2.controls['fullName']?.hasError('minlength')"
                class="create-order__bad-input"
              >
                El nombre del cliente debe tener más de 3 caracteres.
              </span>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-8 mt-5 col-offset-2" *ngIf="isReverseOrder()">
    <app-card
      header="Datos de la transacción"
      subHeader="Escribe los datos de la transacción a reversar"
    >
      <div class="grid col-12">
        <div class="mb-2 col-6">
          <div class="grid">
            <div class="col-12">
              <span>Referencia de la transacción original (Ref)</span>
            </div>
            <div class="col-12">
              <deuna-input
                [masked]="false"
                [disableErrorMessage]="true"
                [form]="formStep2"
                [maxLength]="100"
                type="text"
                placeholder=""
                name="originalTransactionRefId"
                control="originalTransactionRefId"
              >
              </deuna-input>
              <span
                *ngIf="
                  formStep2.controls['originalTransactionRefId']?.hasError(
                    'required'
                  ) && formStep2.controls['originalTransactionRefId']?.touched
                "
                class="create-order__bad-input"
              >
                Dato obligatorio
              </span>
              <span
                *ngIf="
                  formStep2.controls['originalTransactionRefId']?.hasError(
                    'minlength'
                  )
                "
                class="create-order__bad-input"
              >
                La referencia de la transacción debe tener minimo 5 caracteres.
              </span>
            </div>
          </div>
        </div>

        <div class="mb-2 col-6">
          <div class="grid">
            <div class="col-12">
              <span>Id de la transacción original (Id)</span>
            </div>
            <div class="col-12">
              <deuna-input
                [masked]="false"
                [disableErrorMessage]="true"
                [form]="formStep2"
                type="text"
                [maxLength]="36"
                placeholder=""
                name="originalTransactionUUId"
                control="originalTransactionUUId"
              >
              </deuna-input>
              <span
                *ngIf="
                  formStep2.controls['originalTransactionUUId']?.hasError(
                    'required'
                  ) && formStep2.controls['originalTransactionUUId']?.touched
                "
                class="create-order__bad-input"
              >
                Dato obligatorio
              </span>
              <span
                *ngIf="
                  formStep2.controls['originalTransactionUUId']?.hasError(
                    'minlength'
                  )
                "
                class="create-order__bad-input"
              >
                El número válido del ID debe tener 36 caracteres.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="grid col-12">
        <div class="mb-2 col-12">
          <div class="grid">
            <div class="col-12">
              <span>Razón del reverso</span>
            </div>
            <div class="col-12">
              <deuna-input
                [masked]="false"
                [form]="formStep2"
                type="text"
                [maxLength]="250"
                placeholder=""
                name="reverseReason"
                control="reverseReason"
              >
              </deuna-input>
              <span
                *ngIf="
                  formStep2.controls['reverseReason']?.hasError('required') &&
                  formStep2.controls['reverseReason']?.touched
                "
                class="create-order__bad-input"
              >
                Dato obligatorio
              </span>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-8 mt-5 col-offset-2">
    <app-card
      header="Carga de respaldos"
      subHeader="Sube un zip con toda la evidencia de respaldo relacionada a la cancelación"
    >
      <app-file-upload
        (onFileUploaded)="receiveEvidencesFile($event)"
        [maxSizeMB]="10"
        [allowedExtensions]="['zip']"
        [required]="isFieldInvalid('evidenceFile')"
        [showDownloadTemplate]="true"
        fileDescriptionText="Archivo de evidencias"
        titleButton="Cargar ZIP"
      ></app-file-upload>
    </app-card>
  </div>

  <div class="col-6 col-offset-4 pt-4">
    <div class="grid">
      <div class="col-6">
        <deuna-button
          deUnaClass="p-button-outlined"
          label="Cancelar"
          (eventClick)="openCancelModal()"
        >
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button
          (eventClick)="onSubmit()"
          label="Crear orden"
        ></deuna-button>
      </div>
    </div>
  </div>

  <div class="w-full h-full z-5" *ngIf="loading">
    <deuna-loading
      titulo="Creando orden"
      subtitulo="Por favor, espera un momento"
    >
    </deuna-loading>
  </div>

  <app-confirm-modal
    *ngIf="!formStep2.invalid"
    [displayModal]="displayConfirmModal"
    [loading]="loading"
    (onConfirm)="confirmOrderCreation()"
    (onCancel)="cancelOrderCreation()"
    (onHide)="hideConfirmModal()"
  ></app-confirm-modal>

  <app-cancel-modal
    [displayModal]="displayCancelModal"
    [loading]="loading"
    (onConfirm)="confirmOrderCancellation()"
    (onCancel)="hideCancelModal()"
    (onHide)="hideCancelModal()"
  ></app-cancel-modal>
</form>
