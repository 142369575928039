import { Component } from '@angular/core';
import { TableActionsAdjustmentComponent } from 'src/app/modules/adjustment/components/table-actions-adjustment/table-actions-adjustment.component';

@Component({
  selector: 'app-table-actions-ordinance',
  templateUrl:
    '../../../../shared/components/table-actions-base/table-actions-base.component.html',
  styleUrls: [
    '../../../../shared/components/table-actions-base/table-actions-base.component.css',
  ],
})
export class TableActionsOrdinanceComponent extends TableActionsAdjustmentComponent {}
