import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancellationsModule } from './cancellations/cancellations.module';
import { MessageService } from 'primeng/api';
import { S3Service } from '../services/http/aws-s3.service';
import { ConfigurationBackOfficeService } from '../services/graphql/configuration-flow-backoffice.service';
import { CreateOrderService } from '../services/incidentandorders/create-order.service';
import { ErrorHandlingService } from '../services/http/error-handling.service';

import { ValidateFilesHelpers } from '../shared/helpers/validate-files';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainIncidentOrdersRoutingModule } from './main-incident-orders-routing.module';
import { DataSharingService } from '../services/http/data-sharing.service';
import { RouterModule } from '@angular/router';
import { AdjustmentModule } from './adjustment/adjustment.module';
import { OrdinancesModule } from './ordinances/ordinances.module';
import { RecoveryModule } from './recovery/recovery.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    S3Service,
    ConfigurationBackOfficeService,
    CreateOrderService,
    MessageService,
    ErrorHandlingService,
    DataSharingService,
    ValidateFilesHelpers,
  ],
  declarations: [MainIncidentOrdersRoutingModule.COMPONENTS],
  imports: [
    CancellationsModule,
    AdjustmentModule,
    OrdinancesModule,
    RecoveryModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class MainIncidentOrdersModule {}
