<div class="grid m-3 scroll">
  <div class="grid mx-3">
    <div class="col-12 mb-2">
      <strong>Fecha</strong>
    </div>
    <div class="grid">
      <div class="col-12" style="margin: 0">
        <input
          class="colorCheck mt-2"
          type="checkbox"
          name="allDate"
          [checked]="areAllSelectedDate"
          (change)="allSelectionDate()"
        />
        Todas
      </div>
      <div class="col-12">
        <div *ngFor="let orderFilterList of filterDate">
          <div
            style="height: 40px"
            [style.backgroundColor]="
              orderFilterList.selected ? '#F1F2F5' : '#FFFFFF'
            "
          >
            <input
              class="colorCheck mt-2"
              type="checkbox"
              [(ngModel)]="orderFilterList.selected"
              [checked]="orderFilterList.selected"
            />
            {{ orderFilterList.name }}
          </div>

          <ng-container
            *ngIf="orderFilterList.selected"
            (change)="updateSelection()"
          >
            <div class="flex flex-direction-row">
              <p-calendar
                #calendar
                [numberOfMonths]="2"
                [selectionMode]="'range'"
                [appendTo]="'body'"
                [(ngModel)]="selectedDates"
                (onSelect)="onSelect($event)"
                [dateFormat]="'dd/mm/yy'"
                [inputStyle]="{ width: '108px', display: 'block' }"
                placeholder="Desde"
              >
              </p-calendar>

              <input
                type="text"
                class="input-date"
                placeholder="Hasta"
                [readonly]="true"
                [ngModel]="selectedDates[1] | date : 'dd/MM/yyyy'"
              />
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isExecutor" class="grid mx-3">
    <div class="col-12 mb-2">
      <strong>Estado</strong>
    </div>
    <div class="grid">
      <div class="col-12" style="margin: 0">
        <input
          class="colorCheck mt-2"
          type="checkbox"
          name="allStatus"
          [checked]="areAllSelectedStatus"
          (change)="allSelectionStatus()"
        />
        Todas
      </div>
      <div class="col-12 boxFilters">
        <div *ngFor="let status of statusList; let i = index">
          <div
          class="boxCheck"
            [style.backgroundColor]="status.selected ? '#F1F2F5' : '#FFFFFF'"
          >
            <input
              class="colorCheck mt-2"
              type="checkbox"
              [(ngModel)]="status.selected"
              [checked]="status.selected"
              (change)="updateSelectionStatus()"
            />
            {{ status.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid mx-3">
    <div class="col-12">
      <strong>Tipo de proceso</strong>
    </div>
    <div class="grid">
      <div class="col-12" style="margin: 0">
        <input
          class="colorCheck mt-2"
          type="checkbox"
          name="allOrdenType"
          [checked]="areAllSelectedOrdenType"
          (change)="allSelectionOrdenType()"
        />
        Todas
      </div>
      <div class="col">
        <div *ngFor="let orderFilterList of orderTypeList; let i = index">
          <div
            style="height: 40px"
            [style.backgroundColor]="
              orderFilterList.selected ? '#F1F2F5' : '#FFFFFF'
            "
          >
            <input
              class="colorCheck mt-2"
              type="checkbox"
              [(ngModel)]="orderFilterList.selected"
              [checked]="orderFilterList.selected"
              (change)="updateSelection()"
            />
            {{ orderFilterList.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid mt-3">
    <div class="col-12">
      <deuna-button
        [label]="'Aplicar filtros'"
        [outline]="true"
        (eventClick)="applyFilters()"
      ></deuna-button>
    </div>
    <div class="col-12 text-link cursor-pointer mb-4">
      <u (click)="deleteFiltersReload()">Borrar todos los filtros</u>
    </div>
  </div>
</div>
