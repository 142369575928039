import { Component, EventEmitter, Input, Output } from '@angular/core';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { ErrorMappingValidation } from 'src/app/shared/models/config-backoffice.schema';
import { ImageSets } from 'src/app/shared/models/image-sets.schema';

@Component({
  selector: 'app-format-error-modal',
  templateUrl: './format-error-modal.component.html',
  styleUrls: ['./format-error-modal.component.scss'],
})
export class FormatErrorModalComponent {
  @Input() displayModal: boolean = false;
  @Output() onRetry = new EventEmitter();
  title: string = '';
  subtitle: string = '';
  errors: ErrorMappingValidation[] = [];
  img: string = assetUrl('png/image-access_denied.png');
  icons = ImageSets.errorImages;

  hideModal() {
    this.displayModal = false;
    this.title = '';
    this.subtitle = '';
    this.errors = [];
    this.onRetry.emit();
  }

  showModal(ttl: string, sbt: string, err: ErrorMappingValidation[]) {
    this.title = ttl;
    this.subtitle = sbt;
    this.displayModal = true;
    this.errors = err;
  }
  get formattedErrors(): string[] {
    return this.errors.map((entry) => {
      const item = `<strong>${entry.item}</strong>`;
      const reasons = entry.reason.join(', ');
      return `${item}: ${reasons}.`;
    });
  }
}
