import { Component, Input } from '@angular/core';
import {
  IQueryDataOrders,
  OrderProcessType,
  ReviewDecision,
} from 'src/app/shared/models/data-table.schema';
import { ListAdjustmentComponent } from 'src/app/modules/adjustment/components/list-adjustment/list-adjustment.component';
import {
  massiveColumnsApprover,
  massiveColumnsExecutorNew,
} from 'src/app/shared/models/order-types.schema';

@Component({
  selector: 'app-list-ordinance',
  templateUrl: `../../../../shared/components/list-base/list-base.component.html`,
  styleUrls: [
    '../../../../shared/components/list-base/list-base.component.scss',
  ],
})
export class ListOrdinanceComponent extends ListAdjustmentComponent {
  @Input() override paramsQuery: IQueryDataOrders = {
    orderProcessType: OrderProcessType.JUDICIAL,
    skip: 0,
    limit: 10,
  };
  override actionsType = OrderProcessType.JUDICIAL;
  override columnsApprove = massiveColumnsApprover;
  override columnsExecutor = massiveColumnsExecutorNew;

  override createConfigGetInitial() {
    this.paramsQuery = {
      orderProcessType: OrderProcessType.JUDICIAL,
      skip: this.currentPage,
      limit: this.limitPage,
    };
    this.paramsDecision = {
      id: '',
      reviewDecision: ReviewDecision.APPROVED,
      reviewComments: '',
    };
    this.resultsDecision = `
        id: '',
        orderId: ''
        `;

    this.dataPagination = {
      skip: this.currentPage,
      limit: this.limitPage,
    };
  }
}
