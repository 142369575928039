export enum EnumOrderStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  ERROR_PROCESSING = 'ERROR_PROCESSING',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  EXPIRED = 'EXPIRED',
  EXPIRED_ON_DEMAND = 'EXPIRED_ON_DEMAND',
  EXPIRED_WITH_ERRORS = 'EXPIRED_WITH_ERRORS',
  PENDING_EXPIRATION = 'PENDING_EXPIRATION',
}
