<app-massive-pts-modal
*ngIf="displayMassivePtsModal"
[displayModal]="displayMassivePtsModal"
[orderType]="paramsOrder.orderType"
[orderProcessType]="paramsOrder.orderProcessType"
[orderId]="paramsOrder.orderId"
[statusOrder]="paramsOrder.status"
[mailExecutor]="paramsOrder.createdByUser"
[mailApprove]="paramsOrder.reviewedByUser"
[order]="paramsOrder"
[loading]="loading"
(onHide)="hideOrderMassivePtsModal()"
(onCancel)="hideOrderMassivePtsModal()"
></app-massive-pts-modal>
<app-download-file-modal
[displayModal]="displayDownloadOrderFileModal"
[isOrderCompleted]="isOrderToDownloadCompleted()"
(onSelect)="downloadOrderFileModalAccount($event)"
[status]="displayStatusModal"
(onHide)="hideOrderFileModal()"
(onCancel)="hideOrderFileModal()"
></app-download-file-modal>
<app-confirm-order-modal
*ngIf="displayConfirmOrderModal"
[displayModal]="displayConfirmOrderModal"
[orderId]="selectedOrderToId.orderId"
(onConfirm)="confirmOrderAccount()"
[loading]="loading"
(onCancel)="hideOrderConfirmModal()"
(onHide)="hideOrderConfirmModal()"
></app-confirm-order-modal>

<app-reason-modal
*ngIf="displayRejectOrderModal"
[displayModal]="displayRejectOrderModal"
[orderId]="selectedOrderToId.orderId"
(onConfirm)="rejectOrder()"
[loading]="loading"
(onCancel)="hideOrderRejectModal()"
(onHide)="hideOrderRejectModal()"
[reviewComments]="reviewComments"
(onreviewCommentsChange)="selectedOrderToReject.reviewComments = $event"
></app-reason-modal>

<app-delete-order-modal
*ngIf="displayDeleteOrderModal"
[displayModal]="displayDeleteOrderModal"
[orderId]="selectedOrderToId.orderId"
[loading]="loading"
(onConfirm)="confirmDeleteOrder()"
(onCancel)="hideOrderDeletionModal()"
(onHide)="hideOrderDeletionModal()"
></app-delete-order-modal>
<app-change-order-modal
*ngIf="displayExecuteOrderModal"
[displayModal]="displayExecuteOrderModal"
[orderId]="selectedOrderToId.orderId"
[loading]="loading"
(onConfirm)="confirmExecuteOrderAccount()"
(onCancel)="hideOrderExecuteModal()"
(onHide)="hideOrderExecuteModal()"
></app-change-order-modal>
