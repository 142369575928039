<deuna-side-right
  [title]="'Filtros'"
  [showSide]="showSideBar"
  (closeSide)="showSideBar = false"
>
  <div class="content-app">
    <div class="grid mt-3 mx-5">
      <div class="col-12 pt-5 pl-4">
        <h1 class="title" *ngIf="canDisplay([backofficeRoles.EXECUTOR])">
          Mis ordenes
        </h1>
        <h1 *ngIf="canDisplay([backofficeRoles.APPROVER])" class="title">
          Ordenes por aprobar
        </h1>
      </div>
    </div>
    <div class="grid mx-5 mb-4 pl-3">
      <div class="col-12 mb-4">
        <deuna-tab-menu
          [items]="items"
          [activeItem]="activeItem"
        ></deuna-tab-menu>
      </div>
    </div>

    <!-- Buscador , boton filtros y boton de formulario -->
    <app-tab-list-branch
      [tabindex]="activeItem.tabindex ?? 'INITIAL'"
      (toggleSidebar)="toggleSidebar()"
    ></app-tab-list-branch>
    <!-- Section -->
    <app-list-cancellation
      [showTable]="showSideBar"
      *ngIf="activeItem.tabindex === actionTypeEnum.CLOSEACCOUNT"
    ></app-list-cancellation>
    <app-list-adjustment
      [showTable]="showSideBar"
      *ngIf="activeItem.tabindex === actionTypeEnum.RECONCILE"
    ></app-list-adjustment>
    <app-list-ordinance
      [showTable]="showSideBar"
      *ngIf="activeItem.tabindex === actionTypeEnum.JUDICIAL"
    ></app-list-ordinance>
    <app-list-recovery
      [showTable]="showSideBar"
      *ngIf="activeItem.tabindex === actionTypeEnum.RECOVERY"
    ></app-list-recovery>
  </div>
  <!-- Sidebar con filtros -->
  <div class="content-side grid">
    <app-filters [orderProcessType]="activeItem.tabindex ?? ''"></app-filters>
  </div>
</deuna-side-right>
