import { NgModule } from '@angular/core';

import {
  DeUnaButtonModule,
  DeUnaCheckListModule,
  DeUnaInputModule,
  DeUnaLoadingModule,
  DeUnaModalModule,
  DeUnaPaginationModule,
  DeUnaRadioButtonModule,
  DeUnaSelectModule,
  DeUnaTableCellModule,
  DeUnaTableModule,
  DeUnaTableRowModule,
  DeunaChipModule,
  DeunaMenuModule,
  DeunaTabMenuModule,
  DeUnaSideRightModule,
} from '@deuna/bo-sh-components-ng';

@NgModule({
  exports: [
    DeUnaModalModule,
    DeUnaLoadingModule,
    DeUnaButtonModule,
    DeUnaRadioButtonModule,
    DeUnaSelectModule,
    DeUnaCheckListModule,
    DeUnaCheckListModule,
    DeunaChipModule,
    DeUnaTableModule,
    DeUnaTableRowModule,
    DeunaMenuModule,
    DeUnaPaginationModule,
    DeUnaTableCellModule,
    DeunaTabMenuModule,
    DeUnaInputModule,
    DeUnaButtonModule,
    DeUnaSideRightModule,
  ],
})
export class DeUnaModule {}
